import { Divider, Modal } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Logo } from "../../assets/svg";
import { useSelector } from "react-redux";
import { LogoApp } from "assets/img";
import i18n,{ languageKeys } from "../../i18n";

const WarningModal = forwardRef(
  (
    { children, width = 400, title = i18n.t(languageKeys.common_Canh_bao), content = i18n.t(languageKeys.modal_hay_xac_nhan), maskClosable = true, onClosed = () => {}, titleColor = "#FF5855" },
    ref
  ) => {

    const [visible, setVisible] = useState(false);
    const userProfile = useSelector((state) => state.auth.user);

    useImperativeHandle(ref, () => ({
      open() {
        setVisible(true);
      },
      close() {
        onClose();
      },
    }));

    const onClose = () => {
      setVisible(false);
      onClosed();
    };

    return (
      <Modal
        visible={visible}
        onCancel={onClose}
        width={width}
        closable={false}
        footer={null}
        maskClosable={maskClosable}
      >
        <div
          style={{
            textAlign: "center",
            paddingLeft: "30px",
            paddingRight: "30px",
          }}
        >

          {/* {
            userProfile?.config?.CUSTOM_DOMAIN
              ? userProfile.config.CUSTOM_DOMAIN.LOGO_ICON
                ? <img
                  src={window.GATEWAY + `cis-public/public/file?ID=${userProfile.config.CUSTOM_DOMAIN.LOGO_ICON}`}
                  alt="logo"
                  style={{ height: 29 }}
                />
                : <Logo />
              : <Logo />
          } */}

          {/* <img
            src={
              userProfile?.config?.CUSTOM_DOMAIN
                ? userProfile.config.CUSTOM_DOMAIN.LOGO_ICON
                  ? window.GATEWAY + `cis-public/public/file?ID=${userProfile.config.CUSTOM_DOMAIN.LOGO_ICON}`
                  : LogoApp
                : LogoApp
            }
            alt="logo"
            style={{ height: 29 }}
          /> */}

          <Divider />

          {typeof title === "string" ? (
            <h1 style={{ color: titleColor }}>{title}</h1>
          ) : (
            title
          )}

          {typeof content === "string" ? (
            <p style={{ color: "#2C3782" }}>{content}</p>
          ) : (
            content
          )}
        </div>

        {children}
      </Modal>
    );
  }
);

export default WarningModal;
