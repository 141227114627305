import { notification } from "antd";
import { getExamHistory, getExamHistorySuccess } from "ducks/slices/GoiDichVu/ThongTinSDGoiDv/examHistory.slice";
import i18n, { languageKeys } from "../../../../i18n";
import { put, select, takeLatest } from "redux-saga/effects";
import { setTableLoading } from "ducks/slices/loadingSlice";
import { layLSKhamBenh } from "../apiGoiDichVu";

export function* watchExamHistory() {
  yield takeLatest(getExamHistory, handleFetchExamHistory);
}

function* handleFetchExamHistory(action) {
    const {LICH_KHAM_ID, BENH_NHAN_ID} = action.payload;
  try {
    yield put(setTableLoading(true));
    const userProfile = yield select((state) => state.auth.user);
    const res = yield layLSKhamBenh({
      partner_code: userProfile?.partner_code,
      BENH_VIEN_ID: userProfile?.BENH_VIEN_ID,
      BENH_NHAN_ID,
      LICH_KHAM_ID,
      filter_chan_doan_benh: "",
      filter_cskcb: "",
      filter_khoa: "",
      TU_NGAY: "",
      DEN_NGAY: "",
      filter_ngay_tai_kham: {
        TU_NGAY: "",
        DEN_NGAY: "",
      },
      filter_sap_xep_theo_key: {
        field: "",
        type: "",
      },
      limit: 20,
      page: 1,
    });

    if (!!res) {
      if (res.status === "OK") {
        yield put(getExamHistorySuccess(res));
      } else {
        notification.error({
          message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
          placement: "bottomLeft",
        });
      }
    } else {
      notification.error({
        message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
        placement: "bottomLeft",
      });
    }
  } catch (err) {
    notification.error({
      message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
      placement: "bottomLeft",
    });
  } finally {
    yield put(setTableLoading(false));
  }
}
