const { createSlice } = require("@reduxjs/toolkit")

const initState = {
    data: {},
}

const prescriptionByExaminationSlice = createSlice({
    name: 'prescriptionByExaminationSlice',
    initialState: initState,
    reducers: {
        getPrescriptionByExamination: () => {},
        getPrescriptionByExaminationSuccess: (state, action) => {
            const {result} = action.payload;
            state.data = result?.length ? result[0] : {}
        },
    }
})

export const {getPrescriptionByExamination, getPrescriptionByExaminationSuccess, updateDetailData} = prescriptionByExaminationSlice.actions;
export const getPrescriptionByExaminationData = (state) => state.servicePacket.PrescriptionByExamination;
export default prescriptionByExaminationSlice.reducer;