import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  servicePackTypeList: [],
};

const ServicePackType = createSlice({
  name: "ServicePackType",
  initialState,
  reducers: {
    getServicePacketTypeList: () => {},
    getServicePacketTypeListSuccess: (state, { payload }) => {
      const formatData = payload.data.map((obj) => ({ ...obj, value: obj.ID, label: obj.TEN_LOAI_GOI }));
      state.servicePackTypeList = formatData;
    },
  },
});

export const { getServicePacketTypeList, getServicePacketTypeListSuccess } = ServicePackType.actions;
export const getServicePacketTypes = (state) => state.servicePacket.ServicePackType;
export default ServicePackType.reducer;
