import React from "react";
import { Button, Row, Spin, Col } from "antd";
import { HLog, rid } from "helpers";
import style from "./styles/style.module.less";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { chatAiActions, chatAiState } from "ducks/slices/chatAiSlice";
import { genarateSpecialist } from "./helps";
import { LoadingOutlined, CloseOutlined } from "@ant-design/icons";
import { ds_chuyen_khoa } from "./constant";
import Draggable from "react-draggable"; // The default
import i18n,{ languageKeys } from "../../i18n";
class ChatItem {
  constructor(content, user) {
    this.content = content;
    this.user = user;
    this.id = rid(8) + Date.now();
  }

  getObject() {
    return {
      content: this.content,
      user: this.user,
      id: this.id,
    };
  }
}

class MedicalCategory {
  constructor(name, code) {
    this.name = name;
    this.code = code;
  }

  getObj() {
    return { TEN: this.name, MA_CHUYEN_KHOA: this.code };
  }
}

const ChatAi = ({ title = i18n.t(languageKeys.tro_ly_y_khoa_ai), component = () => {} }) => {

  const dispatch = useDispatch();
  const { open, data, loading, history } = useSelector(chatAiState);
  const specialistData = genarateSpecialist(
    data?.answer,
    ds_chuyen_khoa.map((i) => i.TEN)
  );

  /**
   *
   * @param {String} stringData
   */
  const handleStringData = (stringData) => {
    try {
      if(!stringData) return "";
      stringData = JSON.parse(stringData);
      let result = stringData;
      result = stringData.split('\n')?.filter(i => !!i)?.join('<br/>');
      return `<strong>${result}</strong>`;
    } catch (error) {
      console.log(error);
      return "";
    }
  };

  const handleClose = () => {
    dispatch(chatAiActions.setOpen(false));
    dispatch(chatAiActions.setData({}));
  };

  return (
    <Draggable>
      <div
        style={{
          display: open ? "block" : "none",
          zIndex: 1001,
        }}
        className={style["drag-wrapper"]}
      >
        <div className={style["drag-container"]}>
          <Row style={{ paddingInline: 14, paddingBlock: 4 }} className={style["modal-header"]} justify="space-between" align={"middle"}>
            <Col></Col>
            <Col>
              <span className="bold-txt" style={{ fontSize: 24 }}>
                {title}
              </span>
            </Col>
            <Col>
              <Button size="small" type="text" on icon={<CloseOutlined />} onClick={handleClose} />
            </Col>
          </Row>
          <Spin spinning={loading} indicator={<LoadingOutlined />}>
            {" "}
            <div className={style["modal-content-wrapper"]}>
              <div className={classNames(style["modal-content-container"], /*style["style-scroll-bar-hide"]*/)}>
                <>
                  {!!data?.answer && <div>{i18n.t(languageKeys.danh_sach_chuyen_khoa)}:</div> }
                  <p className={style['generator-html']} dangerouslySetInnerHTML={{ __html: handleStringData(data?.answer) }} />
                </>
              {component({data})}
              </div>
            </div>
          </Spin>
        </div>
      </div>
    </Draggable>
  );
};

export default ChatAi;
