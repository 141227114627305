import { SendOutlined,LoadingOutlined } from "@ant-design/icons";
import { Button, Spin } from "antd";
import React, { memo } from "react";
import { Input } from "../../components_v2";
import style from "./styles/style.module.less"

const InputChat = ({ onSubmit = () => {}, loading = false }) => {
  const [string, setString] = React.useState("");

  const handleSubmit = (value = '') => {
    setString("");
    return onSubmit(value);
  }
  return (
    <div
      className={style['input-chat']}
    >
      {" "}
      <Input
        value={string}
        onChange={(e) => !loading && setString(e.target.value)}
        onPressEnter={(e) => !loading && handleSubmit(e.target.value)}
        suffix={
          <Spin spinning={loading}                 
            indicator={
            <LoadingOutlined
              style={{
                fontSize: 14,
                top: 20,                
                left: 18,
              }}
            />
          }>
            <Button
              shape={ "circle"}
            //   loading={loading}
              type={loading ? "ghost" : "primary"}
              onClick={() => !loading && onSubmit(string)}
            >
              <SendOutlined />
            </Button>
          </Spin>
        }
      />
    </div>
  );
};

export default memo(InputChat);
