import { notification } from "antd";
import {
  getServicePackages,
  addPacketService,
  getChildrenService,
  getChildrenServiceSuccess,
  getDetailPacketService,
  getDetailPacketServiceSuccess,
  getPacketServiceList,
  getPacketServiceListSuccess,
  lockPacketService,
  deletePacketService,
  editPacketService,
  getPacketServiceListWithoutFilter,
  getCustomerSourceList,
  getCustomerSourceListSuccess,
} from "ducks/slices/GoiDichVu/DSGoiDichVu/DSGoiDichVuSlice";
import i18n, { languageKeys } from "../../../../i18n";
import moment from "moment";
import { setButtonLoading, setModalLoading, setSelectLoading, setTableLoading } from "ducks/slices/loadingSlice";
import { isEmpty } from "lodash";

const { takeLatest, put, select } = require("redux-saga/effects");
const {
  apiGetPacketServiceList,
  apiGetChildrenService,
  apiAddPacketService,
  apiGetDetailPacketService,
  apiLockPacketService,
  apiDeletePacketService,
  apiEditPacketService,
  apiGetCustomerSourceList,
} = require("./apiDSGoiDichVu");

export function* watchServicePacketList() {
  yield takeLatest(getPacketServiceList, handleGetServicePacketList);
  yield takeLatest(getPacketServiceListWithoutFilter, handleGetServicePacketListWithoutFilter);
  yield takeLatest(getChildrenService, handleGetChildrenService);
  yield takeLatest(addPacketService, handleAddServicePacket);
  yield takeLatest(getDetailPacketService, handleGetDetailPacketService);
  yield takeLatest(editPacketService, handleEditPacketService)
  yield takeLatest(lockPacketService, handleLockPacketService);
  yield takeLatest(deletePacketService, handleDeletePacketService);
  yield takeLatest(getCustomerSourceList, handleGetCustomerSourceList);
}

function* handleGetServicePacketList({ payload }) {
  const { filters = {}, search_string, limit = 15, page = 1, isSearch = false, isLoadingTable = true } = payload;
  const { filters: storedFilters } = yield select(getServicePackages);
  const appliedFilters = !isEmpty(filters) ? filters : storedFilters;
  try {

    isLoadingTable ? yield put(setTableLoading(true)) : yield put(setSelectLoading(true))
    const res = yield apiGetPacketServiceList({
      ...appliedFilters,
      limit,
      search_string: isSearch ? search_string : storedFilters.search_string ? storedFilters.search_string : '',
      page,
      NHOM_GOI: ['DICH_VU', 'GOI_LIEU_TRINH']
    });
    if (res.status === "OK") {
      yield put(getPacketServiceListSuccess({ ...res, limit, filters: { ...appliedFilters, search_string: isSearch ? search_string : storedFilters.search_string ? storedFilters.search_string : '' } }));
    }
  } catch (error) {
    notification.error({
      message: i18n.t(languageKeys.common_co_loi_xay_ra),
    });
  } finally {
    isLoadingTable ? yield put(setTableLoading(false)) : yield put(setSelectLoading(false))
  }
}

function* handleGetServicePacketListWithoutFilter({ payload }) {
  try {
    payload?.isLoadingTable ? yield put(setTableLoading(true)) : yield put(setSelectLoading(true))
    const res = yield apiGetPacketServiceList({
      ...payload,
      TU_NGAY: moment().subtract(20, "years").format("YYYYMMDD"),
      DEN_NGAY: moment().add(30, "years").format("YYYYMMDD"),
      ARR_TRANG_THAI: [],
      ARR_LOAI_GOI_ID: [],
      limit: payload?.limit || 15,
      search_string: payload?.search_string || '',
      page: payload?.page || 1,
      NHOM_GOI: ['DICH_VU', 'GOI_LIEU_TRINH']
    });
    if (res.status === "OK") {
      yield put(getPacketServiceListSuccess({ ...res, limit: payload?.limit, filters: {} }));
    }
  } catch (error) {
    notification.error({
      message: i18n.t(languageKeys.common_co_loi_xay_ra),
    });
  } finally {
    payload?.isLoadingTable ? yield put(setTableLoading(false)) : yield put(setSelectLoading(false))
  }
}

function* handleAddServicePacket({ payload }) {
  const { dataRequest, callback } = payload;
  const userProfile = yield select(state => state.auth.user);
  const dataCreate = {
    NHANSU_ID: userProfile?.NHANSU_ID,
    NGUOI_CAP_NHAT: `${userProfile?.HO} ${userProfile?.TEN}`,
    NGUOI_CAP_NHAT_ID: userProfile?.NHANSU_ID,
    GIO_CAP_NHAT: moment().format('H:mm'),
    NGAY_CAP_NHAT: moment().format('YYYYMMDD')
  }
  try {
    const { dataSourceTableService } = yield select(getServicePackages);
    const formatServiceList = dataSourceTableService?.map((item) => {
      const { DV_ID, MA_DICHVU, TEN_DICHVU, SL, PHAN_LOAI, GIA_THU_PHI, THANH_TIEN, DON_GIA_PHAN_BO, THANH_TIEN_PHAN_BO, PHONG_THUC_HIEN_ID, TY_LE } = item?.value;
      return {
        ...dataCreate,
        DV_ID,
        MA_DV: MA_DICHVU,
        TEN_DV: TEN_DICHVU,
        SO_LUONG: `${SL}`,
        PHAN_LOAI,
        DON_GIA: GIA_THU_PHI,
        THANH_TIEN,
        DON_GIA_PHAN_BO,
        THANH_TIEN_PHAN_BO,
        PHONG_THUC_HIEN_ID,
        LOAI_GIA: null,
        TY_LE,
        DV_KEM_THEO: item?.value?.children?.map((i) => ({
          ...dataCreate,
          DV_ID: i.DV_ID,
          MA_DV: i.MA_DV,
          TEN_DV: i.TEN_DICHVU,
          SO_LUONG: i.SL,
          LOAI_GIA: null,
          DON_GIA: i.DON_GIA,
          THANH_TIEN: i.THANH_TIEN,
          DON_GIA_PHAN_BO: i.DON_GIA_PHAN_BO,
          THANH_TIEN_PHAN_BO: i.THANH_TIEN_PHAN_BO,
          PHAN_LOAI: i.PHAN_LOAI,
          TY_LE: i.TY_LE,
          PHONG_THUC_HIEN_ID: i.PHONG_THUC_HIEN_ID,
        })),
      };
    });
    yield put(setButtonLoading(true))
    const res = yield apiAddPacketService({ ...dataRequest, DS_DV: formatServiceList });
    if (res.status === "OK") {
      yield put(setButtonLoading(false))
      callback();
      yield put(
        getPacketServiceList({
          status: [],
          TU_NGAY: moment().format("YYYYMMDD"),
          DEN_NGAY: moment().format("YYYYMMDD"),
          packetType: [],
        })
      );
      notification.success({
        message: i18n.t(languageKeys.them_moi_thanh_cong),
        placement: "bottomLeft",
      });
    }
  } catch (error) {
    yield put(setButtonLoading(false))
    callback();
    notification.error({
      message: i18n.t(languageKeys.them_moi_that_bai),
      placement: "bottomLeft",
    });
  } finally {
    yield put(setButtonLoading(false))
  }
}

function* handleGetChildrenService({ payload }) {
  const res = yield apiGetChildrenService({ DV_ID: payload });
  if (res.status === "OK") {
    yield put(getChildrenServiceSuccess(res.result));
  }
}

function* handleGetDetailPacketService({ payload }) {
  yield put(setModalLoading(true))
  const res = yield apiGetDetailPacketService({ ID: payload.id });
  const { NHANSU_ID } = yield select(state => state.auth.user)
  if (res.status === "OK") {
    yield put(getDetailPacketServiceSuccess({
      data: res.result[0],
      isCopy: payload?.isCopy,
      currentDetail: payload?.currentDetail,
      NGUOI_CAP_NHAT: payload?.NGUOI_CAP_NHAT,
      NHANSU_ID,
      actionType: payload?.actionType,
      GIO_CAP_NHAT: moment().format('H:mm'),
      NGAY_CAP_NHAT: moment().format('YYYYMMDD'),
      VAT_TU: res.VAT_TU
    }));
  }
  yield put(setModalLoading(false))
}

function* handleLockPacketService({ payload }) {
  try {
    yield setTableLoading(true);
    const { arr_id, isLock, callback } = payload;
    yield apiLockPacketService({
      arr_ID: arr_id,
      isKhoa: isLock,
    });
    const { currentPage } = yield select(getServicePackages);
    yield put(
      getPacketServiceList({
        page: currentPage,
      })
    );
    if (typeof callback === "function") callback();
    notification.success({
      message: i18n.t(languageKeys.cap_nhat_thanh_cong),
      placement: "bottomLeft",
    });
  } catch {
    notification.error({
      message: i18n.t(languageKeys.cap_nhat_that_bai),
      placement: "bottomLeft",
    });
  } finally {
    yield put(setTableLoading(false));
  }
}

function* handleDeletePacketService({ payload }) {
  try {
    yield setTableLoading(true);
    const { arr_id, callback } = payload;
    const res = yield apiDeletePacketService({
      arr_ID: arr_id,
    });
    if (!!res && res?.status === 'OK') {
      const { currentPage } = yield select(getServicePackages);
      yield put(
        getPacketServiceList({
          page: currentPage,
        })
      );
      if (typeof callback === "function") callback();
      notification.success({
        message: i18n.t(languageKeys.noti_Xoa_thanh_cong),
        placement: "bottomLeft",
      });
    } else {
      notification.error({
        message: i18n.t(languageKeys.xoa_that_bai),
        placement: "bottomLeft",
      });
    }
  } catch {
    notification.error({
      message: i18n.t(languageKeys.xoa_that_bai),
      placement: "bottomLeft",
    });
  } finally {
    yield put(setTableLoading(false));
  }
}

function* handleEditPacketService({ payload }) {
  const { dataRequest, callback } = payload;
  const userProfile = yield select(state => state.auth.user);
  const dataUpdate = {
    NGUOI_CAP_NHAT: `${userProfile?.HO} ${userProfile?.TEN}`,
    NGUOI_CAP_NHAT_ID: userProfile?.NHANSU_ID,
    GIO_CAP_NHAT: moment().format('H:mm'),
    NGAY_CAP_NHAT: moment().format('YYYYMMDD')
  }
  try {
    const { dataSourceTableService } = yield select(getServicePackages);
    const formatServiceList = dataSourceTableService?.map(item => {
      const { DV_ID, MA_DICHVU, TEN_DICHVU, SL, PHAN_LOAI, GIA_THU_PHI, THANH_TIEN, DON_GIA_PHAN_BO, THANH_TIEN_PHAN_BO, PHONG_THUC_HIEN_ID, NGUOI_CAP_NHAT_ID, TY_LE, NGAY_CAP_NHAT, GIO_CAP_NHAT, NGUOI_CAP_NHAT, IS_CAP_NHAT } = item?.value
      return {
        DV_ID,
        MA_DV: MA_DICHVU,
        TEN_DV: TEN_DICHVU,
        SO_LUONG: `${SL}`,
        PHAN_LOAI,
        DON_GIA: GIA_THU_PHI,
        THANH_TIEN,
        DON_GIA_PHAN_BO,
        THANH_TIEN_PHAN_BO,
        PHONG_THUC_HIEN_ID,
        LOAI_GIA: null,
        TY_LE: TY_LE,
        NGUOI_CAP_NHAT_ID: IS_CAP_NHAT ? dataUpdate?.NGUOI_CAP_NHAT_ID : NGUOI_CAP_NHAT_ID,
        NGUOI_CAP_NHAT: IS_CAP_NHAT ? dataUpdate?.NGUOI_CAP_NHAT : NGUOI_CAP_NHAT,
        GIO_CAP_NHAT: IS_CAP_NHAT ? dataUpdate?.GIO_CAP_NHAT : GIO_CAP_NHAT,
        NGAY_CAP_NHAT: IS_CAP_NHAT ? dataUpdate?.NGAY_CAP_NHAT : NGAY_CAP_NHAT,
        DV_KEM_THEO: item?.value?.children?.map(i => ({
          DV_ID: i.DV_ID,
          MA_DV: i.MA_DV,
          TEN_DV: i.TEN_DICHVU,
          SO_LUONG: i.SL,
          LOAI_GIA: null,
          DON_GIA: i.DON_GIA,
          THANH_TIEN: i.THANH_TIEN,
          DON_GIA_PHAN_BO: i.DON_GIA_PHAN_BO,
          THANH_TIEN_PHAN_BO: i.THANH_TIEN_PHAN_BO,
          PHAN_LOAI: i.PHAN_LOAI,
          TY_LE: i.TY_LE,
          PHONG_THUC_HIEN_ID: i.PHONG_THUC_HIEN_ID,
          NGUOI_CAP_NHAT_ID: i.IS_CAP_NHAT ? dataUpdate?.NGUOI_CAP_NHAT_ID : i.NGUOI_CAP_NHAT_ID,
          NGUOI_CAP_NHAT: i.IS_CAP_NHAT ? dataUpdate?.NGUOI_CAP_NHAT : i.NGUOI_CAP_NHAT,
          NGAY_CAP_NHAT: i.IS_CAP_NHAT ? dataUpdate?.NGAY_CAP_NHAT : i.NGAY_CAP_NHAT,
          GIO_CAP_NHAT: i.IS_CAP_NHAT ? dataUpdate?.GIO_CAP_NHAT : i.GIO_CAP_NHAT
        }))
      }
    })
    yield put(setButtonLoading(true))
    const res = yield apiEditPacketService({ ...dataRequest, DS_DV: formatServiceList })
    if (res.status === 'OK') {
      callback();
      yield put(getPacketServiceList({
        status: [],
        TU_NGAY: moment().format("YYYYMMDD"),
        DEN_NGAY: moment().format("YYYYMMDD"),
        packetType: [],
      }))
      notification.success({
        message: i18n.t(languageKeys.cap_nhat_thanh_cong),
        placement: 'bottomLeft'
      })
    }
  } catch (error) {
    yield put(setButtonLoading(false))
    callback();
    notification.error({
      message: i18n.t(languageKeys.cap_nhat_that_bai)
    })
  } finally {
    yield put(setButtonLoading(false))
  }
}

function* handleGetCustomerSourceList({ payload }) {
  try {
    yield put(setModalLoading(true))
    const { nguonKhachId } = payload
    const res = yield apiGetCustomerSourceList({ DM_NHOM_NGUON_KHACH_ID: nguonKhachId, KHOA: 0, limit: 10000 })
    if (!!res && res.status === 'OK') {
      yield put(getCustomerSourceListSuccess(res.result))
    }
  } catch (error) {

  } finally {
    yield put(setModalLoading(false))
  }
}
