import { formatCurrency2, layDiaChi } from "helpers";
import i18n, { languageKeys } from "../../../../i18n";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import JsBarcode from "jsbarcode";
import { nanoid } from "@reduxjs/toolkit";
import Images from "components/Images/Images";

export const stylePhieuHoanTien = `
#phieuHoanTien * {
  font-size: 12px;
  line-height: 1rem;
    font-family: "Times New Roman", Times, serif;
}

#phieuHoanTien .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}

#phieuHoanTien .header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: '80px';
    font-size: 10px;
}

#phieuHoanTien .header-left {
    min-width: 75%;
    display: flex;
    gap: 10px;
    flex-direction: row;
  }

#phieuHoanTien .header-right {
    min-width: 25%;
    position: relative;
    display: flex;
    flex-direction: column ;
    align-items: center ;
    justify-content: center ;
    text-align:  center ;
    position:  relative ;
    min-width:  150px ;
}

#phieuHoanTien .header-department {
    text-transform: uppercase;
    font-weight: bold;
}

#phieuHoanTien .header-date {
    font-style: italic;
}

#phieuHoanTien .hospital-name {
    text-transform: uppercase;
    font-weight: bold;
}


#phieuHoanTien .title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 90%;
  margin: 2.5rem auto 0.25rem auto;
}

#phieuHoanTien .sub-title {
  margin-block: 0.5rem;
  font-size: 12px;
  font-weight: bold;
}

#phieuHoanTien .info-customer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

#phieuHoanTien .info-customer-item {
  max-width: 40%;
}

#phieuHoanTien .date-time {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    font-weight: 400;
    font-style: italic;
}

#phieuHoanTien .report {
    text-align: center;
    font-size: 12px;
    line-height: 18px;
    font-weight: bold;
    font-style: italic;
}

#phieuHoanTien .table {
    margin-block: 1rem;
    width: 100%;
}

#phieuHoanTien .table * {
    font-size: 12px;
}

#phieuHoanTien .table,
.table th, 
.table td {
    border: 1px solid black;
    border-collapse: collapse;
}

#phieuHoanTien .table th,
.table td {
    padding: 5px 10px;
}

#phieuHoanTien .date-time-footer{
    margin-top: 40px;
    font-size: 14px;
    text-align: end;
    padding-right: 20px;
}
#phieuHoanTien .footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 50px;

}
#phieuHoanTien .footer-chu-ky{
    text-align: center;
    font-size: 12px;
    font-weight: 700;
}
#phieuHoanTien .ky-ten {
    font-style: italic;
    font-size: 14px;
    font-weight: normal;
}

#phieuHoanTien .sub-barcode-text {
  font-size:12px;
  position:absolute;
  top:50px;
  left:50%;
  line-height: 1.2em;
  transform: translateX(-50%);
}

#phieuHoanTien .phone-website { display: flex; gap: 24px; align-items: center }

@media print {
    #phieuHoanTien, #phieuHoanTien .foot-break {
        break-inside: avoid;
    }
    #phieuHoanTien { page-break-after: always}
`;

export const stylePhieuHoanTienGL = `
#phieuHoanTien * {
  font-size: 14pt;
    font-family: "Times New Roman", Times, serif;
}

#phieuHoanTien .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}

#phieuHoanTien .header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    gap: '80px';
    font-size: 14pt;
}

#phieuHoanTien .header-left {
    min-width: 75%;
    display: flex;
    gap: 10px;
    flex-direction: row;
  }

#phieuHoanTien .header-right {
    min-width: 25%;
    position: relative;
    display: flex;
    flex-direction: column ;
    align-items: center ;
    justify-content: center ;
    text-align:  center ;
    position:  relative ;
    min-width:  150px ;
}

#phieuHoanTien .header-department {
    text-transform: uppercase;
    font-weight: bold;
}

#phieuHoanTien .header-date {
    font-style: italic;
}

#phieuHoanTien .hospital-name {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16pt;
}


#phieuHoanTien .title {
  text-transform: uppercase;
  font-size: 16pt;
  font-weight: bold;
  text-align: center;
  width: 90%;
  margin: 2.5rem auto 0.25rem auto;
}

#phieuHoanTien .sub-title {
  margin-block: 0.5rem;
  font-size: 14pt;
  font-weight: bold;
}

#phieuHoanTien .info-customer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

#phieuHoanTien .info-customer-item {
  max-width: 40%;
}

#phieuHoanTien .date-time {
    text-align: center;
    font-size: 14pt;
    line-height: 18pt;
    font-weight: 400;
    font-weight: 400;
    font-style: italic;
}

#phieuHoanTien .report {
    text-align: center;
    font-size: 14pt;
    line-height: 18pt;
    font-weight: bold;
    font-style: italic;
}

#phieuHoanTien .table {
    margin-block: 1rem;
    width: 100%;
}

#phieuHoanTien .table * {
    font-size: 14pt;
}

#phieuHoanTien .table,
.table th, 
.table td {
    border: 1px solid black;
    border-collapse: collapse;
}

#phieuHoanTien .table th,
.table td {
    padding: 5px 10px;
}

#phieuHoanTien .date-time-footer{
    margin-top: 40px;
    font-size: 14pt;
    text-align: end;
    padding-right: 20px;
}
#phieuHoanTien .footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 50px;

}
#phieuHoanTien .footer-chu-ky{
    text-align: center;
    font-size: 12pt;
    font-weight: 700;
}
#phieuHoanTien .ky-ten {
    font-style: italic;
    font-size: 14pt;
    font-weight: normal;
}

#phieuHoanTien .sub-barcode-text {
  font-size:14pt;
  position:absolute;
  top:50px;
  left:50%;
  line-height: 1.2em;
  transform: translateX(-50%);
}

#phieuHoanTien .phone-website { display: flex; gap: 24px; align-items: center }

@media print {
    #phieuHoanTien, #phieuHoanTien .foot-break {
        break-inside: avoid;
    }
    #phieuHoanTien { page-break-after: always}
`;

const PhieuHoanTien = ({ data, rid }) => {

  const {hospital, benh_nhan, cac_phuong_thuc_tt} = data
  const userProfile = useSelector((state) => state.auth.user);

  let dv_goi = data.dv || data.dv_goi

  useEffect(() => {
    if (!!rid && !!benh_nhan.ma_benh_nhan) {
      JsBarcode(`#barcode-${rid}`)
        .options({ font: "OCR-B" })
        .CODE128(benh_nhan.ma_benh_nhan, {
          text: ` `,
          fontSize: 10,
          width: 1.2,
          height: 40,
          textMargin: 0,
          margin: 0,
          padding: 0,
        })
        .render();
    }
  }, [benh_nhan.ma_benh_nhan, rid]);
  
  try {
    // let { exportData } = data;
    // exportData = (() => {
    //   let count = 1;
    //   const list = exportData.map((item) => {
    //     return {
    //       ...item,
    //       key: nanoid(),
    //       DICH_VU: item?.DICH_VU?.map((dich_vu) => {
    //         let STT = "";
    
    //         if (count + 1 <= 10) {
    //           STT = `0${count++}`;
    //         } else {
    //           STT = count++;
    //         }
    
    //         return { ...dich_vu, key: nanoid(), STT };
    //       }),
    //     };
    //   });
  
    //   return list;
    // })();

    // const timeRange = !data?.dataRequest?.TIME[0]
    //   ? `${moment().format('DD/MM/YYYY')} ${i18n.t(languageKeys.field_to)} ${moment().format('DD/MM/YYYY')}`
    //   : `${moment(data?.dataRequest?.TIME[0], 'YYYYMMDD').format('DD/MM/YYYY')} ${i18n.t(languageKeys.field_to)} ${moment(
    //       data?.dataRequest?.TIME[1],
    //       'YYYYMMDD',
    //     ).format('DD/MM/YYYY')}`;

    // const room =
    //   data?.dataRequest?.functionRoom?.length ?? 0 === 0
    //     ? i18n.t(languageKeys.all)
    //     : data?.dataRequest?.functionRoom?.length === 1
    //     ? data?.dataRequest?.functionRoom[0]?.TEN_KHOA_PHONG
    //     : `${data?.dataRequest?.functionRoom?.length} ${i18n.t(languageKeys.room)}`;
    // const trang_thai =
    //   data?.dataRequest?.executionStatus === 'DA_THUC_HIEN'
    //     ? i18n.t(languageKeys.accomplished)
    //     : data?.dataRequest?.executionStatus === 'CHO_THUC_HIEN'
    //     ? i18n.t(languageKeys.unaccomplished)
    //     : i18n.t(languageKeys.all);
    return Array(2).fill(null).map((_, index) => (
      <div id="phieuHoanTien" key={index}>
        <div className="header">
          <div className="header-left">
            {userProfile.benh_vien.ANH_DAI_DIEN && (
              <Images className="logo" imgId={hospital.avatar}/>
            )}
            <div>
              <div className="hospital-name">{hospital.name}</div>
              <div>
                {i18n.t(languageKeys.dia_chi)} : {hospital.address}
              </div>
              <div className="phone-website">
                <div>
                  {i18n.t(languageKeys.dien_thoai)}:{' '}
                  {hospital.phone || ''}
                </div>
              </div>
              <div>
                  Website: {userProfile?.benh_vien?.WEBSITE || ''}
                </div>
              <div>
                Email: {userProfile?.benh_vien?.EMAIL || ''}
              </div>
            </div>
          </div>

          <div className="header-right">
            <svg id={`barcode-${rid}`}></svg>
            <div className="sub-barcode-text">
              <div style={{width: '300px', textAlign: 'center'}}>{i18n.t(languageKeys.field_Ma_BN)}: <b>{benh_nhan.ma_benh_nhan}</b></div>
              <div style={{width: '300px', textAlign: 'center'}}>{i18n.t(languageKeys.field_Ma_phieu)}: <b>{benh_nhan.SO_PHIEU}</b></div>
            </div>
          </div>
        </div>

        <div className="title">{i18n.t(languageKeys.phieu_hoan)}</div>
        <div className="sub-title" style={{ textAlign: "center", lineHeight: "1em", marginBottom: '2rem' }}>({i18n.t(languageKeys.hoa_don_lien)} {index + 1}: {index + 1 === 1 ? i18n.t(languageKeys.thu_ngan_giu) : i18n.t(languageKeys.khach_hang_giu)})</div>

        <div className="info-customer">
          <div className="info-customer-item">
            <div>{i18n.t(languageKeys.ho_va_ten_khach_hang)}: {benh_nhan.ten_benh_nhan}</div>
            <div>{i18n.t(languageKeys.field_So_dien_thoai)}: {benh_nhan.sdt}</div>
            <div>{i18n.t(languageKeys.dia_chi)}: {benh_nhan.dia_chi}</div>
            <div>{i18n.t(languageKeys.goi_dich_vu)}: {data.ten_goi_dv || ''}</div>
            <div>{i18n.t(languageKeys.field_hinh_thuc_thanh_toan)}: {cac_phuong_thuc_tt?.length > 0 ? Object.keys(cac_phuong_thuc_tt[0])[0]: ''}</div>
            <div>{i18n.t(languageKeys.field_Ly_do_hoan_tien)}: {benh_nhan.ly_do_hoan_tien || ''}</div>
          </div>
          <div className="info-customer-item">
            <div>{i18n.t(languageKeys.field_Ngay_sinh)}: {benh_nhan.ngay_sinh}</div>
            <div>{i18n.t(languageKeys.field_Doi_tuong)}: {benh_nhan.doi_tuong}</div>
            <div>{i18n.t(languageKeys.loai_goi)}: {benh_nhan.ten_loai_goi}</div>
          </div>
          <div className="info-customer-item">{i18n.t(languageKeys.field_Gioi_tinh)}: {benh_nhan.gioi_tinh}</div>
        </div>

        <table className="table">
          <thead>
            <th style={{textAlign: 'center'}}>{i18n.t(languageKeys.stt)}</th>
            <th style={{textAlign: 'center'}}>{i18n.t(languageKeys.field_Ten_dich_vu)}</th>
            <th style={{textAlign: 'center'}}>{i18n.t(languageKeys.sl)}</th>
            <th style={{textAlign: 'center'}}>{i18n.t(languageKeys.field_don_gia)}</th>
            <th style={{textAlign: 'center'}}>{i18n.t(languageKeys.field_tong_tien)}</th>
            <th style={{textAlign: 'center'}}>{i18n.t(languageKeys.field_doi_tuong_mien_giam)}</th>
            <th style={{textAlign: 'center'}}>{i18n.t(languageKeys.vien_phi_thanh_tien)}</th>
          </thead>

          <tbody>
          {dv_goi?.map((item, index) => (
            <>
            <tr key={nanoid()}>
                <td style={{width: '5%', textAlign: 'center'}}>
                  {index + 1}
                </td>
                <td style={{ width: '30%' }}>
                  {item.TEN_DV}
                </td>
                <td align="center" style={{width: '5%', textAlign: 'center'}}>
                  {item.SO_LUONG}
                </td>
                <td style={{ width: '15%', textAlign: 'end'}}>
                  {formatCurrency2(item.DON_GIA, '', true)}
                </td>
                <td style={{ width: '15%', textAlign: 'end'}}>
                  {formatCurrency2(item.DON_GIA * item.SO_LUONG, '', true)}
                </td>
                <td style={{ width: '15%', textAlign: 'end'}}>
                  {formatCurrency2(item.MIEN_GIAM, '', true)}
                </td>
                <td style={{ width: '15%', textAlign: 'end'}}>
                  {formatCurrency2(item.THANH_TIEN, '', true)}
                </td>
              </tr>
              {item?.DV_KEM_THEO?.length > 0 && item?.DV_KEM_THEO?.map(i => {
                return (
                  <tr key={nanoid()}>
                    <td></td>
                    <td style={{ width: '30%' }}>
                      <div style={{marginLeft: 10}}>
                        {i.TEN_DV}
                      </div>
                    </td>
                    <td style={{width: '5%', textAlign: 'center'}}>
                      {i.SO_LUONG}
                    </td>
                    <td style={{ width: '15%', textAlign: 'end'}}>
                      {formatCurrency2(i.DON_GIA, '', true)}
                    </td>
                    <td style={{ width: '15%', textAlign: 'end' }}>
                      {formatCurrency2(i.DON_GIA * i.SO_LUONG, '', true)}
                    </td>
                    <td style={{ width: '15%', textAlign: 'end' }}>
                      {formatCurrency2(i.MIEN_GIAM, '', true)}
                    </td>
                    <td style={{ width: '15%', textAlign: 'end' }}>
                      {formatCurrency2(i.THANH_TIEN, '', true)}
                    </td>
                  </tr>
                )
              })}
            </>
            ))}
              {/* <>
                <tr key={rid()}>
                  <td style={{width: '5%'}}>
                    1
                  </td>
                  <td style={{ width: '30%' }}>
                    Dich vu mat xa
                  </td>
                  <td style={{width: '5%', textAlign: 'center'}}>
                    7
                  </td>
                  <td style={{ width: '15%', textAlign: 'end'}}>
                    1.000.000
                  </td>
                  <td style={{ width: '15%', textAlign: 'end'}}>
                    500.000
                  </td>
                  <td style={{ width: '15%', textAlign: 'end'}}>
                    500000
                  </td>
                  <td style={{ width: '15%', textAlign: 'end'}}>
                    312312
                  </td>
                </tr>
                <tr key={rid()}>
                  <td></td>
                  <td style={{ width: '30%' }}>
                    <div style={{marginLeft: 10}}>
                       Dich vu mat xa 1
                    </div>
                  </td>
                  <td style={{width: '5%', textAlign: 'center'}}>
                    7
                  </td>
                  <td style={{ width: '15%', textAlign: 'end'}}>
                    1.000.000
                  </td>
                  <td style={{ width: '15%', textAlign: 'end' }}>
                    500.000
                  </td>
                  <td style={{ width: '15%', textAlign: 'end' }}>
                    500000
                  </td>
                  <td style={{ width: '15%', textAlign: 'end' }}>
                    312312
                  </td>
                </tr>
              </> */}
            
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>{i18n.t(languageKeys.tong_cong)}</td>
              <td style={{textAlign: 'center', fontWeight: 'bold'}}>{dv_goi.reduce((total, curr) => total += curr.SO_LUONG, 0)}</td>
              <td style={{textAlign: 'end', fontWeight: 'bold'}}>{formatCurrency2(dv_goi.reduce((total, curr) => total += curr.DON_GIA , 0), '', true)}</td>
              <td style={{textAlign: 'end', fontWeight: 'bold'}}>{formatCurrency2(dv_goi.reduce((total, curr) => total += (curr.DON_GIA * curr.SO_LUONG), 0), '', true)}</td>
              <td style={{textAlign: 'end', fontWeight: 'bold'}}>{formatCurrency2(dv_goi.reduce((total, curr) => total += curr.MIEN_GIAM, 0), '', true)}</td>
              <td style={{textAlign: 'end', fontWeight: 'bold'}}>{formatCurrency2(dv_goi.reduce((total, curr) => total += curr.THANH_TIEN, 0), '', true)}</td>
            </tr>
          </tfoot>
        </table>
        <div style={{display: 'flex', justifyContent: 'space-between' }}>
          <div></div>
          <div style={{ width: '350px'}}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: '20px',
                alignItems: "center",
              }}
            >
              <div>(1) {i18n.t(languageKeys.tong_tien)}:</div>
              <b>{formatCurrency2(data.thanh_toan, '', true)}</b>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 20,
                alignItems: "center",
              }}
            >
              <div>(2) {i18n.t(languageKeys.field_Mien_giam)}:</div>
              <b>{formatCurrency2(dv_goi.reduce((total, curr) => total += curr.MIEN_GIAM, 0), '', true)}</b>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 20,
                alignItems: "center",
                borderTop: "1px solid #000",
                marginTop: 5,
                paddingTop: 5,
              }}
            >
              <div>(3) = (1) - (2) {i18n.t(languageKeys.tong_thanh_toan)}:</div>
              <b>{formatCurrency2(data.thanh_toan - dv_goi.reduce((total, curr) => total += curr.MIEN_GIAM, 0), '', true)}</b>
            </div>
          </div>
        </div>

        <div className="date-time-footer">
            {`${data.gio}, ${data.ngay_tao}`}
        </div>
        <div className="footer">
          <div>
            <div className="footer-chu-ky">
              {i18n.t(languageKeys.khach_hang)}
              <div className="ky-ten">{i18n.t(languageKeys.ky_ghi_ro_ho_ten)}</div>
            </div>
          </div>
          <div>
            <div className="footer-chu-ky">
              {i18n.t(languageKeys.data_Thu_ngan)}
              <div className="ky-ten">{i18n.t(languageKeys.ky_ghi_ro_ho_ten)}</div>
              <div style={{ marginTop: 50 }}>{data?.ten_nhan_su || ""}</div>
            </div>
          </div>
        </div>
      </div>
    ))
  } catch (error) {
    console.log(error);
    return (
      <div id="phieuHoanTien" style={{ width: '100%' }}>
        {/* <Result
          style={{ display: 'block', margin: 'auto', paddingInline: '30%', textAlign: 'center' }}
          status="500"
          title="Có lỗi xảy ra"
        /> */}
      </div>
    );
  }
};

export default PhieuHoanTien