const { createSlice } = require("@reduxjs/toolkit");

const initState = {
    data: {}
}

const PackageUsagePaymentHistorySlice = createSlice({
    name: 'PackageUsagePaymentHistorySlice',
    initialState: initState,
    reducers: {
        fetchPacketUsagePaymentHistory: () => {},
        fetchPacketUsagePaymentHistorySuccess: (state, action) => {
            const {result} = action.payload;
            state.data = result;
        },
        clearPacketUsagePaymentHistory: (state) => {
            state.data=[]
        }
    }
})

export const {fetchPacketUsagePaymentHistory, fetchPacketUsagePaymentHistorySuccess, clearPacketUsagePaymentHistory} = PackageUsagePaymentHistorySlice.actions;
export const getPackageUsagePaymentHistoryData = (state) => state.servicePacket.PackageUsagePaymentHistory;
export default PackageUsagePaymentHistorySlice.reducer;