import { put } from 'redux-saga/effects';
import { takeLatest } from '@redux-saga/core/effects';
import { apis } from "../../../constants";
import { doFetchMedSupplies, doFetchSuppliesFromStorage, fetchMedSuppliesSuccess, setSupplyLoading } from 'ducks/slices/ListData/Supply.slice';
import { common_post } from 'helpers';

export function* watchDoSupplies() {
    yield takeLatest(doFetchSuppliesFromStorage, handleFetchSuppliesFromStorage)
    yield takeLatest(doFetchMedSupplies, handleFetchMedSupplies)
}

function* handleFetchSuppliesFromStorage(action) {
    const { search_string = "", KHO_VAT_TU_ID, setData } = action.payload
    try {
        yield put(setSupplyLoading(true));
        const res = yield common_post(apis.dm_lay_ds_vat_tu_trong_kho, {
            page: 1,
            limit: 20,
            search_string,
            KHO_VAT_TU_ID,
        })
        if (res) {
            if (res.status === 'OK') {
                // yield put(fetchSuppliesFromStorageSuccess({
                //     ...res,
                //     result: res.result?.map(item => ({ ...item, KHO_VAT_TU_ID }))
                // }));
                setData(res.result?.map(item => ({ ...item, KHO_VAT_TU_ID })))
            }
        }
    } catch (err) {
        console.log(err.message)
    } finally {
        yield put(setSupplyLoading(false));
    }
}

function* handleFetchMedSupplies(action) {
    const { search_string = "" } = action.payload
    try {
        yield put(setSupplyLoading(true));
        const res = yield common_post(apis.dm_lay_ds_thuoc_vat_tu, {
            page: 1,
            limit: 20,
            search_string,
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchMedSuppliesSuccess(res));
            }
        }
    } catch (err) {
        console.log(err.message)
    } finally {
        yield put(setSupplyLoading(false));
    }
}