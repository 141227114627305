import { createSlice } from '@reduxjs/toolkit';

const initState = {
    city: [],
    district: [],
    ward: [],
};

const CityDistrictWardSlice = createSlice({
    name: 'CityDistrictWard',
    initialState: initState,
    reducers: {
        getCity: () => { },
        getCitySuccess: (state, action) => {
            state.city = action.payload.result;
        },
        getDistrict: () => { },
        getDistrictSuccess: (state, action) => {
            state.district = action.payload.result;
        },
        getWard: () => { },
        getWardSuccess: (state, action) => {
            state.ward = action.payload.result;
        },
        clearDistrictWard: (state) => {
            state.district = []
            state.ward = []
        }
    }
});

export const { getCity, getCitySuccess, getDistrict, getDistrictSuccess, getWard, getWardSuccess, clearDistrictWard } = CityDistrictWardSlice.actions;
export const getCityDistrictWard = (state) => state.listData.CityDistrictWard;

export default CityDistrictWardSlice.reducer;