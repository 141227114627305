import { Layout, Spin } from "antd";
import React, { memo, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import { paths } from "../constants";
import { featureKeys } from "../constants/keys";
import { doCheckUser } from "../ducks/slices/authSlice";
import { rid } from "../helpers";
import { useEventAppointment, useIndexedDb } from "../hooks";
import i18n, { languageKeys } from "../i18n";
import { NotFound } from "../pages";
import EventSourceClient from "../routes/eventSource/EventSourceClient";
import { FeatureRoute } from "./route";
import SubHeader from "layouts/SubHeader/SubHeader";
import { MauMoTa, TraKetQua } from "assets/svg";
import RightMenu from "layouts/RightMenu";
import { routeApp } from "../constants/keys";
import { Chatbot } from "features";
const MauMoTaCdha = React.lazy(()=> import ("../pages/RisNhapTay/MauMoTaCdha/MauMoTaCdha"));
const TraKetQuaCdha = React.lazy(() => import("../pages/RisNhapTay/TraKetQuaCdha/TraKetQuaCdha"));


const RISMenuItems = [
  {
    featureKey: `${featureKeys.quan_ly_chan_doan_hinh_anh_ris}.${featureKeys.mau_mo_ta_cdha}`,
    key: "/ris/mau-mo-ta-cdha",
    // icon: <MauMoTa />,
    label: i18n.t(languageKeys.menu_mau_mo_ta_cdha),
  },
  {
    featureKey:  `${featureKeys.quan_ly_chan_doan_hinh_anh_ris}.${featureKeys.tra_ket_qua_cdha}`,
    key: "/ris/tra-ket-qua-cdha",
    // icon: <TraKetQua />,
    label: i18n.t(languageKeys.menu_tra_kq_cdha),
  },
];

export const RISRoute = () => {
  const dispatch = useDispatch();
  const { user, tokens } = useSelector((state) => state.auth);
  const { loadingInitData } = useIndexedDb();
  const { eventClient, reloadAppointment } = useEventAppointment();

  const currentRoute = window.location.href;
  useEffect(() => {
    if (!user && !!tokens) {
      dispatch(doCheckUser(tokens));
    }
  }, [dispatch, tokens?.token]);

  if (!user) {
    return <LoadingComponent />;
  }
  return (
    <Spin
      spinning={loadingInitData}
      tip={
        <div style={{ textAlign: "center" }}>
          <div>{i18n.t(languageKeys.text_khoi_tao_du_lieu)}</div>
        </div>
      }
    >
      <App redirectPath={PathRis.traKetQua} currentRoute={currentRoute} />

      <EventSourceClient ref={eventClient} callEventHandlers={{ reloadAppointment }} />
    </Spin>
  );
};

const App = memo(({ redirectPath, currentRoute }) => {
  const baoCaoRisRef = useRef();
  const history = useHistory();

  const handleNavigate = ({ keyPath, key }) => {
    history.push(keyPath.pop());
    if (baoCaoRisRef.current) {
      baoCaoRisRef?.current.setViewKey(key);
    }
  };
  return (
    <Layout>
      <SubHeader menuItem={RISMenuItems} onNavigate={handleNavigate} module={routeApp.RIS}/>
      <Layout>
        <Layout.Content>
          <Switch>
            {featureRoutes.map((route) => (
              <FeatureRoute key={rid()} exact path={route.path} component={route.component} featureKey={route.key} />
            ))}
            {/*<FeatureRoute key={rid()} exact path={PathRis.baoCao} component={() => <BaoCaoRis ref={baoCaoRisRef} isTach={true} />} />*/}
            <Redirect exact from={paths.ris} to={redirectPath} />
            <Route component={NotFound} />
          </Switch>
        </Layout.Content>
        <RightMenu currentFeature={routeApp.RIS} />
      </Layout>
    </Layout>
  );
});

const LoadingComponent = () => (
  <div style={{ width: "100vw", height: "100vh", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
    <Spin spinning />
    <h3 className="blue-txt">{i18n.t(languageKeys.updatingAccInfo)}...</h3>
  </div>
);

export const PathRis = {
  mauMoTa: "/ris/mau-mo-ta-cdha",
  traKetQua: "/ris/tra-ket-qua-cdha",
  // baoCao: "/ris/bao-cao-cdha",
};

const featureRoutes = [
  {
    key: `${featureKeys.quan_ly_chan_doan_hinh_anh_ris}.${featureKeys.mau_mo_ta_cdha}`,
    path: PathRis.mauMoTa,
    component: MauMoTaCdha,
  },
  {
    key: `${featureKeys.quan_ly_chan_doan_hinh_anh_ris}.${featureKeys.tra_ket_qua_cdha}`,
    path: PathRis.traKetQua,
    component: TraKetQuaCdha,
  },
];
