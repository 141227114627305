import { createSlice } from '@reduxjs/toolkit';

const initState = {
  personnel: [],
  paymentMethod: [],
  invoiceBook: [],
  refundBook: [],
  advanceBook: [],
  debtBook: [],
  todayDoctors: undefined,
  customerTarget: [],
  listSample: [],
  sourceClient: [],
  unit: [],
  routeOfUse: [],
  usage: [],
  supplyStorage: [],
  manual: [],
  prescriptionForm: undefined,
  transferRate: [],
};

const SharedDataSlice = createSlice({
  name: 'sharedData',
  initialState: initState,
  reducers: {
    doFetchPersonnel: () => {},
    doFetchServiceTypes: () => {},
    fetchPersonnelSuccess: (state, action) => {
      state.personnel = action.payload.result;
    },
    doFetchPaymentMethod: () => {},
    fetchPaymentMethodSuccess: (state, action) => {
      state.paymentMethod = action.payload.result;
    },
    doFetchBook: () => {},
    fetchBookSuccess: (state, action) => {
      const { data, type } = action.payload
      if (!type) state.debtBook = data;
      if (type === 'SO_THU_TIEN') state.invoiceBook = data;
      if (type === 'SO_TAM_UNG') state.advanceBook = data;
      if (type === 'SO_HOAN_TIEN') state.refundBook = data;
    },
    setTodayDoctors: (state, action) => {
      state.todayDoctors = action.payload.result;
    },
    doFetchCustomerTarget: () => {},
    fetchTargetSuccess: (state, action) => {
      state.customerTarget = action.payload.result;
    },
    doFetchListSample: () => {},
    fetchListSampleSuccess: (state, action) => {
      state.listSample = action.payload.result;
    },
    doFetchSourceClient: () => {},
    fetchSourceClientSuccess: (state, action) => {
      state.sourceClient = action.payload.result;
    },
    doFetchUnit: () => {},
    fetchUnitSuccess: (state, action) => {
      state.unit = action.payload.result;
    },
    doFetchRouteOfUse: () => {},
    fetchRouteOfUseSuccess: (state, action) => {
      state.routeOfUse = action.payload.result;
    },
    doFetchUsage: () => {},
    fetchUsageSuccess: (state, action) => {
      state.usage = action.payload.result;
    },
    doFetchSupplyStorage: () => {},
    fetchSupplyStorageSuccess: (state, action) => {
      state.supplyStorage = action.payload.result;
    },
    doFetchManual: () => {},
    fetchManualSuccess: (state, action) => {
      state.manual = action.payload.result;
    },
    doFetchPrescriptionForm: () => {},
    fetchPrescriptionFormSuccess: (state, action) => {
      state.prescriptionForm = action.payload;
    },
    doFetchTransferRate: () => {},
    fetchTransferRateSuccess: (state, action) => {
      state.transferRate = action.payload.result;
    },
  }
});

export const {
  doFetchPersonnel, fetchPersonnelSuccess,
  doFetchPaymentMethod, fetchPaymentMethodSuccess,
  doFetchBook, fetchBookSuccess,
  setTodayDoctors,
  doFetchCustomerTarget, fetchTargetSuccess,
  doFetchServiceTypes,
  doFetchListSample, fetchListSampleSuccess,
  doFetchSourceClient, fetchSourceClientSuccess,
  doFetchUnit, fetchUnitSuccess,
  doFetchRouteOfUse, fetchRouteOfUseSuccess,
  doFetchUsage, fetchUsageSuccess,
  doFetchSupplyStorage, fetchSupplyStorageSuccess,
  doFetchManual, fetchManualSuccess,
  doFetchPrescriptionForm, fetchPrescriptionFormSuccess,
  doFetchTransferRate, fetchTransferRateSuccess,
} = SharedDataSlice.actions;

export const getSharedData = (state) => state.listData.sharedData;

export default SharedDataSlice.reducer;