import { all, fork } from "@redux-saga/core/effects";
import { watchDoAuth, watchUpdateConfig, watchUpdateHospital } from "./authSagas";
import { watchDoLoadInfoPayment } from "./khamBenhSagas";
import watchPacketService from "./GoiDichVu";
import { watchDoReceipt } from "./TiepDon/TiepDon.saga";
import watchHospitalFeeManagement from "./QlyVienPhi";
import rootListData from "./ListData";

export default function* rootSaga() {
  yield all([
    fork(watchDoAuth),
    fork(watchUpdateHospital),
    fork(watchUpdateConfig),
    fork(watchDoLoadInfoPayment),
    fork(watchPacketService),
    fork(watchDoReceipt),
    fork(watchHospitalFeeManagement),
    fork(rootListData),
  ]);
}
