import { Input, Form } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import i18n, { languageKeys } from "../../i18n";
import moment from "moment";
import { HLog } from "../../helpers";
import { useForm } from "antd/lib/form/Form";
import styles from "./style.module.less"

const HSDInput = forwardRef(
  (
    {
      
      name,
      label,
      parentName,
      placeholder = "DD/MM/YYYY",
      readOnly = false,
      required = false,
      validateFutureDay = false,
      validateOldDay=false,
      onChangeInput = () => {},
      onSetError = () => {},
      onClearError = () => {},
      styleInput = {},
      disabled = false,
      value = "",
      ...props
    },
    ref
  ) => {
    const [oldValue, setOldValue] = useState();
    const [form] = useForm()
    useImperativeHandle(ref, () => ({
      reset() {
        setOldValue();
      },
    }));

    useEffect(() => {
        if (value !== "") {
            form.setFields([{ name: name, value: value }]);
        }
    },[])

    const onChange = (event) => {
      let { value } = event.target;
      HLog("DateInput onChange value: ", value)
      if (value >= oldValue && (value.length === 2 || value.length === 5)) {
        value = value + "/";
      }

      setOldValue(value);
      if (!!parentName) {
        let parrentArr = form.getFieldsValue([parentName])[parentName];
        parrentArr[name[0]] = { ...parrentArr[name[0]], [name[1]]: value };
        form.setFields([{ name: parentName, value: parrentArr }]);
       
       
        onChangeInput(value);
      } else {
        form.setFields([{ name: name, value: value, errors: [] }]);
        onChangeInput(value);
      }
    };

    return (
        <Form form={form} className={styles.formInput} layout="vertical">
            
            <Form.Item
            className={styles.formInput}
        {...props}
        name={name}
        label={label}
        style={{marginBlock : 0}}
        rules={[
          {
            pattern:
              "^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4})$",
            message: i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang),
          },
          {
            required: required,
            message: i18n.t(languageKeys.vui_long_nhap),
          },
          {
            validator: async (_, value) => {
              if (
                validateFutureDay &&
                !!value &&
                value.length === 10 &&
                value[2] === "/" &&
                value[5] === "/"
              ) {
                
                const formatVal = moment(value, "DD/MM/YYYY");
                const isFutureDay = formatVal.isAfter(moment(), "days");
                if (isFutureDay) {
                  return Promise.reject(
                    new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le))
                  );
                }

              }else if(
                validateOldDay &&
                !!value &&
                value.length === 10 &&
                value[2] === "/" &&
                value[5] === "/"
              ){
                
                let isOldDay = moment(value,"DD/MM/YYYY").isBefore(moment(),"days");
                  if(isOldDay){
                    return Promise.reject(
                      new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le))
                    );
                  }
              }
            },
          },
        ]}
      >
        <Input
          onChange={onChange}
          maxLength={10}
          placeholder={placeholder}
          readOnly={readOnly}
          style = {{...styleInput}}
          disabled = {disabled}
          value={value}
        />
      </Form.Item>
        </Form>
    );
  }
);

export default HSDInput;
