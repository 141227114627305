import apiServices from "config/apiServices";
import { apis, keys } from "../../constants";
import { common_post } from "helpers";

export const handleGetToken = async () => {
    try {
      const tokenUrl = apis.refreshTokenChatAi;
      const data = new FormData();

      data.append("scope", apiServices.vin_scope);
      data.append("grant_type", apiServices.vin_grant_type);
      data.append("client_id", apiServices.vin_client_id);
      data.append("client_secret", apiServices.vin_client_secret);
      const headers = {
        "Content-Type": "multipart/form-data",
      };

      let res = await common_post(tokenUrl);

      if(res && res.status === 'OK'){
        let { result } = res;
        localStorage.setItem(keys.ai_access_token, JSON.stringify(result));
        return result.access_token;
      }
    } catch (error) {
      console.log("error::: ", error);
    }
  };