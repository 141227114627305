import { Tooltip } from "antd";
import i18n, {languageKeys} from '../../i18n'
export const CheckRoleTooltip = ({children, oriTitle, isAllowed, hasTooltip, ...props}) => {
    if(hasTooltip)
      return (
        <Tooltip title={isAllowed ? oriTitle : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay) } {...props}>
          {children}
        </Tooltip>
      )
    else if(!isAllowed) {
      return (
      <Tooltip title={i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)} {...props}>
        {children}
      </Tooltip>
      )
    }
    else return children;
}