import { Input, Form } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
import i18n, { languageKeys } from "../../i18n";
import moment from "moment";
import { HLog } from "../../helpers";

const DateInput = forwardRef(
  (
    {
      form,
      name,
      field,
      label,
      parentName,
      placeholder = "DD/MM/YYYY",
      readOnly = false,
      required = false,
      validateFutureDay = false,
      validateOldDay = false,
      onChangeInput = () => { },
      onSetError = () => { },
      onClearError = () => { },
      styleInput = {},
      disabled = false,
      suffix,
      additionalRules = [],
      ...props
    },
    ref
  ) => {
    const [oldValue, setOldValue] = useState();
    useImperativeHandle(ref, () => ({
      reset() {
        setOldValue();
      },
    }));

    const onChange = (event) => {
      let { value } = event.target;
      HLog("DateInput onChange value: ", value)
      if (value >= oldValue) {
        if ((value.length === 2 || value.length === 5))
          value = value + "/";
        if (value.length === 3 && value[2] !== '/')
          value = value.substring(0, 2) + '/' + value.substring(2);
        if ((value.length === 6 && value[5] !== '/'))
          value = value.substring(0, 5) + '/' + value.substring(5);
      }

      setOldValue(value);
      if (!!parentName) {
        let parrentArr = form.getFieldsValue([parentName])[parentName];
        parrentArr[name[0]] = { ...parrentArr[name[0]], [name[1]]: value };
        form.setFields([{ name: parentName, value: parrentArr }]);
        onChangeInput(value);
      } else {
        form.setFields([{ name: field ? field : name, value: value, errors: [] }]);
        onChangeInput(value);
      }
    };

    return (
      <Form.Item
        {...props}
        name={name}
        label={label}
        rules={[
          ...additionalRules,
          {
            pattern:
              "^([0]?[1-9]|[1|2][0-9]|[3][0|1])[./-]([0]?[1-9]|[1][0-2])[./-]([0-9]{4})$",
            message: i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang),
          },
          {
            required: required,
            message: i18n.t(languageKeys.vui_long_nhap),
          },
          {
            validator: async (_, value) => {
              if (
                validateFutureDay &&
                !!value &&
                value.length === 10 &&
                value[2] === "/" &&
                value[5] === "/"
              ) {
                const formatVal = moment(value, "DD/MM/YYYY");
                const isFutureDay = formatVal.isAfter(moment(), "days");

                if (isFutureDay) {
                  return Promise.reject(
                    new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le))
                  );
                }

              } else if (
                validateOldDay &&
                !!value &&
                value.length === 10 &&
                value[2] === "/" &&
                value[5] === "/"
              ) {
                let isOldDay = moment(value, "DD/MM/YYYY").isBefore(moment(), "days");
                if (isOldDay) {
                  return Promise.reject(
                    new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le))
                  );
                }
              }
              const isValidDay = moment(value, "DD/MM/YYYY").isValid();
              if (!isValidDay) {
                return Promise.reject(
                  new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le))
                );
              }
            },
          },
        ]}
      >
        <Input
          onChange={onChange}
          maxLength={10}
          placeholder={placeholder}
          readOnly={readOnly}
          style={{ ...styleInput }}
          disabled={disabled}
          suffix={suffix}
        />
      </Form.Item>
    );
  }
);

export default DateInput;
