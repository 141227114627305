import { setSelectLoading, setTableLoading } from "ducks/slices/loadingSlice";
import { put, select, takeLatest } from "redux-saga/effects";
import { hoanThanhSDGoiDv, layDsSdGoiDv, tamNgungSdGoiDv } from "../apiGoiDichVu";
import i18n, { languageKeys } from "../../../../i18n";
import { notification } from "antd";
import { getServicePackageUsage, getServicePackageUsageSuccess, getServicePacketUsageData, lockServicePackageUsage, finishServicePackageUsage, doSettleBundleUsage } from "ducks/slices/GoiDichVu/ThongTinSDGoiDv/serivcePackageUsage.slice";
import { isEmpty } from "lodash";
import { common_post } from "helpers";
import { apis } from "../../../../constants";

export function* watchServicePackageUsage() {
    yield takeLatest(getServicePackageUsage, handleGetGetServicePackageUsage)
    yield takeLatest(lockServicePackageUsage, handleLockGetServicePackageUsage)
    yield takeLatest(finishServicePackageUsage, handleFinishServicePackageUsage)
    yield takeLatest(doSettleBundleUsage, handleSettleBundleUsage)
}

function* handleGetGetServicePackageUsage(action) {
    const { filters = {}, limit = 15, search_string = '', page, isSearch = false } = action?.payload;
    const { filters: storedFilters } = yield select(getServicePacketUsageData);
    const userProfile = yield select(state => state.auth.user);
    const appliedFilters = !isEmpty(filters) ? filters : storedFilters;
    const body = {
        ...appliedFilters,
        BENH_VIEN_ID: userProfile?.BENH_VIEN_ID,
        partner_code: userProfile?.partner_code,
        limit: limit,
        search_string: isSearch ? search_string : storedFilters.search_string ? storedFilters.search_string : '',
        orderBy: "NGAY_KICH_HOAT",
        page
    }
    try {
        yield put(setTableLoading(true));

        const res = yield layDsSdGoiDv(body);
        if (!!res) {
            if (res.status === 'OK') {
                yield put(getServicePackageUsageSuccess({ ...res, limit, filters: { ...appliedFilters, search_string: isSearch ? search_string : storedFilters.search_string ? storedFilters.search_string : '' } }));
            } else {
                notification.error({
                    message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
                    placement: 'bottomLeft'
                });
            }
        } else {
            notification.error({
                message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
                placement: 'bottomLeft'
            });
        }
    } catch (err) {
        notification.error({
            message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
            placement: 'bottomLeft'
        });
    } finally {
        yield put(setTableLoading(false))
    }

}

function* handleLockGetServicePackageUsage(action) {
    const { arr_id, is_Lock, NGAY_KICH_HOAT, NGAY_KET_THUC, callback = () => {} } = action.payload;
    try {
        yield put(setTableLoading(false));
        const userProfile = yield select(state => state.auth.user);
        const res = yield tamNgungSdGoiDv({
            arr_ID: arr_id,
            isKhoa: is_Lock,
            NGAY_KICH_HOAT,
            NGAY_KET_THUC,
            BENH_VIEN_ID: userProfile?.BENH_VIEN_ID,
            partner_code: userProfile?.partner_code,
        })
        if (!!res) {
            if (res.status === 'OK') {
                notification.success({
                    message: i18n.t(languageKeys.cap_nhat_thanh_cong),
                    placement: 'bottomLeft'
                });
                const { currentPage } = yield select(getServicePacketUsageData)
                yield put(getServicePackageUsage({
                    page: is_Lock ? currentPage : 1
                }))
                yield callback();
            } else {
                notification.error({
                    message: i18n.t(languageKeys.cap_nhat_that_bai),
                    placement: 'bottomLeft'
                });
            }
        } else {
            notification.error({
                message: i18n.t(languageKeys.cap_nhat_that_bai),
                placement: 'bottomLeft'
            });
        }
    } catch (err) {
        notification.error({
            message: i18n.t(languageKeys.cap_nhat_that_bai),
            placement: 'bottomLeft'
        });
    }
}

function* handleFinishServicePackageUsage(action) {
    try {
        yield put(setTableLoading(false));
        const userProfile = yield select(state => state.auth.user);
        const res = yield hoanThanhSDGoiDv({
            BENH_VIEN_ID: userProfile?.BENH_VIEN_ID,
            partner_code: userProfile?.partner_code,
            ID: action.payload
        })
        if (!!res) {
            if (res.status === 'OK') {
                notification.success({
                    message: i18n.t(languageKeys.cap_nhat_thanh_cong),
                    placement: 'bottomLeft'
                });
                const { currentPage } = yield select(getServicePacketUsageData)
                yield put(getServicePackageUsage({
                    page: currentPage
                }))
            } else {
                notification.error({
                    message: i18n.t(languageKeys.cap_nhat_that_bai),
                    placement: 'bottomLeft'
                });
            }
        } else {
            notification.error({
                message: i18n.t(languageKeys.cap_nhat_that_bai),
                placement: 'bottomLeft'
            });
        }
    } catch (err) {
        notification.error({
            message: i18n.t(languageKeys.cap_nhat_that_bai),
            placement: 'bottomLeft'
        });
    }
}


function* handleSettleBundleUsage(action) {
    const { data, afterSuccess } = action.payload
    try {
        yield put(setTableLoading(false));
        const res = yield common_post(apis.tatToanGoi, data)
        if (!!res) {
            if (res.status === 'OK') {
                notification.success({
                    message: i18n.t(languageKeys.cap_nhat_thanh_cong),
                    placement: 'bottomLeft'
                });
                const { currentPage } = yield select(getServicePacketUsageData)
                yield put(getServicePackageUsage({
                    page: currentPage
                }))
                afterSuccess()
            } else {
                notification.error({
                    message: i18n.t(languageKeys.cap_nhat_that_bai),
                    placement: 'bottomLeft'
                });
            }
        } else {
            notification.error({
                message: i18n.t(languageKeys.cap_nhat_that_bai),
                placement: 'bottomLeft'
            });
        }
    } catch (err) {
        notification.error({
            message: i18n.t(languageKeys.cap_nhat_that_bai),
            placement: 'bottomLeft'
        });
    }
}