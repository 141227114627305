import { createSlice } from "@reduxjs/toolkit";

const initState = {
    title: "",
    accountManagmentVisible:false,
    totalResult:0,
};

const titlePage = createSlice({
  name: "titlePage",
  initialState: initState,
  reducers: {
    updateTitle:(state, action)=> {
        state.title = action.payload
    },
    updateTotal : (state, action) => {
        state.totalResult  = action.payload
    },
    resetTitle : (state) => {
        state.title = ""
        state.totalResult = 0

    },
    onVisibleAccManagment : (state,action) => {
      state.accountManagmentVisible = action.payload;
    }
  },
});

export const { updateTitle,updateTotal,resetTitle,onVisibleAccManagment } = titlePage.actions;

export default titlePage.reducer;
