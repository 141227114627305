import moment from "moment";
import React from "react";
import { layDiaChi, getSexFromString, rid } from "../../../helpers";
import { useSelector } from "react-redux";
import i18n, { languageKeys, languages } from "../../../i18n";
import { enDateFormat } from "constants/keys";
import Images from "components/Images/Images";

export const styleGiayChuyenVien = `
#giay-chuyen-vien * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }

#giay-chuyen-vien .column {
  float: left;
  width: 33.33%;
}

#giay-chuyen-vien .row:after {
  content: "";
  display: table;
  clear: both;
}

#giay-chuyen-vien .container-row {
  margin-top: 8px;
}

#giay-chuyen-vien .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#giay-chuyen-vien .header { display: flex; gap: 10px; }
#giay-chuyen-vien .flex {
    display: flex;
  }
#giay-chuyen-vien .flex.justify {
    justify-content: space-around;
  }

#giay-chuyen-vien .text-bold {
    font-size: 12px;
    font-weight: bold !important;
  }

  #giay-chuyen-vien .table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
  }

  #giay-chuyen-vien td, #giay-chuyen-vien th {
    border: 1px solid #000;
    // text-align: center;
    padding: 6px;
    // color: #2c3782;
  }

  #giay-chuyen-vien .table th:first-child {
    border: 1px solid #000;
    text-align: left;
    padding: 6px;
  }

#giay-chuyen-vien .tel {
    margin-right: 50px;
  }
#giay-chuyen-vien .txt-700 {
    font-weight: 700;
  }
#giay-chuyen-vien .container-title {
  text-transform: uppercase; font-size: 20px; font-weight: bold; text-align: center; width: 90%; margin: 1rem auto 1rem auto;
  }
#giay-chuyen-vien .container__title--main {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
#giay-chuyen-vien .container__ttbn--wrapper {
  margin-top: 6px;
}
#giay-chuyen-vien .container__ttbn--wrapper>div {
    margin: 4px 0 0 0;
  }
#giay-chuyen-vien .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  #giay-chuyen-vien .container-wrapper-flex .col-3{
    width: 30%;
  }

  #giay-chuyen-vien .container-wrapper-flex .col-2{
    width: 20%;
  }

  #giay-chuyen-vien .container-wrapper-flex .col-5{
    width: 50%;
  }

  #giay-chuyen-vien .thongtin-khambenh {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  #giay-chuyen-vien .thongtin-khambenh .left {
    // width: 65%;
    display: flex;
    flex-direction: column;
  }

  #giay-chuyen-vien .thongtin-khambenh .right {
    width: 35%;
  }

  #giay-chuyen-vien .table-border {
    padding: 6px 0;
    border: 1px solid #000;
  }

  #giay-chuyen-vien .table-item{
    padding-left:12px;
  }

  #giay-chuyen-vien hr.solid {
    border-top: 1px solid #000;
  }

  #giay-chuyen-vien .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }

  #giay-chuyen-vien .footer .footer-confirm {
    margin-right: 44px;
    text-align: center;
  }

  #giay-chuyen-vien .name-space {
    height: 80px;
  }

  #giay-chuyen-vien .txt-size-m{
    font-size: 12px;
  }

  #giay-chuyen-vien .upperTxt{
    text-transform: uppercase;
  }

  #giay-chuyen-vien .phone-website { 
    display: flex; gap: 24px; align-items: center 
  }

@media print {
#giay-chuyen-vien .foot-break { break-inside: avoid; }
}

#giay-chuyen-vien .table { margin-block: 10px; }
#giay-chuyen-vien .table * { font-size: 12px; }
#giay-chuyen-vien .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#giay-chuyen-vien .table th, .table td { padding: 5px 10px; }
#giay-chuyen-vien .table th { text-align: start; }
`;

const GiayChuyenVien = ({ data }) => {

  const userProfile = useSelector((state) => state.auth.user);

  const benh_nhan = data.patient[0]
  const thong_tin_kham = data.treatmentInfo[0] || {}
  const icd = data.icd[0] || {}
  const don_thuoc = data.prescriptions || []
  const tt_giay = data.paperReferrals[0] || {}

  const ngay_tao = tt_giay.NGAY_TAO ? moment(tt_giay.NGAY_TAO, 'YYYYMMDD').format('DD/MM/YYYY').split("/") : []

  return (
    <div id="giay-chuyen-vien">
      <div className="header">
      {!!userProfile.benh_vien.ANH_DAI_DIEN && (
          <div className="img-header">
            <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>
          </div>
        )}
        <div className="content-header">
          <h2 style={{ margin: 0, fontSize: 10 }} className="upperTxt">
            {userProfile?.benh_vien?.TEN_CSKCB}
          </h2>
          <div>
            <span className="txt-700">{i18n.t(languageKeys.dia_chi)}:</span> {layDiaChi(userProfile.benh_vien)}
          </div>
          <div className="flex">
            <div className="tel">
              <span className="txt-700"> {i18n.t(languageKeys.dien_thoai)}: </span> {convertString(userProfile.benh_vien.SO_DIEN_THOAI)}
            </div>
            <div>
              <span className="txt-700">Website:</span> {convertString(userProfile.benh_vien.WEBSITE)}
            </div>
          </div>
          <div>
            <span className="txt-700">Email:</span> {convertString(userProfile.benh_vien.EMAIL)}
          </div>
        </div>
      </div>

      <div className="container">

        <div className="container-title">
          {i18n.t(languageKeys.giay_chuyen_vien)}
        </div>
        <div className="container-wrapper-flex" style={{ justifyContent: 'center', marginBottom: '1rem' }}>
          <i className="col-5">{i18n.t(languageKeys.kinh_gui)}:</i>
        </div>

        <div className="container__chapter--1">
          <div className="container__title--main">{i18n.t(languageKeys.pk_tran_trong_gt)}</div>

          <div className="container__ttbn--wrapper">
            <div className="container-wrapper-flex">
              <div className="col-5">
                {i18n.t(languageKeys.ten_benh_nhan)}:{" "}
                <span className="txt-700 txt-size-m"> {convertString(benh_nhan.TEN)?.toUpperCase()} </span>
              </div>
              <div className="col-3">
                {benh_nhan.NGAY_SINH.trim().length > 4 ? i18n.t(languageKeys.field_Ngay_sinh) : i18n.t(languageKeys.field_Nam_sinh)}:{" "}
                {benh_nhan.NGAY_SINH}
              </div>
              <div className="col-2">
                {i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(benh_nhan.GIOI_TINH)}
              </div>
            </div>
          </div>
          <div>
            {i18n.t(languageKeys.dia_chi)}: {layDiaChi(benh_nhan)}
          </div>
          <div>
            {i18n.t(languageKeys.da_dc_dieu_tri_kh_benh_tai)}: {userProfile?.benh_vien?.TEN_CSKCB}
          </div>
          <div>
            {i18n.t(languageKeys.common_tu_ngay)}: {moment(thong_tin_kham.NGAY_TIEP_DON, 'YYYYMMDD').format('DD/MM/YYYY')} {i18n.t(languageKeys.common_den)} {moment().format('DD/MM/YYYY')}
          </div>

        </div>

        <div className="container__title--main" style={{ margin: "10px 0", textAlign: 'center' }}>
          {i18n.t(languageKeys.tom_tat_benh_an).toUpperCase()}
        </div>

        <div>
          {i18n.t(languageKeys.dau_hieu_lam_sang)}: {tt_giay.DAU_HIEU_LAM_SANG}
        </div>
        <div>
          {i18n.t(languageKeys.cac_xet_nghiem)}: {data.services?.flatMap(i => i.TEN_DICHVU).join(", ")}
        </div>
        <div>
          {i18n.t(languageKeys.chan_doan)}: {tt_giay.CHAN_DOAN}
        </div>
        <div>
          {i18n.t(languageKeys.thuoc_da_dung)}:
        </div>
        {don_thuoc?.[0] && (
          <table style={{ width: "100%" }} className="table">
            <thead>
              <tr>
                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.stt)}</th>
                <th style={{ textAlign: 'center', width: "35%" }}>{i18n.t(languageKeys.field_Ten_thuoc)}</th>
                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.dvt)}</th>
                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.sl)}</th>
                <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.ngay_dung)}</th>
                <th style={{ textAlign: 'center', width: "30%" }}>{i18n.t(languageKeys.chi_dan_dung_thuoc)}</th>
              </tr>
            </thead>
            <tbody>
              {don_thuoc.map((item, index) => {
                return (
                  <tr key={rid()}>
                    <td style={{ textAlign: 'center' }}>{index + 1}</td>
                    <td style={{ textAlign: "start", width: "35%" }}>{item.TEN_THUOC}</td>
                    <td>{item.DON_VI_TINH}</td>
                    <td style={{ textAlign: 'center' }}>{item.SO_LUONG}</td>
                    <td style={{ textAlign: 'center' }}>{item.SO_NGAY_SU_DUNG}</td>
                    <td style={{ textAlign: "start", width: "20%", maxWidth: "25%" }}>{item.HUONG_DAN_SU_DUNG}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
        <div>
          {i18n.t(languageKeys.tinh_trang_luc_chuyen_vien)}: {tt_giay.TINH_TRANG_NGUOI_BENH}
        </div>
        <div>
          {i18n.t(languageKeys.ly_do_chuyen_vien)}: {tt_giay.LY_DO_CHUYEN_VIEN}
        </div>
        <div>
          {i18n.t(languageKeys.chuyen_hoi)}:
          &nbsp;
          {
            tt_giay.GIO_TAO
              ? i18n.language === languages.tieng_viet
                ? moment(tt_giay.GIO_TAO, 'HH:mm').format('HH giờ mm ') + ' phút'
                : tt_giay.GIO_TAO
              : i18n.language === languages.tieng_viet
                ? '…………giờ …………phút'
                : ''
          }
          {
            tt_giay.GIO_TAO && tt_giay.NGAY_TAO
              ? <>,&nbsp;</>
              : <>&nbsp;</>
          }
          {
            tt_giay.NGAY_TAO
              ? i18n.language === languages.tieng_viet
                ? `Ngày ${ngay_tao[0]} tháng ${ngay_tao[1]} năm ${ngay_tao[2]}`
                : moment(tt_giay.NGAY_TAO, 'YYYYMMDD').format("MMMM d, YYYY")
              : i18n.language === languages.tieng_viet
                ? 'Ngày ............. tháng .............. năm ....................'
                : ''
          }
        </div>
        <div>
          {i18n.t(languageKeys.phuong_tien_van_chuyen)}: {tt_giay.PHUONG_TIEN_CHUYEN_VIEN}
        </div>
        <div>
          {i18n.t(languageKeys.ho_ten_ng_dua_di)}: {tt_giay.HO_TEN_NGUOI_DUA_DI}
        </div>
      </div>



      <div className="footer foot-break">

        <div style={{ marginTop: 16, textAlign: 'center', marginLeft: 44 }}>
          <div className="txt-700">
            {i18n.t(languageKeys.bac_si_dieu_tri)}
          </div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          <div className="txt-700">{data.tu_van_vien}</div>
        </div>

        <div className="footer-confirm">
          {i18n.language === languages.tieng_viet ? (
            <i>
              {moment().format("HH:mm") + ", "}
              Ngày {moment().date()},tháng {moment().month() + 1}, năm {moment().year()}
            </i>
          ) : (
            <i> {moment().format(enDateFormat)}</i>
          )}
          <div className="txt-700">
            {i18n.t(languageKeys.giam_doc_pk)}
          </div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          <div className="txt-700">{data.tu_van_vien}</div>
        </div>

      </div>
    </div>
  );
};

let convertString = (field) => {
  let text = "";

  if (!!field && typeof field === "string") {
    text = field;
  } else if (!!field && typeof field === "number") {
    text = `${field}`;
  }
  return text;
};

export default GiayChuyenVien;
