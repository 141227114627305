import { FileExcelOutlined, InboxOutlined } from "@ant-design/icons";
import { notification, Row, Space, Spin, Upload } from "antd";
import { useState } from "react";
import { readExcel } from "../../helpers";
import i18n, { languageKeys } from "../../i18n";
import ConfirmModal from "../Modal/ConfirmModal";
import importCsvModal from "./importCsvModal.module.less";

const ImportCsvModal = ({
  modalRef, //  ref dành cho ConfirmModal
  onOk = () => {}, // Hàm xử lý đẩy data lên server
}) => {
  // const [importData, setImportData] = useState([]); // [{...}, {...}, ...] // array of objects
  // const [fileName, setFileName] = useState([]); // ["", "", ...]
  const [loading, setLoading] = useState(false);
  const [importListData, setImportListData] = useState([]); // [[{...}, {...}, ...], ...] - array of arrays of objects
  const [fileNames, setFileNames] = useState([]);

  const onCancel = () => {
    // setImportData([]);
    // setFileName();
    setImportListData([]);
    setFileNames([]);
  };

  const beforeUpload = (file) => {
    const fileType = file.type;

    if (
      fileType !==
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      fileType !== "application/vnd.ms-excel"
    ) {
      notification.error({
        message: i18n.t(languageKeys.noti_Chi_duoc_upload_excel),
        placement: "bottomLeft",
      });
    } else {
      setLoading(true);

      readExcel(file, (data) => {
        // setImportData(data);
        // setFileName(file.name);
        setImportListData((list) => [...list, data]);
        setFileNames((list) => [...list, file.name]);
        setLoading(false);
      });
    }

    return false;
  };

  // console.log(fileNames, importListData);

  return (
    <>
      <ConfirmModal
        ref={modalRef}
        width={500}
        title={i18n.t(languageKeys.common_Nhap_csv)}
        content=""
        onOk={() => {
          modalRef.current.loading(true);
          // onOk(importData);
          onOk(importListData[0]);
          setImportListData([]);
          setFileNames([]);
        }}
        onCancel={onCancel}
        okDisabled={importListData.length === 0}
        // clickOutsideDisabled
      >
        <div className={importCsvModal["container"]}>
          <Spin spinning={loading}>
            <Upload.Dragger
              multiple={false}
              name="file"
              showUploadList={false}
              disabled={loading}
              beforeUpload={beforeUpload}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>

              <p className="ant-upload-text">
                {i18n.t(languageKeys.noi_dung_keo_tha_upload_file)}
              </p>
            </Upload.Dragger>
          </Spin>

          {fileNames.length > 0 &&
            fileNames.map((name, index) => (
              <Row
                className={importCsvModal["file-name"]}
                justify="space-between"
                align="middle"
                key={index}
              >
                <Space>
                  <FileExcelOutlined />
                  <div>{name}</div>
                </Space>
              </Row>
            ))}
        </div>
      </ConfirmModal>
    </>
  );
};

export default ImportCsvModal;
