import { createSlice } from "@reduxjs/toolkit";
import {isEmptyObject} from "../../helpers";
import { tabKhamBenh } from "constants/keys";

const initState = {
    infoPayment: null,
    showInfo : false,
    message : "",
    thongTinKhamNhanh : null,
    thongTinKhamBenh : null,
    dataDetail : null,
    dataDetailPopup : null,
    tabExams : tabKhamBenh.KHAM_BENH,
    disabledServTab : false,
    groupLoading : {},
    dataRequested : {},
    openFastRis: false,
    dataFastRis : {},
    actionProps : {
        khamBenhTabs : undefined,
    },
    qlKhamBenh:{
        totalMain: 0,
        totalTatCa:0,
        totalChoKham:0,
        totalDangKham:0,
        totalHoanThanh:0
    },
    qlDVCD:{
        totalMain: 0,
        totalTatCa:0,
        totalChoKham:0,
        totalDangKham:0,
        totalHoanThanh:0
    },
    thongTinSelect : null,
    openFastPTTT: false,
    dataFastPTTT : {},
    dataInFastPTTT : null,
    reloadDataPTTT: false,
    LICH_KHAM_ID:null,
    reload:null,
    infoClickIconDsDv:null,
    dsBacSi:[],
    dsBacSiRis:[],
};
//LOAD_THONG_TIN_KHAM_NHAN_CLS
const khamBenhSlice = createSlice({
    name: "khamBenh",
    initialState: initState,
    reducers: {

        setQlKhamBenh: (state, action) => {
            state.qlKhamBenh = action.payload;
        },
        setQlDVCD: (state, action) => {
            state.qlDVCD = action.payload;
        },
        loadThongTinThanhToan(state, action) {},
        loadThongTinThanhToanSuccess(state, action) {
            state.infoPayment = action.payload;
            state.showInfo = true
        },
        resetDataThanhToan(state, action) {
            state.infoPayment = null;
            state.showInfo = false;
        },
        loadThongTinKhamNhanhCLS(state, action){
            let { benh_chinh, benh_phu } = action.payload;
            let data_benh = [];
            if (benh_chinh && !isEmptyObject(benh_chinh)) {
                data_benh = data_benh.concat([benh_chinh])
            }
            if (benh_phu && benh_phu.length > 0) {
                data_benh = data_benh.concat(benh_phu)
            }
            state.thongTinKhamBenh = {
                ...state.thongTinKhamBenh,
                ...action.payload,
                data_benh : data_benh
            }
        },
        resetMessageLoadThongTinKhamNhanh(state, action) {
            state.message = "" ;
            state.thongTinKhamNhanh = null
        },
        saveThongTinKhamBenh(state, action) {
            state.thongTinKhamBenh = {
                ...state.thongTinKhamBenh,
                ...action.payload
            }
        },
        resetThongTinKhamBenh (state, action) {
            state.thongTinKhamBenh = null
        },
        setDataDetail (state, action) {
            state.dataDetail = action.payload
        },
        setDataDetailPopup (state, action) {
            state.dataDetailPopup = action.payload
        },
        setTabExams (state,action) {
            state.tabExams = action.payload;
        },
        setDisabledServTabs(state,action) {
            state.disabledServTab = action.payload;
        },
        setGroupLoading (state, action) {
            state.groupLoading = {
                ...state.groupLoading,
                ...action.payload
            }
        },
        setDataRequested (state, action) {
            state.dataRequested = action.payload;
        },
        setOpenFastRis(state,action) {
            let { payload } = action
            state.dataFastRis = payload;
            state.openFastRis = true;
        },
        setCloseFastRis(state,action) {
            state.openFastRis = false;
            state.dataFastRis = {};
        },
        updateActionProps(state,action) {
            state.actionProps = {...state.actionProps,...action.payload};
        },
        setThongTinSelect(state,action) {
            let { payload } = action
            state.thongTinSelect = payload;
        },
        setOpenFastPTTT(state,action) {
            let { payload } = action
            state.dataFastPTTT = payload;
            state.openFastPTTT = true;
        },
        setCloseFastPTTT(state,action) {
            state.openFastPTTT = false;
            state.dataFastPTTT = {};
        },
        setDataInPTTT(state,action) {
            let { payload } = action
            state.dataInFastPTTT = payload;
        },
        setReloadDataPTTT(state,action) {
            let { payload } = action
            state.reloadDataPTTT = payload;
        },
        setEditDsChiDinhSuccess: (state, action) => {
            const { LICH_KHAM_ID }= action.payload
            state.LICH_KHAM_ID =LICH_KHAM_ID;
            state.reload =new Date();
        },
        resetInfoDsChiDinhSuccess: (state) => {
            state.LICH_KHAM_ID = null;
            state.reload =null;
        },
        setInfoClickIconDsDv: (state, action) => {
            state.infoClickIconDsDv =action.payload
        },
        setDsBacSi: (state, action) => {
            state.dsBacSi = action.payload;
        },
        setDsBacSiRIS: (state, action) => {
            state.dsBacSiRis = action.payload;
        },

    },
});

export const {
    setInfoClickIconDsDv,
    setEditDsChiDinhSuccess,
    resetInfoDsChiDinhSuccess,
    resetDataThanhToan,
    loadThongTinThanhToan,
    loadThongTinThanhToanSuccess,
    loadThongTinKhamNhanhCLS,
    resetMessageLoadThongTinKhamNhanh,
    saveThongTinKhamBenh,
    resetThongTinKhamBenh,
    setDataDetail,
    setDataDetailPopup,
    setCloseFastRis,
    setOpenFastRis, 
    updateActionProps,
    setQlDVCD,
    setQlKhamBenh,
    setThongTinSelect,
    setOpenFastPTTT,
    setCloseFastPTTT,
    setDataInPTTT,
    setReloadDataPTTT,
    setDsBacSi,
    setDsBacSiRIS
} = khamBenhSlice.actions;
export const KhamBenhActions = khamBenhSlice.actions;

export default khamBenhSlice.reducer;
