const { createSlice } = require("@reduxjs/toolkit")

const initState = {
    data: {},
}

const detailPackageUsageSlice = createSlice({
    name: 'detailPackageUsageSlice',
    initialState: initState,
    reducers: {
        getDetailData: () => {},
        getDetailDataSuccess: (state, action) => {
            const { result, VAT_TU_TRONG_GOI = [], VAT_TU_NGOAI_GOI = [] } = action.payload;
            state.data = result.length ? { ...result[0], VAT_TU_TRONG_GOI, VAT_TU_NGOAI_GOI } : {}
        },
        updateDetailData: () => {}
    }
})

export const { getDetailData, getDetailDataSuccess, updateDetailData } = detailPackageUsageSlice.actions;
export const getDetailPackageUsageData = (state) => state.servicePacket.detailPackageUsage;
export default detailPackageUsageSlice.reducer;