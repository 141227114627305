import React from "react";
import { Modal, Form, Row, Col, Button, notification } from "antd";
import { Calendar } from "assets/svg";
import i18n, { languageKeys } from "../../i18n";
import style from "./styles/style.module.less";
import moment from "moment";
import DateInputv2 from "components/DateInput_v2/DateInput_v2";
import { HLog, common_post } from "helpers";
import { apis } from "../../constants";
import { ConfirmModal } from "components";
import { useSelector } from "react-redux";

let initInfoActive = {
  isActive: true,
  NGAY_KICH_HOAT: moment(new Date()).format("DD/MM/YYYY"),
  NGAY_SU_DUNG: 0,
  NGAY_KET_THUC: "",
};
const ModalActiveServicePack = ({ handleAfterSubmit = () => {} }, ref) => {
  const userProfile = useSelector((state) => state.auth.user);
  const dateRef = React.useRef();
  const confirmRef = React.useRef();
  const [formPopup] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedPackage, setSelectedPackage] = React.useState();
  const [infoActive, setInfoActive] = React.useState(initInfoActive);

  React.useImperativeHandle(ref, () => {
    return {
      open: handleOpen,
      close: handleClose,
    };
  });

  const handleOpen = (data) => {
    setIsModalOpen(true);
    setSelectedPackage(data);
    setInfoActive({
      isActive: true,
      NGAY_KICH_HOAT: moment(new Date()).format("DD/MM/YYYY"),
      NGAY_SU_DUNG: data?.NGAY_SU_DUNG,
      NGAY_KET_THUC: moment().add("days", data?.NGAY_SU_DUNG).format("DD/MM/YYYY"),
    });
  };
  const handleClose = ({ reload = false }) => {
    setIsModalOpen(false);
    setLoading(false);
    if (reload) {
      handleAfterSubmit(selectedPackage);
    }
    setSelectedPackage();
    setInfoActive(initInfoActive);
    formPopup.resetFields();
  };

  const commonNotiFail = (message) => {
    notification.error({ message: message ? message : i18n.t(languageKeys.noti_thao_tac_that_bai) });
    return;
  };

  /**
   *
   * @param {*} packagePayload
   * @returns {void}
   */
  const handleCreatePackage = async (packagePayload) => {
    try {
      confirmRef?.current?.loading(true);
      setLoading(true);
      if (!packagePayload) packagePayload = { ...infoActive };

      const keyRemove = ["isActive", "label", "value"];

      keyRemove.forEach((key) => {
        delete packagePayload[key];
      });

      let body = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        partner_code: userProfile.partner_code,
        NGAY_KICH_HOAT: moment(packagePayload.NGAY_KICH_HOAT, "DD/MM/YYYY").format("YYYYMMDD"),
        NGAY_KET_THUC: moment(packagePayload.NGAY_KET_THUC, "DD/MM/YYYY").format("YYYYMMDD"),
        arr_ID: [selectedPackage?.GOI_DV_ID],
        isKhoa: 0,
      };
      let res = await common_post(apis.lock_packet_service_usage, body);
      if (res && res.status === "OK") {
        setTimeout(() => {
          handleClose({ reload: true });
        });
      } else {
        if (res.message) {
          throw new Error(res.message);
        }
        throw new Error(res.error);
      }
    } catch (error) {
      HLog(error);
      commonNotiFail(error.message);
    } finally {
      loading && setLoading(false);
      confirmRef?.current?.loading(false);
    }
  };
  // sau khi mở popup cảnh báo ( nhấn xác nhận )
  const handleCheckDate = (data) => {
    handleCreatePackage({ ...data });
  };

  const handleSubmit = async () => {
    if (moment(infoActive.NGAY_KET_THUC, "YYYYMMDD").isAfter(moment(selectedPackage?.NGAY_HET_HAN, "YYYYMMDD"))) {
      confirmRef.current?.open({ ...infoActive });
      return;
    } else {
      handleCreatePackage();
    }
    // setIsModalOpen(false);
  };

  return (
    <Modal
      className={style.modal}
      //   centered
      width={412}
      title={false}
      footer={false}
      closable={false}
      open={isModalOpen}
      onCancel={handleClose}
    >
      <Form
        form={formPopup}
        onFinish={(values) => {
          HLog("Success:", values);
        }}
        initialValues={{
          NGAY_KICH_HOAT: moment(new Date()).format("DD/MM/YYYY"),
        }}
      >
        <h2 className={style.title}>{i18n.t(languageKeys.kich_hoat_goi_dv)}</h2>
        <div className={style.content}>
          <Row>
            <Col span={10}>
              <p className={style.label}>{i18n.t(languageKeys.ten_goi)}:</p>
            </Col>
            <Col span={14}>
              <p className={style.des}>{selectedPackage?.TEN_GOI_DV}</p>
            </Col>
            <Col style={{ marginTop: 12 }} span={10}>
              <p className={style.label}>{i18n.t(languageKeys.ngay_kich_hoat)}:</p>
            </Col>
            <Col style={{ marginTop: 12 }} span={14}>
              <DateInputv2
                form={formPopup}
                name={"NGAY_KICH_HOAT"}
                placeholder="DD/MM/YYYY"
                suffix={
                  <div className={style.addonAfter}>
                    <Calendar />
                  </div>
                }
                validateOldDay={true}
                onChangeInput={(value) => {
                  formPopup
                    .validateFields()
                    .then(
                      () => {
                        setInfoActive((obj) => {
                          return {
                            ...obj,
                            NGAY_KICH_HOAT: value,
                            NGAY_KET_THUC: moment(value, "DD/MM/YYYY").add("days", obj?.NGAY_SU_DUNG).format("DD/MM/YYYY"),
                          };
                        });
                      },
                      () => {
                        setInfoActive((obj) => {
                          return {
                            ...obj,
                            NGAY_KET_THUC: "",
                          };
                        });
                      }
                    )
                    .catch(() => {});
                }}
                additionalRules={[
                  {
                    validator: async (_, value) => {
                      if (value?.length < 10) return Promise.reject(new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le)));
                    },
                  },
                ]}
                ref={dateRef}
              />
            </Col>
            <Col style={{ marginTop: 12 }} span={10}>
              <p className={style.label}>{i18n.t(languageKeys.so_ngay_sd)}:</p>
            </Col>
            <Col style={{ marginTop: 12 }} span={14}>
              <p className={style.des}>
                {infoActive.NGAY_SU_DUNG} {i18n.t(languageKeys.data_Ngay)}
              </p>
            </Col>
            <Col style={{ marginTop: 12 }} span={10}>
              <p className={style.label}>{i18n.t(languageKeys.field_Ngay_ket_thuc)}:</p>
            </Col>
            <Col style={{ marginTop: 12 }} span={14}>
              <p className={style.des}>{infoActive.NGAY_KET_THUC}</p>
            </Col>
          </Row>
        </div>

        <Row gutter={[8, 8]} style={{ marginTop: 24 }}>
          <Col span={12}>
            <Button type="primary" className={style["button"]} ghost onClick={handleClose}>
              {i18n.t(languageKeys.common_Huy)} (ESC)
            </Button>
          </Col>
          <Col span={12}>
            <Button
              type="primary"
              loading={loading}
              className={style["button"]}
              onClick={() => {
                formPopup
                  .validateFields()
                  .then(() => {
                    handleSubmit();
                  })
                  .catch(() => {});
              }}
            >
              {i18n.t(languageKeys.title_Xac_nhan)}
            </Button>
          </Col>
        </Row>
      </Form>
      <ConfirmModal
        ref={confirmRef}
        content={i18n.t(languageKeys.ngay_kt_lon_hon_ngay_het_han)}
        danger={true}
        onOk={handleCheckDate}
        onCancel={() => {
          setIsModalOpen(true);
          setLoading(false);
        }}
      />
    </Modal>
  );
};

export default React.memo(React.forwardRef(ModalActiveServicePack));
