import { nanoid } from "@reduxjs/toolkit";
import { Button, Col, Input, Row, Select as AntSelect, Spin } from "antd";
import * as _ from "lodash";
import { PlusCircleFilled } from "@ant-design/icons";
import style from "./select.module.less";
import i18n, { languageKeys } from "../../i18n";
import cn from "classnames";
import { HLog } from "../../helpers";

/**
 * Custom Ant Design Select Component
 * @param labelKey is the label key of an object if dataSource is an Array of Objects
 * @param valueKey is the value key of an object if dataSource is an Array of Objects
 * @param tableProps if user want to display dropdown options as table, this prop must be an object with prop visible true
 * @param addItemProps if user want to display button "add item" at the bototm of dropdown, this prop must be an object with prop visible true
 */
export const Select = ({
  dataSource = [],
  labelKey = "",
  valueKey = "",
  optionProps = {},
  showSearch = true,
  onSelect = () => {},
  onDeselect = () => {},
  tableProps = {
    visible: false,
    columns: [],
    width: undefined,
  },
  addItemProps = {
    visible: false,
    onAddItem: () => {},
    textAddItem: i18n.t(languageKeys.common_Them_moi),
  },
  readOnly = false,
  allowClear = true,
  onClear,
  placeholder = i18n.t(languageKeys.common_Chon),
  className,
  customTitle,
  dropdownTopComponent = <></>,
  popupClassName,
  getFullValue = false,
  loading = false,
  onChange = () => {},
  ...props
}) => {
  const isDropdownTable = _.isObject(tableProps) && tableProps.visible;
  const visibleAddItem = _.isObject(addItemProps) && addItemProps.visible;

  const getLabel = (data, isLabelProp = false) => {
    if (!_.isObject(data)) return data;
    if (!labelKey) return JSON.stringify(data);
    if (isDropdownTable && !isLabelProp) {
      return (
        <Row align="middle" wrap={false} gutter={5} className={style["table-body"]}>
          {tableProps.columns.map((col) => (
            <Col key={nanoid()} style={{ width: col?.width, minWidth: col?.width, maxWidth: col?.width }} className={style.colCell}>
              {col?.render
                ? col.render(data[col?.dataIndex], data)
                : data[col?.dataIndex]
              }
            </Col>
          ))}
        </Row>
      );
    }
    return data[labelKey];
  };

  const getValue = (data) => {
    if (!_.isObject(data)) return data;
    return !!valueKey ? data[valueKey] : JSON.stringify(data);
  };

  const handleSelect = (value, optionInstance) => {
    if (!valueKey) return onSelect(value);
    // if(getFullValue) return onSelect(optionInstance.fullValue);
    onSelect(optionInstance.fullValue);
  };

  const handleChange = (value, optionInstance) => {
    if (!valueKey) return onChange(value);
    onChange(optionInstance?.fullValue);
  };

  const options = dataSource.map((data) => (
    <AntSelect.Option key={nanoid()} value={getValue(data)} label={getLabel(data, true)} fullValue={data}>
      {customTitle ? customTitle(data) : getLabel(data)}
    </AntSelect.Option>
  ));

  const dropdownRender = (originNode) => {
    const defaultNode = (
      <Spin spinning={loading}>
        {originNode}

        {visibleAddItem && (
          <div className={style["add-item"]}>
            <Button type="link" icon={<PlusCircleFilled />} onClick={addItemProps?.onAddItem}>
              {addItemProps.textAddItem || "Add Item"}
            </Button>
          </div>
        )}
      </Spin>
    );

    if (!isDropdownTable) {
      return (
        <>
          {dropdownTopComponent}
          {defaultNode}
        </>
      );
    }
    return (
      <>
        {dropdownTopComponent}

        <Row align="middle" className={style["table-head"]} wrap={false} gutter={5}>
          {tableProps.columns.map((col, index) => (
            <Col key={index} style={{ width: col?.width, minWidth: col?.width, maxWidth: col?.width }} className={style.colCell}>
              {col?.title}
            </Col>
          ))}
        </Row>

        {defaultNode}
      </>
    );
  };

  const getTableWidth = () => {
    if (!!tableProps.width) return tableProps.width;
    return tableProps.columns.reduce((sum, col) => (sum += col.width), 0);
  };

  const getDataKey = () => {
    try {
      return dataSource?.find(dt => dt?.[valueKey] == props?.value)?.[labelKey]
    } catch (error) {
      HLog("Error select component v2: ", error)
      return undefined
    }
  }

  if (readOnly) return <Input value={getDataKey() ?? props.value} readOnly />;

  return (
    <AntSelect
      placeholder={placeholder}
      showSearch={showSearch}
      onSelect={handleSelect}
      optionLabelProp={isDropdownTable && "label"}
      dropdownRender={dropdownRender}
      dropdownMatchSelectWidth={isDropdownTable ? getTableWidth() : undefined}
      onDeselect={onDeselect}
      allowClear={allowClear}
      onClear={onClear}
      popupClassName={cn(popupClassName, style["dropdown"])}
      className={cn(className, style["container"])}
      loading={loading}
      onChange={handleChange}
      {...props}
    >
      {options}
    </AntSelect>
  );
};
