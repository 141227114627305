import { loadThongTinThanhToan, loadThongTinThanhToanSuccess } from "../slices/khamBenhSlice";
import { takeLatest, put } from "redux-saga/effects";
import { common_post } from "../../helpers";
import { apis } from "../../constants";
import { updateIndexedDb, updateIndexedDbWithSaga } from "ducks/slices/dataSlice";
import { indexedDbKeys } from "constants/keys";
import Constants from "constants/Constants";
import { apiLayDsDvCls, apiLayDsDvKhamBenh } from "hooks";
import Dexie from "dexie";
import { setIndexedDbLoading } from "ducks/slices/loadingSlice";

export function* watchDoLoadInfoPayment() {
  yield takeLatest(loadThongTinThanhToan, handleGetInfoPayment);
  yield takeLatest(updateIndexedDbWithSaga, handleReloadServices);
}

function* handleGetInfoPayment(action) {
  try {
    const response = yield common_post(apis.khambenh_ds_benh_nhan_kham, action.payload);
    if (response.status === "OK" && response.result.length > 0) {
      let result = response.result[0];
      yield put(loadThongTinThanhToanSuccess(result));
    }
  } catch (e) {}
}

function* handleReloadServices() {
  yield put(setIndexedDbLoading(true))
  try {
    const resKhB = yield apiLayDsDvKhamBenh()
    const resCls = yield apiLayDsDvCls()
    if (resKhB.status === 'OK' && resCls.status === 'OK') {
      const db = new Dexie(Constants.INDEX_DB_NAME);
      db.version(10).stores({
        [indexedDbKeys.nghe_nghiep]: "STT,MA_NGHE_NGHIEP,TEN_NGHE_NGHIEP",
        [indexedDbKeys.kham_benh]: "++id, KHAM_BENH",
        [indexedDbKeys.cls]: "++id, CLS",
        [indexedDbKeys.template_pdf]: "++id, TEMPLATE_PDF",
        [indexedDbKeys.init_done]: "++id, INIT_DONE",
        [indexedDbKeys.phong]: "++id, PHONG",
        fetch_date: `++id, date`,
      });
      db.kham_benh.update(1, { KHAM_BENH: resKhB.result })
        .then(() => {
          put(updateIndexedDb({ [indexedDbKeys.kham_benh]: resKhB.result }))
        })
        .catch(error => {
          console.error('Error clearing table:', error);
        });
      db.cls.update(1, { CLS: resCls.result })
        .then(() => {
          put(updateIndexedDb({ [indexedDbKeys.cls]: resCls.result }))
        })
        .catch(error => {
          console.error('Error clearing table:', error);
        });
    }
  } catch (err) {
    console.log(err)
  } finally {
    yield put(setIndexedDbLoading(false))
  }
}