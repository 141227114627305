import { createSlice } from '@reduxjs/toolkit';

const initState = {
    data: [],
};

const ExemptionSlice = createSlice({
    name: 'Exemption',
    initialState: initState,
    reducers: {
        getExemption: () => { },
        getExemptionSuccess: (state, action) => {
            state.data = action.payload;
        },
    }
});

export const { getExemption, getExemptionSuccess } = ExemptionSlice.actions;
export const getExemptionData = (state) => state.listData.exemption.data;

export default ExemptionSlice.reducer;