import Icon from "@ant-design/icons";
import { nanoid } from "@reduxjs/toolkit";
import { Col, Popover, Row, notification, Tag } from "antd";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SSE } from "sse.js";
import { Chatbot as ChatbotSvg } from "../../assets/svg";
import InputChat from "./InputChat";
import initScriptChat from "./models/init_script_chat";
import { InfoCircleFilled } from "@ant-design/icons";

import i18n, { languageKeys } from "../../i18n";
import keys, { chatKeys } from "constants/keys";
import style from "./styles/style.module.less";
import { apis } from "../../constants";
import { handleGetToken } from "features/ChatAi/apiInstances";
import { useSelector } from "react-redux";
EventSource = SSE;
var _source = null;

class ChatItem {
  constructor(content, user) {
    this.content = content;
    this.user = user;
    this.id = nanoid() + Date.now();
  }

  getObject() {
    return {
      content: this.content,
      user: this.user,
      id: this.id,
    };
  }
}

const Chatbot = ({ floatBtn = true, styleWrapper = {}, from }) => {
  const { useAi } = useSelector(state => state.auth)
  const bpRef = useRef();

  const [visibleChat, setVisibleChat] = useState(false);
  const [contentText, setContentText] = useState([]);

  const [loadingMessage, setLoadingMessage] = useState(false);

  React.useEffect(() => {
    let token = localStorage.getItem(keys.ai_access_token);
    !token && handleGetToken();
  }, []);

  const scriptChat = (() => {
    let res = [...initScriptChat];
    if (from === "RIS") {
      res[1].content = "<p>Tôi là trợ lý hỏi đáp thông tin chẩn đoán hình ảnh được phát triển trên công nghệ trí tuệ nhân tạo.</p>";
    } else if (from === "DUOC") {
      res[1].content = "<p>Tôi là trợ lý hỏi đáp thông tin dược phẩm được phát triển trên công nghệ trí tuệ nhân tạo.</p>";
    } else {
      res[1].content = "<p>Tôi là trợ lý hỏi đáp thông tin y tế được phát triển trên công nghệ trí tuệ nhân tạo.</p>";
    }
    return res;
  })();

  const next = React.useCallback(
    (time) => {
      if (time < scriptChat.length) {
        setLoadingMessage(true);
      } else {
        setLoadingMessage(false);
      }
      setContentText((prev) => [...prev, scriptChat[time - 1]].filter((c) => !!c));
    },
    [setVisibleChat?.toString()]
  );

  usePushData({
    autoInterval: scriptChat.length,
    next,
  });
  const handleCloseEvent = () => {
    try {
      if (_source && typeof _source.close === "function") {
        _source.close();
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   *
   * @param {*} data
   * @returns { {access_token: string, expires_in:number} }
   */
  const convertJSON = (data) => {
    try {
      let rs = JSON.parse(data);
      return rs;
    } catch (error) {
      return data;
    }
  };

  async function prepareAsking(message) {
    try {
      let tokenLocal = localStorage.getItem(keys.ai_access_token);
      let newToken;
      let parseToken = convertJSON(tokenLocal);

      if (Date.now() > parseToken.expires_in) {
        newToken = await handleGetToken();
      } else {
        newToken = parseToken.access_token;
      }

      return { message, newToken };
    } catch (error) {
      console.log(error);
    }
  }

  const customMessage = (string) => {
    try {
      return string;
    } catch (error) {
      console.log(error);
      return string
    }
  }

  const handleAsking = async (message, token) => {
    try {
      setLoadingMessage(true);
      const url = apis.askingChatAi;
      if (!message) return;

      _source = new SSE(url, {
        method: "POST",
        payload: JSON.stringify({ ask: customMessage(message), previousMessages:[
          {message: `trả lời bằng ngôn ngữ html `},
          {message: `tô đậm các chuyên khoa nếu có `},
        ] }),
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        start: false,
      });
      let string = "";
      let error;
      if (_source && _source.onerror) {
        _source.onerror = (event) => {
          error = event;
        };
      }
      _source.onmessage = (event) => {
        let payload = JSON.parse(event.data);
        if (payload?.content) string += payload?.content;
      };

      _source.addEventListener("readystatechange", (e) => {
        if (+e?.readyState === 2) {
          string && addString(string);
          setLoadingMessage(false);
          handleCloseEvent();
        }
      });

      _source.stream();
    } catch (error) {
      setLoadingMessage(false);
      console.log(error);
      addString("Xin lỗi! Tôi đang không được khỏe, bạn vui lòng thử lại sau ít phút nhé!");
    }
  };

  const addString = (mess) => {
    setContentText((prev) => {
      let newArr = [];
      if (Array.isArray(prev)) {
        let newArr = [...prev];
        let newMessage = new ChatItem(mess, chatKeys.ai);
        return [...newArr, newMessage.getObject()];
      } else {
        return newArr;
      }
    });
  };

  const handleScrollToView = () => {
    if (bpRef.current) {
      bpRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }
  };

  const JSONContent = useMemo(() => {
    try {
      return JSON.stringify(contentText);
    } catch (error) {
      return contentText;
    }
  }, [contentText]);

  useEffect(() => {
    handleScrollToView();
  }, [JSONContent]);

  const messageFrame = (text, from, initArr, index) => {
    const handleStringData = (stringData) => {
      try {
        let result = stringData;
        // let list_ldv = loaiDichVu.map((i) => i.MA_LOAI);
        result = stringData.split('\n')?.filter(i => !!i)?.join('<div style={{marginBlock:2}} />');
        // result = result.replaceAll('<br/>','');
        return result;
      } catch (error) {
        console.log(error);
        return "";
      }
    }
    return (
      <Row key={nanoid()} justify={from === chatKeys.ai ? "left" : "end"} gutter={10}>
        <Col style={{ minWidth: 34 }}>
          <Icon
            hidden={from === chatKeys.user || (index !== 0 && initArr[index - 1]?.user !== chatKeys.user)}
            component={ChatbotSvg}
            style={{ fontSize: 24, marginTop: 10 }}
          />
        </Col>
        <Col
          className={style.messageContainer}
          style={{ background: from === chatKeys.user && "#0957DE", color: from === chatKeys.ai ? "#000" : "#fff" }}
        >
           <p dangerouslySetInnerHTML={{__html:handleStringData(text)}}/>
        </Col>
      </Row>
    );
  };

  const callbackSubmit = async (string) => {
    if (!string) return;
    let item = new ChatItem(string.trim(), chatKeys.user);
    let askingString = "";

    prepareAsking(string)
      .then(({ message, newToken }) => handleAsking(message, newToken))
      .catch((err) => notification.error({ message: err?.message }));

    setContentText((prev) => [...prev, item.getObject()]);
  };

  const handleSubmit = useCallback(callbackSubmit, [setContentText]);

  return (
    <React.Fragment>
      <Popover
        open={!!useAi && visibleChat}
        placement={window.innerHeight < 540 ? "leftBottom" : "topLeft"}
        trigger="click"
        showArrow={false}
        onOpenChange={(bool) => setVisibleChat(bool)}
        overlayClassName={style["chat-overlay-container"]}
        content={
          <div className={style["chat-frame-container"]}>
            <Row className={style["chat-header"]}>
              <Col>
                <div className={style["chat-frame-container__wrapper--svg"]}>
                  <div style={{ margin: "auto" }}>
                    <Icon component={ChatbotSvg} style={{ fontSize: 28, transform: "translateY(2px)" }} />
                  </div>
                </div>
              </Col>
              <Col>
                <span className={style["title-header"]}>{i18n.t(languageKeys.tro_ly_y_khoa_ai)}</span><Tag className={style['tag-name']} content='beta' color="#6576ff">beta</Tag>
              </Col>
            </Row>
            <ChatComponent contentText={contentText} messageFrame={messageFrame} loadingMessage={loadingMessage} breakPointRef={bpRef} />
            <div style={{ display: "flex", justifyContent: "center", padding: 4 }}>
              <Row style={{ maxWidth: "80%", color: "#3333337d" }} align={"middle"} gutter={2}>
                <Col span={2}>
                  <InfoCircleFilled style={{ fontSize: 20 }} />
                </Col>
                <Col span={22}>
                  <span style={{ fontSize: 12 }}>{i18n.t(languageKeys.warn_thong_tin_tham_khao_hoi_chuyen_gia)}</span>
                </Col>
              </Row>
            </div>
            <InputChat onSubmit={handleSubmit} loading={loadingMessage} />
          </div>
        }
      >
        <div hidden={!useAi} className={floatBtn ? style["float-btn"] : style["btn"]} style={styleWrapper}>
          <ChatbotSvg />
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default Chatbot;

const ChatComponent = ({ contentText = [], messageFrame = () => {}, loadingMessage, breakPointRef }) => {
  return (
    <div className={style.cssChatContainer}>
      {contentText.map((i, id) => {
        return messageFrame(i?.content, i?.user, contentText, id);
      })}
      <br />
      {loadingMessage && (
        <Row gutter={10}>
          <Col>
            <Icon component={ChatbotSvg} style={{ fontSize: 24, marginTop: 8 }} />
          </Col>
          <Col className={style["messageContainer"]} style={{ minWidth: 60, justifyContent: "center", display: "flex" }}>
            <div className={style.loadingMessageContainer} />
          </Col>
        </Row>
      )}
      <div className="break-point" ref={breakPointRef} />
    </div>
  );
};

const usePushData = ({ autoInterval = 3, next = () => {} }) => {
  const [time, setTime] = useState(0);
  const interval = useRef(null);

  const handleStart = (e) => {
    interval.current = setInterval(() => {
      setTime((time) => time + 1);
    }, 1500);
  };

  useEffect(() => {
    handleStart();
  }, []);
  const handleStop = (e) => {
    clearInterval(interval.current);
  };
  useEffect(() => {
    if (time === autoInterval) {
      handleStop();
    }
  }, [time]);

  useEffect(() => {
    next(time);
  }, [time]);
};
