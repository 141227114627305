import { CaretDownOutlined, CaretRightOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, notification, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apis, keys } from "../../constants";
import {
  common_post,
  convertDateToValue,
  convertValueToDate,
  currencyParserNotAlowNegativeNum,
  formatNumberToPrice,
  rid,
  deepEqual
} from "../../helpers";
import { useEventListener } from "../../hooks";
import i18n, { languageKeys } from "../../i18n";
import Table from "../Table/Table";
import HSDInput from "./HSDInput";
import styles from "./style.module.less";

function LichSuNhapThuoc(props) {
  const userProfile = useSelector((state) => state.auth.user);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentDataSource, setCurrentDataSource] = useState([])
  const [form] = useForm();
  useEffect(() => {
    //layLichSuNhap({})
  }, []);

  const [expandKeys, setExpandKeys] = React.useState([]);

  useEffect(() => {
    layLichSuNhap({ search_string: props.searchString });
  }, [props.searchString]);

  async function layLichSuNhap({
    search_string = props.searchString,
    page = currentPage,
  }) {
    setLoading(true);
    let THUOC_ID = props.dataThuoc.Thuoc_ID;
    let req = {
      search_string,
      page,
      THUOC_ID,
      limit: keys.limit,
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
    };
    try {
      let res = await common_post(apis.lay_lich_su_nhap_thuoc_dm_thuoc, req);
      if (res && res.status === "OK") {
        setDataSource(res.result.map((item, index) => ({ ...item, key: index })));
        setCurrentDataSource(res.result.map((item, index) => ({ ...item, key: index })));
        setCurrentPage(res.current_page);
        setTotal(res.total);
      }
      setLoading(false);
    } catch (error) {}
  }

  async function handleEditItem(item) {
    if (item.HAN_SU_DUNG !== "") {
      let checkDate = moment(item.HAN_SU_DUNG, "DD/MM/YYYY", true).isValid() || moment(item.HAN_SU_DUNG, "YYYYMMDD", true).isValid();
      if (!checkDate) {
        notification.warning({ message: i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang) });
        return;
      }
    }

    let itemCurrentEdit = currentDataSource.find(item => item.RLS_THUOC_PHIEU_ID === item.RLS_THUOC_PHIEU_ID);
    let itemEdit = { ...item, HAN_SU_DUNG: item.HAN_SU_DUNG.includes("/") ? convertValueToDate(item.HAN_SU_DUNG) : item.HAN_SU_DUNG };
    let notChangeData = deepEqual(itemCurrentEdit, itemEdit);
    if (notChangeData) {
      notification.warning({ message: i18n.t(languageKeys.vui_long_thay_doi_tt_truoc_luu) })
      return
    }
    let req = {
      ...item,
      NHANSU_ID: userProfile.NHANSU_ID,
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      HAN_SU_DUNG: item.HAN_SU_DUNG.includes("/") ? convertValueToDate(item.HAN_SU_DUNG) : item.HAN_SU_DUNG
    };

    let url = apis.sua_lo_thuoc_dm_thuoc;
    try {
      setLoading(true)
      const res = await common_post(url, req);
      if (res && res.status === "OK") {
        layLichSuNhap({});
        notification.success({
          message: i18n.t(languageKeys.thao_tac_thanh_cong),
        });
      }
    } catch (error) {}
  }

  const columns = [
    {
      title: i18n.t(languageKeys.field_Ma_phieu),
      key: "MA_PHIEU",
      dataIndex: "MA_PHIEU",
      render: (MA_PHIEU) => MA_PHIEU || "-",
    },
    {
      title: i18n.t(languageKeys.fieldMa_hoa_don),
      key: "MA_HOA_DON",
      dataIndex: "MA_HOA_DON",
      render: (MA_HOA_DON) => MA_HOA_DON || "-",
    },
    {
      title: i18n.t(languageKeys.menu_Kho_thuoc),
      key: "TEN_KHO",
      dataIndex: "TEN_KHO",
    },
    {
      title: i18n.t(languageKeys.field_So_luong),
      key: "SO_LUONG",
      dataIndex: "SO_LUONG",
    },
    {
      title: i18n.t(languageKeys.field_Ngay_tao),
      key: "NGAY",
      dataIndex: "NGAY",
      render: (NGAY) => convertDateToValue(NGAY),
    },
    {
      title: i18n.t(languageKeys.nha_cung_cap),
      key: "TEN_NCC",
      dataIndex: "TEN_NCC",
    },
    {
      title: i18n.t(languageKeys.field_Nguoi_tao),
      key: "NGUOI_TAO",
      dataIndex: "NGUOI_TAO",
    },
  ];

  return (
    <>
      <div className={styles.tableContainer}>
        <Table
          dataSource={dataSource}
          columns={columns}
          showPagination={true}
          className={styles.table}
          rowKey={(record) => record.RLS_THUOC_PHIEU_ID}
          totalResult={total}
          currentPage={currentPage}
          onPrev={() => layLichSuNhap({ page: currentPage - 1 })}
          onNext={() => layLichSuNhap({ page: currentPage + 1 })}
          limit={keys.limit}
          loading={loading}
          scroll={{ y: "calc(100vh - 280px)" }}
          expandable={{
            expandedRowRender: (record) => (
              <Form layout="vertical" name={record.key} key={record.key}>
                <Row align="bottom" gutter={10} style={{ marginLeft: "20px" }}>
                  <Col span={3}>
                    <Form.Item
                      label={i18n.t(languageKeys.field_so_lo)}
                      style={{ marginBlock: "0px" }}
                    >
                      <Input
                        value={record.MA_LO}
                        onChange={(e) => {
                          setDataSource(
                            dataSource.map((item) => {
                              if (
                                item.RLS_THUOC_PHIEU_ID ===
                                record.RLS_THUOC_PHIEU_ID
                              ) {
                                return { ...item, MA_LO: e.target.value };
                              }
                              return item;
                            })
                          );
                        }}
                        className={styles.input}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label={i18n.t(languageKeys.field_Gia_nhap)}
                      style={{ marginBlock: "0px" }}
                    >
                      <InputNumber
                        width={"100%"}
                        style={{ marginTop: "10px", width: "100%" }}
                        formatter={formatNumberToPrice}
                        parser={currencyParserNotAlowNegativeNum}
                        onChange={(number) => {
                          setDataSource(
                            dataSource.map((item) => {
                              if (
                                item.RLS_THUOC_PHIEU_ID ===
                                record.RLS_THUOC_PHIEU_ID
                              ) {
                                return {
                                  ...item,
                                  GIA_NHAP: number,
                                  GIA_CO_VAT: Math.round(
                                    number * (1 + record.VAT_PHAN_TRAM / 100)
                                  ),
                                  GIA_THANG_DU: tinhGiaThangDu(
                                    Math.round(
                                      number * (1 + record.VAT_PHAN_TRAM / 100)
                                    )
                                  ),
                                };
                              }
                              return item;
                            })
                          );
                        }}
                        value={record.GIA_NHAP}
                        className={styles.input}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label={i18n.t(languageKeys.field_gia_ban)}
                      style={{ marginBlock: "0px" }}
                    >
                      <InputNumber
                        width={"100%"}
                        style={{ marginTop: "10px", width: "100%" }}
                        formatter={formatNumberToPrice}
                        parser={currencyParserNotAlowNegativeNum}
                        onChange={(number) => {
                          setDataSource(
                            dataSource.map((item) => {
                              if (
                                item.RLS_THUOC_PHIEU_ID ===
                                record.RLS_THUOC_PHIEU_ID
                              ) {
                                return { ...item, GIA_BAN: number };
                              }
                              return item;
                            })
                          );
                        }}
                        value={record.GIA_BAN}
                        className={styles.input}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Form.Item label="VAT" style={{ marginBlock: "0px" }}>
                      <InputNumber
                        value={record.VAT_PHAN_TRAM}
                        min={0}
                        max={100}
                        formatter={(value) => `${value}%`}
                        parser={(value) => value && value.replace("%", "")}
                        className={styles.input}
                        onChange={(number) => {
                          setDataSource(
                            dataSource.map((item) => {
                              if (
                                item.RLS_THUOC_PHIEU_ID ===
                                record.RLS_THUOC_PHIEU_ID
                              ) {
                                return {
                                  ...item,
                                  VAT_PHAN_TRAM: number,
                                  GIA_CO_VAT: Math.round(
                                    record.GIA_NHAP * (1 + number / 100)
                                  ),
                                  GIA_THANG_DU: tinhGiaThangDu(
                                    Math.round(
                                      record.GIA_NHAP * (1 + number / 100)
                                    )
                                  ),
                                };
                              }
                              return item;
                            })
                          );
                        }}
                        style={{ width: "100%" }}
                      // className={styles.input}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label={i18n.t(languageKeys.gia_co_vat)}
                      style={{ marginBlock: "0px" }}
                    >
                      <InputNumber
                        className={styles.input}
                        formatter={formatNumberToPrice}
                        parser={currencyParserNotAlowNegativeNum}
                        style={{ width: "100%" }}
                        readOnly
                        value={record.GIA_CO_VAT}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item
                      label={i18n.t(languageKeys.field_Gia_thang_du)}
                      style={{ marginBlock: "0px" }}
                    >
                      <InputNumber
                        className={styles.input}
                        formatter={formatNumberToPrice}
                        parser={currencyParserNotAlowNegativeNum}
                        style={{ width: "100%" }}
                        readOnly
                        value={record.GIA_THANG_DU}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <HSDInput
                      name="HAN_SU_DUNG"
                      label={i18n.t(languageKeys.field_ngay_het_han)}
                      onChangeInput={(txt) => {
                        setDataSource(
                          dataSource.map((item) => {
                            if (
                              item.RLS_THUOC_PHIEU_ID ===
                              record.RLS_THUOC_PHIEU_ID
                            ) {
                              return {
                                ...item,
                                HAN_SU_DUNG: moment(
                                  txt,
                                  "DD/MM/YYYY",
                                  true
                                ).isValid()
                                  ? txt
                                  : moment(txt).format("YYYYMMDD"),
                              };
                            }
                            return item;
                          })
                        );
                      }}
                      value={
                        moment(record.HAN_SU_DUNG, "YYYYMMDD", true).isValid()
                          ? convertDateToValue(record.HAN_SU_DUNG)
                          : record.HAN_SU_DUNG
                      }
                    />
                    {/* <Form.Item label={i18n.t(languageKeys.field_ngay_het_han)} style={{marginBlock : "0px"}}>
                            <Input
                                className={styles["inputDate"]}
                                type="date"
                                onChange = {(e) => {
                                    let string = e.target.value
                                    console.log(string)
                                    setDataSource(dataSource.map(item => {
                                        if (item.RLS_THUOC_PHIEU_ID === record.RLS_THUOC_PHIEU_ID) {
                                            return {...item, HAN_SU_DUNG : string.split("-").join("")}
                                        }
                                        return item
                                    }))
                                }}
                                value= { record.HAN_SU_DUNG ? convertDateHTML(record.HAN_SU_DUNG) : ""}
                            />

                        </Form.Item> */}
                  </Col>
                  <Col span={3}>
                    <Button
                      type="primary"
                      style={{ width: "100px" }}
                      onClick={() => handleEditItem(record)}
                    >
                      {i18n.t(languageKeys.common_Luu)}
                    </Button>
                  </Col>
                </Row>
              </Form>
            ),
            rowExpandable: (record) => record.name !== "Not Expandable",
            expandedRowKeys: expandKeys,
            onExpand: (expanded, record) => {
              if (expanded) {
                setExpandKeys(expandKeys.concat(record.RLS_THUOC_PHIEU_ID));
              } else {
                setExpandKeys(
                  expandKeys.filter((item) => item !== record.RLS_THUOC_PHIEU_ID)
                );
              }
            },
            expandRowByClick: true,
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <CaretDownOutlined onClick={(e) => onExpand(record, e)} />
              ) : (
                <CaretRightOutlined onClick={(e) => onExpand(record, e)} />
              ),
          }}
        />
        <Row justify="end">
          <Button className={styles.button} onClick={() => props.onClose()} type="primary" ghost>
            {i18n.t(languageKeys.common_Thoat)} (ESC)
          </Button>
        </Row>
      </div>


    </>
  );
}

export default LichSuNhapThuoc;

// chuyển từ DD//MM/YYY sang MMMMYYDD
export const convertDateHTML = (dateString) => {
  if (dateString && dateString !== "") {
    let year = dateString.slice(0, 4);
    let moth = dateString.slice(4, 6);
    let date = dateString.slice(6, 8);
    return year + "-" + moth + "-" + date;
  }
  return "";
};

function tinhGiaThangDu(gia_co_vat) {
  if (gia_co_vat <= 1000) {
    return tinhGiaPhanTram(gia_co_vat, 15);
  }
  if (gia_co_vat <= 5000) {
    return tinhGiaPhanTram(gia_co_vat, 10);
  }
  if (gia_co_vat <= 100000) {
    return tinhGiaPhanTram(gia_co_vat, 7);
  }
  if (gia_co_vat <= 1000000) {
    return tinhGiaPhanTram(gia_co_vat, 5);
  }
  if (gia_co_vat > 1000000) {
    return tinhGiaPhanTram(gia_co_vat, 2);
  }
  return 0;
}

function tinhGiaPhanTram(gia, phan_tram) {
  return Math.round(gia * (1 + phan_tram / 100));
}
