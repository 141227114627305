import { createSlice } from '@reduxjs/toolkit';

const initState = {
    result: [],
    filters: {},
    total: 69,
    current_page: 1,
    limit: 15,
    total_money: 0,
    //
    dsPhHoanUng: [],
    dsChTT: []
};

const QlyTamUngSlice = createSlice({
    name: 'QlyTamUng',
    initialState: initState,
    reducers: {
        getDsPhTamUng: () => { },
        getDsPhTamUngSuccess: (state, action) => {
            const { page, result, total, filters, limit, total_money } = action.payload;
            state.result = result;
            state.current_page = page;
            state.total = total;
            state.limit = limit;
            state.filters = filters;
            state.total_money = total_money;
        },
        addPhTamUng: () => { },
        editPhTamUng: () => { },
        cancelPhTamUng: () => { },
        printPhTamUng: () => { },
        createPhHoanTien: () => { },
        getDsPhHoanUng: () => { },
        getDsPhHoanUngSuccess: (state, action) => {
            state.dsPhHoanUng = action.payload.result
        },
        getDsChuaThanhToan: () => { },
        getDsChTTSuccess: (state, action) => {
            state.dsChTT = action.payload.result
        },
    }
});

export const { getDsPhTamUng, getDsPhTamUngSuccess, addPhTamUng, editPhTamUng, cancelPhTamUng, printPhTamUng,
    createPhHoanTien, getDsPhHoanUng, getDsPhHoanUngSuccess, getDsChuaThanhToan, getDsChTTSuccess } = QlyTamUngSlice.actions;
export const QlyTamUngData = (state) => state.QlyVienPhi.QlyTamUng;

export default QlyTamUngSlice.reducer;