import { createSlice } from "@reduxjs/toolkit";

const initState = {
  qlTiepDon:{
      totalMain: 0
  },
  qlLichHen:{
    totalMain: 0,
    totalChoXacNhan:0,
    totalDaXacNhan:0,
    totalDaHuy:0
},
};

const totalTdLhSlice = createSlice({
  name: "totalTdLhSlice",
  initialState: initState,
  reducers: {
    doFetchTotal: (state, action) => {},
    setQlTiepDon: (state, action) => {
      state.qlTiepDon = {totalMain: action.payload};
    },
    setQlLichHen: (state, action) => {
      state.qlLichHen = action.payload;
    },
    resetState() {
      return initState;
    }
  },
});

export const {doFetchTotal, setQlTiepDon,setQlLichHen, resetState } = totalTdLhSlice.actions;
export const totalTdLhState = (state) => state.receipt.totalTdLh;


export default totalTdLhSlice.reducer;
