
import { combineReducers } from '@reduxjs/toolkit';
import DebtManagementSlice from './QlyCongNo.slice';
import QlyTamUngSlice from './QlyTamUng.slice';

const HospitalFeeManagementReducer = combineReducers({
    DebtManagement: DebtManagementSlice,
    QlyTamUng: QlyTamUngSlice
}) 

export default HospitalFeeManagementReducer




