import { nanoid } from "@reduxjs/toolkit";
import JsBarcode from "jsbarcode";
import moment from "moment";
import React from "react";
import _ from "lodash";
import { getGioiTinhBN } from "pages/QuanLyTiepDon/columnsTiepDon";

export const styleBarcode = `
#barcode-xn1  * {
  font-size: 12px;
  line-height: 1.5em;
  font-family: "Arial", Arial, san-serif;
}

#barcode-xn1  .barcode-container {
  display:flex;
  gap:2px;
  position: relative;
  width:1.95in;
  height:1.16in;
  page-break-after: always;
}

#barcode-xn1  .barcode-wrapper {
  margin:auto;

}

#barcode-xn1  .main-txt {
  position: absolute !important;
  left :-10%;
}

#barcode-xn1  .time-txt {
  font-size:8px;
  transform: rotate(-90deg);
  top:45px;
}

#barcode-xn1  .benh-pham-txt {
  font-size:8px;
  position: absolute !important;
  height: fit-content;
  width: 100%;
  top:6px;
  left:4px;
  text-transform: uppercase;
  font-weight:bold;
}

@media print {

  * {
    margin: 0;
    padding: 0;
  }
  #barcode-xn1  .foot-break {
    break-inside: avoid;
  }

  #barcode-xn1 {page-break-after: always}
}
`;

const PdfBarcode = ({ data }) => {
  React.useEffect(() => {

    try {
      if (data?.sid) {
        let arrSubffix = _.uniq(dataSource.map(x => x.suffix));
        for (const sub of arrSubffix) {
          let sid = `${data.sid}${sub || ''}`
          JsBarcode(`#barcode-${sub}`)
          .options({ font: "OCR-B" })
          .CODE128(sid, {
            text: `${data?.sid}${sub || ''}`,
          fontSize: 13,
          width: 1.5,
          height: 50,
          textMargin: 0,
          margin: 0,
          padding: 0,
          })
          ?.render?.();
        }
  
      }
    } catch (error) {
      console.log(error);
    }
  }, [data]);

  const { dataSource } = data;
  const result = (() => {
    try {
      let rs = [];
      dataSource.forEach((x) => {
        rs = rs.concat(Array(x.SO_LUONG).fill({ ...x, key: nanoid() }));
      });
      return rs;
    } catch (error) {
      console.log(error);
      return [];
    }
  })();

  return (
    <div id="barcode-xn1">
      <div>
        {result.map((i, ind) => {
          return (
            <div key={i.key} className="barcode-container">
                <div className="benh-pham-txt">{i.TEN_LOAI_BP}</div>

                <div className="main-txt time-txt">{moment().format("DD/MM/YYYY   HH:mm")}</div>
              <div className="barcode-wrapper" >
                <svg id={`barcode-${i.suffix}`} className="barcode-svg"></svg>
              </div>
              <span
                  style={{
                    fontSize: 8,
                    fontWeight: 700,
                    position: "absolute",
                    bottom:0,
                    textAlign:"center",
                    width:"100%",
                  }}
                >
                  {/* {i.TEN_KHACH_HANG} ({i?.GIOI_TINH ? getGioiTinhBN(i?.GIOI_TINH) : ''}.{i?.NGAY_SINH ? i?.NGAY_SINH?.slice(-4) : ''}) */}
                  {i.TEN_KHACH_HANG} ({i?.GIOI_TINH ? getGioiTinhBN(i?.GIOI_TINH) : ''}.{i?.NGAY_SINH ? i?.NGAY_SINH?.length == 4 ? i?.NGAY_SINH: moment(i?.NGAY_SINH, "YYYYMMDD").format("YYYY") : ''})

                </span>
              </div>
          );
        })}
      </div>
    </div>
  );
};

export default PdfBarcode;
