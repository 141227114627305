import TiepDonSlice from "./TiepDon.slice";
import TotalTdLhSlice from "./TotalTdLh.slice";

const { combineReducers } = require("@reduxjs/toolkit");

const QlTiepDonReducer = combineReducers({
    tiepDon:TiepDonSlice,
    totalTdLh:TotalTdLhSlice
});

export default QlTiepDonReducer;