import { formatCurrency2, layDiaChi } from "helpers";
import i18n, { languageKeys } from "../../../../i18n";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import JsBarcode from "jsbarcode";
import { nanoid } from "@reduxjs/toolkit";
import { convertString } from "components/PdfTemplates/update_1-11/PhieuChiDinhGreenLab";
import Images from "components/Images/Images";


export const stylePhieuCongNoGreenLab = `
#phieuCongNoGreenLab * {
    font-size: 14pt;
    font-family: "Times New Roman", Times, serif;
}

#phieuCongNoGreenLab .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}

#phieuCongNoGreenLab .header {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: '80px';
  font-size: 14pt;
}

#phieuCongNoGreenLab .header-left {
  max-width: 75%;
  display: flex;
  gap: 10px;
  flex-direction: row;
}

#phieuCongNoGreenLab .header-right {
  min-width: 25%;
  position: relative;
  display: flex;
  flex-direction: column ;
  align-items: center ;
  justify-content: center ;
  text-align:  center ;
  position:  relative ;
  min-width:  150px ;
}

#phieuCongNoGreenLab .header-department {
    text-transform: uppercase;
    font-weight: bold;
}

#phieuCongNoGreenLab .header-date {
    font-style: italic;
}

#phieuCongNoGreenLab .hospital-name {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16pt;
}


#phieuCongNoGreenLab .title {
  text-transform: uppercase;
  font-size: 16pt;
  font-weight: bold;
  text-align: center;
  width: 90%;
  margin: 2.5rem auto 0.25rem auto;
}

#phieuCongNoGreenLab .sub-title {
  margin-block: 0.5rem;
  font-size: 14pt;
  font-weight: bold;
}

#phieuCongNoGreenLab .info-customer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

#phieuCongNoGreenLab .info-customer-item{
  width: 50%;
}

#phieuCongNoGreenLab .date-time {
    text-align: center;
    font-size: 14pt;
    line-height: 18pt;
    font-weight: 400;
    font-weight: 400;
    font-style: italic;
}

#phieuCongNoGreenLab .report {
    text-align: center;
    font-size: 14pt;
    line-height: 18pt;
    font-weight: bold;
    font-style: italic;
}

#phieuCongNoGreenLab .table {
    margin-block: 1rem;
    width: 100%;
}

#phieuCongNoGreenLab .table * {
    font-size: 14pt;
}

#phieuCongNoGreenLab .table,
.table th, 
.table td {
    border: 1px solid black;
    border-collapse: collapse;
}

#phieuCongNoGreenLab .table th,
.table td {
    padding: 5px 10px;
}

#phieuCongNoGreenLab .date-time-footer{
    margin-top: 40px;
    font-size: 14pt;
    text-align: end;
    padding-right: 20px;
}
#phieuCongNoGreenLab .footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 50px;
}
#phieuCongNoGreenLab .footer-chu-ky{
    text-align: center;
    font-size: 14pt;
    font-weight: 700;
}
#phieuCongNoGreenLab .ky-ten {
    font-style: italic;
    font-size: 14pt;
    font-weight: normal;
}

#phieuCongNoGreenLab .sub-barcode-text { font-size:14pt;position:absolute;top:50px; left:50%; line-height: 1.2em; transform: translateX(-50%);}
#phieuCongNoGreenLab .phone-website { display: flex; gap: 24px; align-items: center }

@media print {
    #phieuCongNoGreenLab, #phieuCongNoGreenLab .foot-break {
        break-inside: avoid;
    }
    #phieuCongNoGreenLab { page-break-after: always}
`;

const PhieuCongNoGreenLab = ({ data, rid }) => {

  const userProfile = useSelector((state) => state.auth.user);
  const { hospital, benh_nhan, cac_phuong_thuc_tt } = data

  let dv_goi = data.dv || data.dv_goi

  useEffect(() => {
    if (!!rid && !!benh_nhan.ma_benh_nhan) {
      JsBarcode(`#barcode-${rid}`)
        .options({ font: "OCR-B" })
        .CODE128(benh_nhan.ma_benh_nhan, {
          text: ` `,
          fontSize: 14,
          width: 1.2,
          height: 40,
          textMargin: 0,
          margin: 0,
          padding: 0,
        })
        .render();
    }
  }, [benh_nhan.ma_benh_nhan, rid]);


  try {

    const MIEN_GIAM = (item) => {
      return item.TONG_TIEN_GIAM > 0 ? item.TONG_TIEN_GIAM : item.TONG_TIEN_GIAM_TIEP_DON
    }

    return Array(2).fill(null).map((_, index) => (
      <div id="phieuCongNoGreenLab" key={index}>
         <div className="header">
        {!!userProfile.benh_vien.ANH_DAI_DIEN && (
          <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>
        )}
        <div className="content-header" style={{paddingLeft:10}}>
          <p style={{ margin: 0, fontSize: "16pt" }} className="hospital-name upperTxt txt-700">
            {userProfile?.benh_vien?.TEN_CSKCB}
          </p>
          <div style={{ wordWrap: 'break-word' }}>
            <span className=" txt-700">{i18n.t(languageKeys.dia_chi)}:</span> {layDiaChi(userProfile.benh_vien)}
          </div>
          <div className="flex" style={{ display: 'flex'}}>
            <div className="tel">
              <span className="txt-700">{i18n.t(languageKeys.sdt)}: </span> {convertString(userProfile.benh_vien.SO_DIEN_THOAI)}
            </div>
          </div>
          <div>
              <span className="txt-700">Website:</span> {userProfile?.benh_vien?.WEBSITE || ''}
          </div>
          <div>
            <span className="txt-700">Email:</span> {convertString(userProfile.benh_vien.EMAIL)}
          </div>
        </div>
       
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', textAlign: 'center', position: 'relative', minWidth: '150pt' }}>
        <svg id={`barcode-${rid}`}></svg>
            <div className="sub-barcode-text">
              <div style={{ width: '300px', textAlign: 'center' }}>{i18n.t(languageKeys.field_Ma_BN)}: <b>{benh_nhan.ma_benh_nhan}</b></div>
              <div style={{ width: '300px', textAlign: 'center' }}>{i18n.t(languageKeys.field_Ma_phieu)}: <b>{benh_nhan.SO_PHIEU}</b></div>
            </div>
        </div>

      </div>

        <div className="title">{i18n.t(languageKeys.phieu_thu)}</div>
        <div className="sub-title" style={{ textAlign: "center", lineHeight: "1em", marginBottom: '2rem' }}>({i18n.t(languageKeys.hoa_don_lien)} {index + 1}: {index + 1 === 1 ? i18n.t(languageKeys.thu_ngan_giu) : i18n.t(languageKeys.khach_hang_giu)})</div>

        <div className="info-customer">
          <div className="info-customer-item">
            <div>{i18n.t(languageKeys.ho_ten)}: {benh_nhan.ten_benh_nhan} </div>
            <div>{i18n.t(languageKeys.field_So_dien_thoai)}: {benh_nhan.sdt}</div>
            <div>{i18n.t(languageKeys.dia_chi)}: {benh_nhan.dia_chi}</div>
            {
              data?.ten_goi_dv !== null ? (
                <div>{i18n.t(languageKeys.goi_dich_vu)}: {data.ten_goi_dv}</div>
              ) : <></>
            }
            {/* <div>{i18n.t(languageKeys.field_hinh_thuc_thanh_toan)}: {cac_phuong_thuc_tt?.length > 0 ? Object.keys(cac_phuong_thuc_tt[0])[0]: ''}</div> */}
            <div>{i18n.t(languageKeys.field_Ly_do_mien_giam)}: {benh_nhan.ly_do_mien_giam}</div>
          </div>
          <div className="info-customer-item">
            <div>{i18n.t(languageKeys.field_Ngay_sinh)}: {benh_nhan.ngay_sinh}&nbsp;&nbsp; {i18n.t(languageKeys.field_Gioi_tinh)}: {benh_nhan.gioi_tinh}</div>
            <div>{i18n.t(languageKeys.field_Doi_tuong)}: {benh_nhan.doi_tuong}</div>
            {
              data?.ten_loai_goi !== null ? (
                <div>{i18n.t(languageKeys.loai_goi)}: {data.ten_loai_goi || ''}</div>
              ) : <></>
            }
          </div>
          {/* <div className="info-customer-item">{i18n.t(languageKeys.field_Gioi_tinh)}: {benh_nhan.gioi_tinh}</div> */}
        </div>

        <table className="table">
          <thead>
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.stt)}</th>
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.field_Ten_dich_vu)}</th>
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.sl)}</th>
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.field_don_gia)}</th>
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.field_tong_tien)}</th>
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.field_doi_tuong_mien_giam)}</th>
            <th style={{ textAlign: "center" }}>{i18n.t(languageKeys.vien_phi_thanh_tien)}</th>
          </thead>

          <tbody>
            {dv_goi?.map((item, index) => {
              return (
                <>
                  <tr key={nanoid()}>
                    <td style={{ textAlign: "center", width: '5%' }}>
                      {index + 1}
                    </td>
                    <td style={{ width: '30%' }}>
                      {item.TEN_DV}
                    </td>
                    <td style={{ width: '5%', textAlign: 'center' }}>
                      {item.SO_LUONG}
                    </td>
                    <td style={{ width: '15%', textAlign: 'end' }}>
                      {formatCurrency2(item.GIA_DICH_VU || item.DON_GIA, '', true)}
                    </td>
                    <td style={{ width: '15%', textAlign: 'end' }}>
                      {formatCurrency2(item.GIA_DICH_VU || item.DON_GIA * item.SO_LUONG, '', true)}
                    </td>
                    <td style={{ width: '15%', textAlign: 'end' }}>
                      {formatCurrency2(MIEN_GIAM(item), '', true)}
                    </td>
                    <td style={{ width: '15%', textAlign: 'end' }}>
                      {formatCurrency2(item.GIA_DICH_VU || item.DON_GIA * item.SO_LUONG - MIEN_GIAM(item), '', true)}
                    </td>
                  </tr>
                  {item?.DV_KEM_THEO?.length > 0 && item?.DV_KEM_THEO?.map(i => {
                    return (
                      <tr key={nanoid()}>
                        <td></td>
                        <td style={{ width: '30%' }}>
                          <div style={{ marginLeft: 10 }}>
                            {i.TEN_DV}
                          </div>
                        </td>
                        <td style={{ width: '5%', textAlign: 'center' }}>
                          {i.SO_LUONG}
                        </td>
                        <td style={{ width: '15%', textAlign: 'end' }}>
                          {formatCurrency2((i.DON_GIA || i.GIA_DICH_VU), '', true)}
                        </td>
                        <td style={{ width: '15%', textAlign: 'end' }}>
                          {formatCurrency2((i.DON_GIA || i.GIA_DICH_VU) * i.SO_LUONG, '', true)}
                        </td>
                        <td style={{ width: '15%', textAlign: 'end' }}>
                          {formatCurrency2(MIEN_GIAM(i), '', true)}
                        </td>
                        <td style={{ width: '15%', textAlign: 'end' }}>
                          {formatCurrency2(i.GIA_DICH_VU || item.DON_GIA * i.SO_LUONG - MIEN_GIAM(i), '', true)}
                        </td>
                      </tr>
                    )
                  })}
                </>
              )
            })}

            {/* <tr key={rid()}>
                  <td style={{width: '5%'}}>
                    1
                  </td>
                  <td style={{ width: '30%' }}>
                    Dich vu mat xa
                  </td>
                  <td style={{width: '5%', textAlign: 'center'}}>
                    7
                  </td>
                  <td style={{ width: '15%', textAlign: 'end'}}>
                    1.000.000
                  </td>
                  <td style={{ width: '15%', textAlign: 'end'}}>
                    500.000
                  </td>
                  <td style={{ width: '15%', textAlign: 'end'}}>
                    500000
                  </td>
                  <td style={{ width: '15%', textAlign: 'end'}}>
                    312312
                  </td>
                </tr>
                <tr key={rid()}>
                  <td></td>
                  <td style={{ width: '30%' }}>
                    <div style={{marginLeft: 10}}>
                       Dich vu mat xa 1
                    </div>
                  </td>
                  <td style={{width: '5%', textAlign: 'center'}}>
                    7
                  </td>
                  <td style={{ width: '15%', textAlign: 'end'}}>
                    1.000.000
                  </td>
                  <td style={{ width: '15%', textAlign: 'end' }}>
                    500.000
                  </td>
                  <td style={{ width: '15%', textAlign: 'end' }}>
                    500000
                  </td>
                  <td style={{ width: '15%', textAlign: 'end' }}>
                    312312
                  </td>
                </tr> */}


            {/* <tr key={rid()}>
                      <td style={{ textAlign: 'center' }}>{'child.STT'}</td>
                      <td>{'child.TEN_DICHVU'}</td>
                      <td style={{ textAlign: 'center' }}>{('child.SO_LUONG')}</td>
                      <td style={{ textAlign: 'end' }}>{('child.DON_GIA')}</td>
                      <td style={{ textAlign: 'end' }}>{('child.TONG_TIEN')}</td>
                      <td style={{ textAlign: 'end' }}>{('child.MIEN_GIAM')}</td>
                      <td style={{ textAlign: 'end' }}>{('child.THANH_TIEN')}</td>
                    </tr> */}

            {/* </> */}

          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>{i18n.t(languageKeys.tong_cong)}</td>
              <td style={{ textAlign: 'center', fontWeight: 'bold' }}>{dv_goi.reduce((total, curr) => total += curr.SO_LUONG, 0)}</td>
              <td style={{ textAlign: 'end', fontWeight: 'bold' }}>{formatCurrency2(dv_goi.reduce((total, curr) => total += (curr.DON_GIA || curr.GIA_DICH_VU), 0), '', true)}</td>
              <td style={{ textAlign: 'end', fontWeight: 'bold' }}>{formatCurrency2(dv_goi.reduce((total, curr) => total += ((curr.DON_GIA || curr.GIA_DICH_VU) * curr.SO_LUONG), 0), '', true)}</td>
              <td style={{ textAlign: 'end', fontWeight: 'bold' }}>{formatCurrency2(dv_goi.reduce((total, curr) => total += MIEN_GIAM(curr), 0), '', true)}</td>
              <td style={{ textAlign: 'end', fontWeight: 'bold' }}>{formatCurrency2(dv_goi.reduce((total, curr) => total += (curr.GIA_DICH_VU || curr.DON_GIA * curr.SO_LUONG - MIEN_GIAM(curr)), 0), '', true)}</td>
            </tr>
          </tfoot>
        </table>

        {/* <div style={{ textAlign: 'end', marginTop: 10 }}>{i18n.t(languageKeys.tong_thanh_toan)}: <span style={{ fontWeight: 'bold' }}>{formatCurrency2(data.tong_thanh_toan, '', true)}</span></div> */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div />
          <div>
            {data.cac_phuong_thuc_tt.map((httt, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 40,
                  alignItems: "center",
                }}
              >
                <div>{i18n.t(languageKeys.field_Thanh_toan) + " " + Object.keys(httt)[0]}:</div>
                <div>{formatCurrency2(Object.values(httt)[0], '', true)}</div>
              </div>
            ))}
          </div>
        </div>

        <div style={{ padding: '0px 30px', display: "flex", justifyContent: 'space-between', marginTop: 10 }}>
          <div>{i18n.t(languageKeys.da_thanh_toan)}: <span style={{ fontWeight: 'bold' }}>{formatCurrency2(data.tong_da_thanh_toan, '', true)}</span></div>
          <div>{i18n.t(languageKeys.thanh_toan)}: <span style={{ fontWeight: 'bold' }}>{formatCurrency2(data.tong_tien_khach_dua, '', true)}</span></div>
          <div>{i18n.t(languageKeys.so_du_cong_no)}: <span style={{ fontWeight: 'bold' }}>
            {data.tong_thanh_toan - data.tong_da_thanh_toan - data.tong_tien_khach_dua < 0 ? 0 : formatCurrency2((data.tong_thanh_toan - data.tong_da_thanh_toan - data.tong_tien_khach_dua), '', true)}
          </span></div>
        </div>

        <div className="date-time-footer">
          {`${data.gio}, ${data.ngay_tao}`}
        </div>
        <div className="footer">
          <div>
            <div className="footer-chu-ky">
              {i18n.t(languageKeys.khach_hang)}
              <div className="ky-ten">{i18n.t(languageKeys.ky_ghi_ro_ho_ten)}</div>
              <div style={{ marginTop: 50 }}>{benh_nhan?.ten_benh_nhan || ""}</div>
            </div>
          </div>
          <div>
            <div className="footer-chu-ky">
              {i18n.t(languageKeys.data_Thu_ngan)}
              <div className="ky-ten">{i18n.t(languageKeys.ky_ghi_ro_ho_ten)}</div>
              <div style={{ marginTop: 50 }}>{data?.ten_nhan_su || ""}</div>
            </div>
          </div>
        </div>
      </div>
    ))
  } catch (error) {
    console.log(error);
    return (
      <div id="phieuCongNo" style={{ width: '100%' }}>
        {/* <Result
          style={{ display: 'block', margin: 'auto', paddingInline: '30%', textAlign: 'center' }}
          status="500"
          title="Có lỗi xảy ra"
        /> */}
      </div>
    );
  }
};

export default PhieuCongNoGreenLab
