import { createSlice } from "@reduxjs/toolkit";
import keys, { notiListKey } from "constants/keys";
import { localSet } from "helpers";
import _ from "lodash";

const initState = {
  message: "",
  unCheckListNoti: [],
  checkedListNoti: [],
  reloadKqDv: "",
};

const risReloadSilce = createSlice({
  name: "pdf",
  initialState: initState,
  reducers: {
    doReload(state, action) {
      state.message = "RELOAD";
    },
    setUnCheckListNoti(state, action) {
      let payload = [];

      if (Array.isArray(action.payload)) {
        payload = [...action.payload];
      } else {
        payload = [action.payload];
      }
      state.unCheckListNoti = [...payload];
    },
    updateUncheckListNoti(state, action) {
      let payload = [];

      if (Array.isArray(action.payload)) {
        payload = [...action.payload];
      } else {
        payload = [action.payload];
      }
      state.unCheckListNoti = [...payload, ...state.unCheckListNoti];
    },
    updateUnCheckListNoti(state,action) {
      let payload = [];

        if(Array.isArray(action.payload)) {
            payload = [...action.payload];
        } else {
            payload = [action.payload];
        }
      state.unCheckListNoti = [...payload,...state.unCheckListNoti];
    },
    setCheckedListNoti(state, action) {
      let payload = [];

      if (Array.isArray(action.payload)) {
        payload = [...action.payload];
      } else {
        payload = [action.payload];
      }
      state.checkedListNoti = payload;
    },
    doReloadKq(state) {
      state.reloadKqDv = "RELOAD";
    },
    setMessageRis(state, action) {
      state.message = action.payload;
    },
    resetRisMessage(state) {
      state.message = "";
      state.reloadKqDv = "";
    },
  },
});

export const { doReload, resetRisMessage, setUnCheckListNoti, setCheckedListNoti, doReloadKq, updateUncheckListNoti, setMessageRis } =
  risReloadSilce.actions;

export default risReloadSilce.reducer;
