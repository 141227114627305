import { notification } from "antd";
import i18n, { languageKeys } from "../../../../i18n";
import { put, select, takeLatest } from "redux-saga/effects";
import { setTableLoading } from "ducks/slices/loadingSlice";
import { layLSThanhToanSDGoiDv } from "../apiGoiDichVu";
import { fetchPacketUsagePaymentHistory, fetchPacketUsagePaymentHistorySuccess } from "ducks/slices/GoiDichVu/ThongTinSDGoiDv/packageUsagePaymentHistory.slice";

export function* watchPacketUsagePaymentHistory(){
    yield takeLatest(fetchPacketUsagePaymentHistory, handleFetchPacketUsagePaymentHistory)
}

function* handleFetchPacketUsagePaymentHistory(action) {
    try{
        yield put(setTableLoading(true))
        const userProfile = yield select(state => state.auth.user)
        const res = yield layLSThanhToanSDGoiDv({
            BENH_VIEN_ID: userProfile?.BENH_VIEN_ID,
            parter_code: userProfile?.parter_code,
            ID: action?.payload
        })

        if(!!res){
            if(res.status === 'OK'){
                yield put(fetchPacketUsagePaymentHistorySuccess(res))
            } else {
                notification.error({
                    message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
                    placement: 'bottomLeft'
                });
            }
        } else {
            notification.error({
                message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
                placement: 'bottomLeft'
            });
        }
    } catch (err) {
        notification.error({
            message: i18n.t(languageKeys.noti_lay_danh_sach_that_bai),
            placement: 'bottomLeft'
        });
    } finally {
        yield put(setTableLoading(false))
    }
}