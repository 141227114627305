import moment from "moment";
import React from "react";
import { layDiaChi, getSexFromString, rid } from "../../../helpers";
import { useSelector } from "react-redux";
import i18n, { languageKeys, languages } from "../../../i18n";
import Images from "components/Images/Images";
import { enDateFormat } from "constants/keys";

export const styleBenhTrinhDieuTri = `
#benh-trinh-dieu-tri * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }

#benh-trinh-dieu-tri .column {
  float: left;
  width: 33.33%;
}

#benh-trinh-dieu-tri .row:after {
  content: "";
  display: table;
  clear: both;
}

#benh-trinh-dieu-tri .container-row {
  margin-top: 8px;
}

#benh-trinh-dieu-tri .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#benh-trinh-dieu-tri .header { display: flex; gap: 10px; }
#benh-trinh-dieu-tri .flex {
    display: flex;
  }
#benh-trinh-dieu-tri .flex.justify {
    justify-content: space-around;
  }

#benh-trinh-dieu-tri .text-bold {
    font-size: 12px;
    font-weight: bold !important;
  }

  #benh-trinh-dieu-tri .table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
  }

  #benh-trinh-dieu-tri td, #benh-trinh-dieu-tri th {
    border: 1px solid #000;
    // text-align: center;
    padding: 6px;
    // color: #2c3782;
  }

  #benh-trinh-dieu-tri .table th:first-child {
    border: 1px solid #000;
    text-align: left;
    padding: 6px;
  }

#benh-trinh-dieu-tri .tel {
    margin-right: 50px;
  }
#benh-trinh-dieu-tri .txt-700 {
    font-weight: 700;
  }
#benh-trinh-dieu-tri .container-title {
  text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2rem auto 1rem auto;
  }
#benh-trinh-dieu-tri .container__title--main {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
#benh-trinh-dieu-tri .container__ttbn--wrapper {
  margin-top: 6px;
}
#benh-trinh-dieu-tri .container__ttbn--wrapper>div {
    margin: 4px 0 0 0;
  }
#benh-trinh-dieu-tri .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  #benh-trinh-dieu-tri .container-wrapper-flex .col-3{
    width: 30%;
  }

  #benh-trinh-dieu-tri .container-wrapper-flex .col-4{
    width: 40%;
  }

  #benh-trinh-dieu-tri .thongtin-khambenh {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  #benh-trinh-dieu-tri .thongtin-khambenh .left {
    // width: 65%;
    display: flex;
    flex-direction: column;
  }

  #benh-trinh-dieu-tri .thongtin-khambenh .right {
    width: 35%;
  }

  #benh-trinh-dieu-tri .table-border {
    padding: 6px 0;
    border: 1px solid #000;
  }

  #benh-trinh-dieu-tri .table-item{
    padding-left:12px;
  }

  #benh-trinh-dieu-tri hr.solid {
    border-top: 1px solid #000;
  }

  #benh-trinh-dieu-tri .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 40px;
  }

  #benh-trinh-dieu-tri .footer .footer-confirm {
    margin-right: 44px;
    text-align: center;
  }

  #benh-trinh-dieu-tri .name-space {
    height: 80px;
  }

  #benh-trinh-dieu-tri .txt-size-m{
    font-size: 12px;
  }

  #benh-trinh-dieu-tri .upperTxt{
    text-transform: uppercase;
  }

  #benh-trinh-dieu-tri .phone-website { 
    display: flex; gap: 24px; align-items: center 
  }

@media print {
#benh-trinh-dieu-tri .foot-break { break-inside: avoid; }
}

#benh-trinh-dieu-tri .table { margin-block: 10px; }
#benh-trinh-dieu-tri .table * { font-size: 12px; }
#benh-trinh-dieu-tri .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#benh-trinh-dieu-tri .table th, .table td { padding: 5px 10px; }
#benh-trinh-dieu-tri .table th { text-align: start; }
`;

const PhieuBenhTrinhDieuTri = ({ data }) => {

  const userProfile = useSelector((state) => state.auth.user);
  let { benh_nhan, DIEN_BIEN_BENH_TRINH } = data;


  return (
    <div id="benh-trinh-dieu-tri">
      <div className="header">
        {!!userProfile?.benh_vien?.ANH_DAI_DIEN && (
          <div className="img-header">
            <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>
          </div>
        )}
        <div className="content-header">
          <h2 style={{ margin: 0, fontSize: 10 }} className="upperTxt">
            {userProfile?.benh_vien?.TEN_CSKCB}
          </h2>
          <div>
            <span className="txt-700">{i18n.t(languageKeys.dia_chi)}:</span> {layDiaChi(userProfile.benh_vien)}
          </div>
          <div className="flex">
            <div className="tel">
              <span className="txt-700"> {i18n.t(languageKeys.dien_thoai)}: </span> {convertString(userProfile.benh_vien.SO_DIEN_THOAI)}
            </div>
            <div>
              <span className="txt-700">Website:</span> {convertString(userProfile.benh_vien.WEBSITE)}
            </div>
          </div>
          <div>
            <span className="txt-700">Email:</span> {convertString(userProfile.benh_vien.EMAIL)}
          </div>
        </div>
      </div>
      <div className="container">
        <div className="container-title">
          {i18n.t(languageKeys.benh_trinh_dieu_tri)}
        </div>
        <div className="container__chapter--1">
          <div className="container__title--main">I. {i18n.t(languageKeys.field_Thong_tin_hanh_chinh)?.toUpperCase()}</div>
          <div className="container__ttbn--wrapper">
            <div className="container-wrapper-flex">
              <div className="col-3">
                <div>
                  {i18n.t(languageKeys.field_Ma_benh_nhan)}: <span className="txt-700 txt-size-m">{convertString(benh_nhan.ma_benh_nhan)}</span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(benh_nhan.gioi_tinh)}
                </div>
              </div>
              <div className="col-4">
                <div>
                  {i18n.t(languageKeys.ten_benh_nhan)}:{" "}
                  <span className="txt-700 txt-size-m"> {convertString(benh_nhan.ten_benh_nhan)?.toUpperCase()} </span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Doi_tuong)}: {convertString(benh_nhan.doi_tuong)}
                </div>
              </div>
              <div className="col-3">
                <div>
                  {benh_nhan.ngay_sinh.trim().length > 4 ? i18n.t(languageKeys.field_Ngay_sinh) : i18n.t(languageKeys.field_Nam_sinh)}:{" "}
                  {benh_nhan.ngay_sinh}
                </div>
                <div>
                  {i18n.t(languageKeys.field_So_dien_thoai)}: <span className="txt-700">{convertString(benh_nhan.sdt)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dia-chi">
            {i18n.t(languageKeys.dia_chi)}: {convertString(benh_nhan.dia_chi)}
          </div>
        </div>

        <div className="container__title--main" style={{ margin: "10px 0" }}>
          II. {i18n.t(languageKeys.dien_bien_cua_benh).toUpperCase()}
        </div>

        <table style={{ width: "100%" }} className="table">
          <thead>
            <tr>
              <th style={{ width: "20%", textAlign: 'center' }}>{i18n.t(languageKeys.chi_so_suc_khoe)}</th>
              <th style={{ width: "30%", textAlign: 'center' }}>{i18n.t(languageKeys.dien_bien_cua_benh)}</th>
              <th style={{ width: "30%", textAlign: 'center' }}>{i18n.t(languageKeys.thuoc_vat_tu_su_dung)}</th>
              <th style={{ width: "10%", textAlign: 'center' }}>{i18n.t(languageKeys.answer_co)}</th>
              <th style={{ width: "10%", textAlign: 'center' }}>{i18n.t(languageKeys.answer_khong)}</th>
            </tr>
          </thead>
          <tbody>
            {DIEN_BIEN_BENH_TRINH.map((item, index) => {
              return (
                <>
                  <tr key={index}>
                    &nbsp;&nbsp;
                    <b>{i18n.t(languageKeys.data_Ngay)} {moment(item.NGAY_TIEP_DON, "YYYYMMDD").format("DD/MM/YYYY")}</b>
                  </tr>
                  <tr>
                    <td>
                      <b>{i18n.t(languageKeys.truoc_thuc_hien)}</b><br />
                      {i18n.t(languageKeys.field_Mach)}: {item.MACH_TRUOC_THUC_HIEN}<br />
                      {i18n.t(languageKeys.field_Nhiet_do)}: {item.NHIET_DO_TRUOC_THUC_HIEN}<br />
                      {i18n.t(languageKeys.field_Huyet_ap)}: {item.HUYET_AP_TRUOC_THUC_HIEN}<br />
                    </td>
                    <td rowSpan={3} style={{ verticalAlign: 'top' }}>
                      {item.DIEN_BIEN_CUA_BENH}
                    </td>
                    <td rowSpan={3} style={{ verticalAlign: 'top' }}>
                      {item.DANH_SACH_VAT_TU.map((i, idx) => (
                        <>
                          {idx + 1}. {i.TEN_VAT_TU}<br />
                        </>
                      ))}
                    </td>
                    <td rowSpan={3} style={{ textAlign: 'center', verticalAlign: 'top' }}>
                      {item.DANH_SACH_VAT_TU.map(i => (
                        <>
                          {i.TRANG_THAI_SU_DUNG === "DA_SU_DUNG" ? "✔" : ""}<br />
                        </>
                      ))}
                    </td>
                    <td rowSpan={3} style={{ textAlign: 'center', verticalAlign: 'top' }}>
                      {item.DANH_SACH_VAT_TU.map(i => (
                        <>
                          {i.TRANG_THAI_SU_DUNG === "DA_SU_DUNG" ? "" : "✔"}<br />
                        </>
                      ))}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{i18n.t(languageKeys.trong_khi_thuc_hien)}</b><br />
                      {i18n.t(languageKeys.field_Mach)}: {item.MACH_TRONG_THUC_HIEN}<br />
                      {i18n.t(languageKeys.field_Nhiet_do)}: {item.NHIET_DO_TRONG_THUC_HIEN}<br />
                      {i18n.t(languageKeys.field_Huyet_ap)}: {item.HUYET_AP_TRONG_THUC_HIEN}<br />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{i18n.t(languageKeys.sau_khi_thuc_hien)}</b><br />
                      {i18n.t(languageKeys.field_Mach)}: {item.MACH_SAU_THUC_HIEN}<br />
                      {i18n.t(languageKeys.field_Nhiet_do)}: {item.NHIET_DO_SAU_THUC_HIEN}<br />
                      {i18n.t(languageKeys.field_Huyet_ap)}: {item.HUYET_AP_SAU_THUC_HIEN}<br />
                    </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="footer foot-break">

        <div style={{ marginTop: 16, textAlign: 'center', marginLeft: 44 }}>
          <div className="txt-700">{i18n.t(languageKeys.khach_hang)}</div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          {/* <div className="txt-700">{data.benh_nhan.ten_benh_nhan}</div> */}
        </div>

        <div style={{ marginTop: 16, textAlign: 'center', marginLeft: 44 }}>
          <div className="txt-700">{i18n.t(languageKeys.dieu_duong)}</div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          {/* <div className="txt-700">{data.benh_nhan.ten_benh_nhan}</div> */}
        </div>

        <div className="footer-confirm">
          {i18n.language === languages.tieng_viet ? (
            <i>
              {moment().format("HH:mm") + ", "}
              Ngày {moment().date()},tháng {moment().month() + 1}, năm {moment().year()}
            </i>
          ) : (
            <i> {moment().format(enDateFormat)}</i>
          )}
          <div className="txt-700">{i18n.t(languageKeys.field_Bac_si)}</div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          {/* <div className="txt-700">{data.tu_van_vien}</div> */}
        </div>

      </div>
    </div>
  );
};

let convertString = (field) => {
  let text = "";

  if (!!field && typeof field === "string") {
    text = field;
  } else if (!!field && typeof field === "number") {
    text = `${field}`;
  }
  return text;
};

export default PhieuBenhTrinhDieuTri;
