import { HUONG_XU_TRI } from "constants/keys";
import moment from "moment";
import { useSelector } from "react-redux";
import { removeTags } from "../../helpers";
import i18n, { languageKeys } from "../../i18n";

export const styleInBenhAnNgoaiTru = `
#in-benh-an-ngoai-tru * {
  font-size: 14px;
  line-height: 1.5;
  font-family: "Times New Roman", Times, serif;
}

#in-benh-an-ngoai-tru p {
  margin: 0;
}

#in-benh-an-ngoai-tru .col-100 {
  width: 100%;
}

#in-benh-an-ngoai-tru .col-50 {
  width: 50%;
}


#in-benh-an-ngoai-tru .col-70 {
  width: 70%;
}

#in-benh-an-ngoai-tru .col-30 {
  width: 30%;
}

#in-benh-an-ngoai-tru .col-33 {
  width: 33%;
}

#in-benh-an-ngoai-tru .flex {
  display: flex;
}

.header {
  display: flex;
  gap: 10;
}

.text-center {
  text-align: center;
}

.box {
  width: 20px;
  height: 20px;
  border: 1px solid #333; 
  text-align: center;
}

.box-sinh-ton {
  min-width: 130px;
  height: 100px;
  border: 1px solid #333; 
  padding: 8px;
}


.gap-10 {
  gap:10px;
}
.float-right {
  float:right;
}


#in-benh-an-ngoai-tru .header {
  display: flex;
  gap: 20px;
}

#in-benh-an-ngoai-tru .hospital-name {
  text-transform: uppercase;
  font-weight: bold;
}

#in-benh-an-ngoai-tru .stt {
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

#in-benh-an-ngoai-tru .title {
  margin-block: 0.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.group-gioi-tinh {
  justify-content: space-evenly;
}

.group-gioi-tinh div {
  gap:10px;
}

.wrap-gioi-tinh > p{
  text-wrap:nowrap;
}

.space-between {
  justify-content: space-between;
}

.flex-wrap {
  flex-wrap: wrap;
}


.bold {
  font-weight: bold;
}
.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.uppercase {
  text-transform: ;
}

.page-2 {
  page-break-before: always;
}

table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
.dotted-line {
  width: 100%;
  border-bottom: 1px dotted black;
  margin: 10px 0;
}
@media print {
  #in-benh-an-ngoai-tru .foot-break {
      break-inside: avoid;
  }

  .page-2 {
    page-break-before: always;
  }
}
`;

const InBenhAnNgoaiTru = ({ data }, rid = "") => {
  const userProfile = useSelector((state) => state.auth.user);


  const { patient, appointment, userHistoryServices, stats, data_benh, services } = data


  const renderNgaySinh = () => {
    let NGAY_SINH = patient?.NGAY_SINH

    if (NGAY_SINH && NGAY_SINH?.length > 0) {
      let NGAY_SINH_CV = (NGAY_SINH?.length == 4 ? moment(NGAY_SINH).format("YYYY") : moment(NGAY_SINH, "YYYYMMDD").format("DDMMYYYY")).toString().split('');
      if (NGAY_SINH?.length == 4) return ["", "", "", ""].concat(NGAY_SINH_CV)?.map((obj, index) => <div className="box" style={{ marginRight: (index == 1 || index == 3) ? 4 : 0 }}>{obj}</div>)
      if (NGAY_SINH?.length == 8) return NGAY_SINH_CV?.map((obj, index) => <div className="box" style={{ marginRight: (index == 1 || index == 3) ? 4 : 0 }}>{obj}</div>)
    }

    return ["", "", "", "", "", "", "", ""]?.map((obj, index) => <div className="box" style={{ marginRight: (index == 1 || index == 3) ? 4 : 0 }}>{obj}</div>)
  }

  const renderTuoi = () => {
    let NGAY_SINH = patient?.NGAY_SINH
    let DO_TUOI = null;

    if (NGAY_SINH && NGAY_SINH?.length > 0) {
      let NGAY_SINH_CV = (NGAY_SINH?.length == 4 ? moment(NGAY_SINH).format("YYYY") : moment(NGAY_SINH).format("YYYYMMDD"));
      DO_TUOI = moment().diff(NGAY_SINH_CV, "years");
      if (DO_TUOI > 0) {
        if (DO_TUOI?.length == 0) DO_TUOI = `0${DO_TUOI}`
        return DO_TUOI?.toString().split('')?.map((obj, index) => <div className="box">{obj}</div>)
      }
    }
    return ["", ""]?.map((obj, index) => <div className="box">{obj}</div>)
  }

  const renderDiaChi = () => {
    let letArray = []
    if (patient?.DIA_CHI_CHI_TIET?.length > 0) letArray.push(patient?.DIA_CHI_CHI_TIET)
    if (patient?.TEN_PHUONG_XA?.length > 0) letArray.push(patient?.TEN_PHUONG_XA)
    if (patient?.TEN_QUAN_HUYEN?.length > 0) letArray.push(patient?.TEN_QUAN_HUYEN)
    if (patient?.TEN_TINH_THANH?.length > 0) letArray.push(patient?.TEN_TINH_THANH)
    return letArray.join(", ")
  }

  const renderDiaChiNguoiThan = () => {
    let letArray = []
    if (appointment?.TEN_NGUOI_THAN?.length > 0) letArray.push(appointment?.TEN_NGUOI_THAN)
    if (appointment?.DIA_CHI_NGUOI_THAN?.length > 0) letArray.push(appointment?.DIA_CHI_NGUOI_THAN)
    if (appointment?.SDT_NGUOI_THAN?.length > 0) letArray.push(`Số điện thoại: ${appointment?.SDT_NGUOI_THAN}`)
    return letArray.join(",   ")
  }

  const renderTimeTiepDon = () => {
    let [gio, phut] = appointment?.GIO_TIEP_DON?.split(':')
    let TIME = appointment?.NGAY_TIEP_DON
    return `${gio} giờ  ${phut} phút ngày ${moment(TIME, "YYYYMMDD").format("DD")} tháng ${moment(TIME, "YYYYMMDD").format("MM")} năm  ${moment(TIME, "YYYYMMDD").format("YYYY")}`
  }

  const renderTimeShort = (key) => {
    let TIME = appointment?.[key]
    if (!TIME) return "............"
    return `${moment(TIME, "YYYYMMDD").format("DD/MM/YYYY")}`
  }

  const tomTatKetQuaCLS = () => {
    let arrayData = []
    services?.forEach(element => {
      if (element?.KET_LUAN?.length > 0) {
        arrayData = arrayData.concat(element?.KET_LUAN?.filter(objFilter => objFilter?.KET_LUAN?.length > 0)?.map(obj => obj.KET_LUAN))
      }
      let data = element?.CHI_TIET_PHIEU?.filter(objFilter => objFilter?.KET_LUAN?.length > 0)?.map(obj => obj.KET_LUAN)
      data.forEach(x => {
        let dataRemoveTag = x
        try {
          dataRemoveTag = removeTags(dataRemoveTag)
        } catch (error) {
          dataRemoveTag = x
        }
        arrayData.push(dataRemoveTag)
      })

    });
    let uniqueArray = [...new Set(arrayData)]
    return uniqueArray.join(", ")

  }

  const chanDoanBanDau = () => {
    let arrayData = []
    services?.filter(objFilter => objFilter?.PHAN_LOAI == "KHAM_BENH").forEach(element => {
      if (element?.CHAN_DOAN_LAM_SANG && element?.CHAN_DOAN_LAM_SANG?.length > 0) arrayData.push(element?.CHAN_DOAN_LAM_SANG)
    });
    return arrayData.join(", ")
  }

  const huongXuTri = () => {
    let arrayData = []
    services?.filter(objFilter => objFilter?.PHAN_LOAI == "KHAM_BENH").forEach(element => {
      if (element?.XU_TRI && element?.XU_TRI?.length > 0) {
        arrayData.push(HUONG_XU_TRI[element?.XU_TRI])
      }
    });
    return arrayData.join(", ")
  }

  const getICDBenhChinh = () => {
    let arrayData = []
    data_benh?.filter(obj => obj?.PHAN_LOAI_CHUAN_DOAN == "CHINH").forEach(element => {
      arrayData.push(`${element?.MA_ICD} - ${element?.TEN_ICD}`)
    });
    return arrayData.join(", ")
  }

  const getICDBenhPhu = () => {
    let arrayData = []
    data_benh?.filter(obj => obj?.PHAN_LOAI_CHUAN_DOAN == "PHU").forEach(element => {
      arrayData.push(`${element?.MA_ICD} - ${element?.TEN_ICD}`)
    });
    return arrayData.join(", ")
  }

  const getQuaTrinhBenhLy = () => {
    let arrayData = []
    services?.filter(objFilter => objFilter?.PHAN_LOAI == "KHAM_BENH")?.forEach(element => {
      if (element?.QUA_TRINH_BENH_LY?.length > 0) {
        arrayData.push(element?.QUA_TRINH_BENH_LY)
      }
    });

    if (userProfile?.partner_code == "cellab_17026232575" || userProfile?.partner_code == "noiquoctuan5") {
      userHistoryServices?.forEach(element => {
        if (element?.DS_DV?.length > 0 && element?.DS_DV?.[0]?.DIEN_BIEN_CUA_BENH?.length > 0) {
          arrayData.push(`${element?.DS_DV?.[0]?.GIO} - ${moment(element?.NGAY, "YYYYMMDD").format("DD/MM/YYYY")}:  ${element?.DS_DV?.[0]?.DIEN_BIEN_CUA_BENH}`)
        }
      });
    }
    return arrayData.join(", ")
  }

  const getLyDoKham = () => {
    let arrayData = []
    services?.filter(objFilter => objFilter?.PHAN_LOAI == "KHAM_BENH")?.forEach(element => {
      if (element?.LY_DO_VAO_VIEN?.length > 0) {
        arrayData.push(element?.LY_DO_VAO_VIEN)
      }
    });

    return arrayData.join(", ")
  }

  const getKetLuanXn = () => {
    let arrayData = []
    services?.filter(obj => obj?.PHAN_LOAI == "XET_NGHIEM")?.forEach(element => {
      if (element?.KET_LUAN?.length > 0) {
        console.log("element?.KET_LUAN----1", element?.KET_LUAN)
        element?.KET_LUAN.forEach(detailKL => {
          if (detailKL?.KET_LUAN?.length > 0) {
            arrayData.push(detailKL?.KET_LUAN)
          }
        });
      }
    });

    let uniqueArray = [...new Set(arrayData)]
    return uniqueArray.join(", ")
  }

  const getTienSuBenh = (key) => {
    let arrayData = []
    services?.filter(objFilter => objFilter?.PHAN_LOAI == "KHAM_BENH")?.forEach(element => {
      if (element?.[key]?.length > 0)
        arrayData.push(element?.[key])
    });

    return arrayData.join(", ")
  }


  const lineDot = () => {
    return <span style={{ flexGrow: 1, borderBottom: "1px dotted #333", marginBottom: 4 }}></span>
  }

  const lineDot2 = () => {
    return <span style={{ flexGrow: 1, height:"30px", borderBottom: "1px dotted #333", marginBottom: 4 }}></span>
  }



  return (
    <div id="in-benh-an-ngoai-tru">
      <div className="col-100 flex header">
        <div className="col-33">
          <p>Sở Y tế</p>
          <p>{userProfile?.benh_vien?.TEN_CSKCB}</p>
        </div>
        <div className="col-33">
          <p className="uppercase fs-20 bold">BỆNH ÁN NGOẠI TRÚ</p>
          <p className="uppercase fs-16 bold"></p>
        </div>
        <div className="col-33">
          <p>MS:</p>
          <p>Số ngoại trú:</p>
          <p>Số lưu trữ:</p>
        </div>
      </div>
      <div className="col-100">
        <div className="col-100 flex" style={{ justifyContent: "space-between" }}>
          <p className="uppercase fs-16 bold">I. HÀNH CHÍNH:</p>
          <p className="" style={{ marginRight: 30 }}>Tuổi</p>
        </div>
        <div className="col-100 flex">
          <div className="col-50">
            <p>1. Họ và tên (In hoa): <span className="uppercase">{patient?.TEN}</span></p>
          </div>
          <div className="col-50 flex space-between">
            <p className="flex gap-10">2. Sinh ngày: <span className="flex">{renderNgaySinh()}</span></p>
            <div className="flex gap-10">
              {renderTuoi()}
            </div>
          </div>
        </div>

        <div className="col-100 flex" style={{ paddingTop: 6 }}>
          <div className="col-50 flex wrap-gioi-tinh">
            <p>3. Giới:</p>
            <div className="col-100 flex group-gioi-tinh">
              <div className="flex"><p>1. Nam </p> <div className="box"> {patient?.GIOI_TINH == "nam" ? "✔" : ""}  </div></div>
              <div className="flex"><p>2. Nữ </p> <div className="box">{patient?.GIOI_TINH == "nu" ? "✔" : ""}</div></div>
            </div>
          </div>
          <div className="col-50 flex space-between">
            <p>4. Nghề nghiệp: {patient?.NGHE_NGHIEP || ""}</p>
            <div className="flex gap-10">
              <div className="box"></div>
              <div className="box"></div>
            </div>
          </div>
        </div>

        <div className="col-100 flex" style={{ paddingTop: 6 }}>
          <div className="col-50">
            <p>5. Dân tộc: {patient?.DAN_TOC || " "}</p>
          </div>
          <div className="col-50 flex  space-between">
            <p>6. Ngoại kiều:</p>
            <div className="flex gap-10">
              <div className="box"></div>
              <div className="box"></div>
            </div>
          </div>


        </div>

        <div className="col-100">
          <p>7. Địa chỉ: {renderDiaChi()}</p>
        </div>
        <div className="col-100 flex">
          <span>8. Nơi làm việc:</span>
        </div>
        <div className="col-100 flex gap-10">
          <p>9. Đối tượng:</p>
          <div className="flex gap-10 flex-wrap">
            <div className="flex gap-10 ">
              <p>1. BHYT </p> <div className="box"></div>
            </div>
            <div className="flex gap-10 ">
              <p>2. Thu phí </p> <div className="box"></div>
            </div>
            <div className="flex gap-10 ">
              <p>3. Miễn phí </p> <div className="box"></div>
            </div>
            <div className="flex gap-10 ">
              <p>4. Khác </p> <div className="box"></div>
            </div>
          </div>
        </div>
        <div className="col-100 flex" style={{ paddingTop: 6 }}>
          <div className="col-50" >
            <p>10. BHYT giá trị đến ngày .......tháng...... năm </p>
          </div>
          <div className="col-50 flex">
            <p>Số thẻ BHYT</p>
            <div className="flex" style={{ border: "1px solid #333" }}>
              <div className="box" style={{ border: "none" }}></div>
              <div className="box" style={{ border: "none" }}></div>
              <div className="box" style={{ border: "none" }}></div>

              <span></span>
              <div className="box" style={{ border: "none", borderLeft: "1px solid #333" }} ></div>
              <div className="box" style={{ border: "none" }}></div>

              <span></span>
              <div className="box" style={{ border: "none", borderLeft: "1px solid #333" }} ></div>
              <div className="box" style={{ border: "none" }}></div>
              <div className="box" style={{ border: "none" }}></div>
              <span></span>
              <div className="box" style={{ border: "none", borderLeft: "1px solid #333" }}></div>
              <div className="box" style={{ border: "none" }}></div>
              <div className="box" style={{ border: "none" }}></div>
              <div className="box" style={{ border: "none" }}></div>
              <div className="box" style={{ border: "none" }}></div>

            </div>
          </div>
        </div>

        <div className="col-100">
          <p>11. Họ tên, địa chỉ người nhà khi cần báo tin: {renderDiaChiNguoiThan()}</p>
        </div>
        <div className="col-100">
          <p>12. Đến khám bệnh lúc: {renderTimeTiepDon()}</p>
        </div>
        <div className="col-100 flex">
          <div className="col-70 flex"><span>13. Chẩn đoán của nơi giới thiệu:</span>{lineDot()}</div>
          <div className="col-30 flex gap-10">
            <div className="flex gap-10 ">
              <p>1. Y tế </p> <div className="box"></div>
            </div>
            <div className="flex gap-10 ">
              <p>2. Tự đến </p> <div className="box"></div>
            </div>


          </div>
        </div>
      </div>
      <div className="col-100">
        <div className="col-100">
          <p className="flex"><span className="uppercase fs-16 bold">II. LÝ DO VÀO VIỆN:</span> {getLyDoKham()}</p>
        </div>
      </div>
      <div className="col-100">
        <p className="uppercase fs-16 bold">III.	HỎI BỆNH: </p>

        <p><span className="bold">1. Quá trình bệnh lý:</span> <span>{services?.filter(objFilter => objFilter?.PHAN_LOAI == "KHAM_BENH")?.filter(dataFilter => dataFilter?.QUA_TRINH_BENH_LY?.length > 0)?.map(detailServices => detailServices?.QUA_TRINH_BENH_LY || "").join(", ")}</span></p>
        <p><span className="bold">2. Tiền sử bệnh:</span> </p>
        {getTienSuBenh("TIEN_SU_BENH")?.length > 0 ? <p>- <span className="bold">Tiền sử bệnh bản thân:</span> {getTienSuBenh("TIEN_SU_BENH")}</p> : ""}
        {getTienSuBenh("TIEN_SU_SAN_KHOA")?.length > 0 ? <p>- <span className="bold">Tiền sử sản khoa:</span> {getTienSuBenh("TIEN_SU_SAN_KHOA")}</p> : ""}
        {getTienSuBenh("TIEN_SU_DI_UNG")?.length > 0 ? <p>- <span className="bold">Tiền sử dị ứng:</span> {getTienSuBenh("TIEN_SU_DI_UNG")}</p> : ""}
        {getTienSuBenh("TAI_NAN_THUONG_TICH")?.length > 0 ? <p>- <span className="bold">Tai nạn thương tích:</span> {getTienSuBenh("TAI_NAN_THUONG_TICH")}</p> : ""}
        {getTienSuBenh("TIEN_SU_GIA_DINH")?.length > 0 ? <p>- <span className="bold">Tiền sử bệnh gia đình:</span> {getTienSuBenh("TIEN_SU_GIA_DINH")}</p> : ""}
        {getTienSuBenh("TIEN_SU_BENH_KHAC")?.length > 0 ? <p>- <span className="bold">Tiền sử bệnh khác:</span> {getTienSuBenh("TIEN_SU_BENH_KHAC")}</p> : ""}

      </div>
      <div className="col-100">
        <p className="uppercase fs-16 bold">IV.	KHÁM BỆNH:</p>
      </div>
      <div className="col-100 flex">
        <div style={{ flexGrow: 1 }}>
          <p><span className="bold">1. Toàn thân:</span> <span>{services?.filter(objFilter => objFilter?.PHAN_LOAI == "KHAM_BENH")?.filter(dataFilter => dataFilter?.TOAN_THAN?.length > 0)?.map(detailServices => detailServices?.TOAN_THAN || "").join(", ")}</span></p>
          <p><span className="bold">2. Các bộ phận:</span> <span>{services?.filter(objFilter => objFilter?.PHAN_LOAI == "KHAM_BENH")?.filter(dataFilter => dataFilter?.CAC_BO_PHAN?.length > 0)?.map(detailServices => detailServices?.CAC_BO_PHAN || "").join(", ")}</span></p>
          <p><span className="bold">3. Tóm tắt kết quả cận lâm sàng:</span>  <span>{tomTatKetQuaCLS()}</span></p>
          <p><span className="bold">4. Chẩn đoán ban đầu:</span> <span>{chanDoanBanDau()}</span></p>
          <p><span className="bold">5. Đã xử lý:</span>  <span>{huongXuTri()}</span></p>
          <p><span className="bold">6. Chẩn đoán khi ra viện :</span> <span>{getICDBenhChinh()}</span> </p>
          <p><span className="bold">7. Điều trị ngoại trú từ ngày</span> {renderTimeShort("NGAY_TIEP_DON")} đến ngày {renderTimeShort("NGAY_KET_THUC")} </p>

        </div>

        <div className="box-sinh-ton" >
          <p className="flex space-between"><span>Mạch</span><span>{stats?.[0]?.MACH || ""}</span><span>lần/ph</span></p>
          <p className="flex space-between"><span>Nhiệt độ</span><span>{stats?.[0]?.NHIET_DO || ""}</span><span>°C</span></p>
          <p className="flex space-between"><span>Huyết áp</span><span>{stats?.[0]?.TAM_THU || ""}/{stats?.[0]?.TAM_TRUONG || ""}</span><span>mmHg</span></p>
          <p className="flex space-between"><span>Nhịp thở</span><span>{stats?.[0]?.NHIP_THO || ""}</span><span>lần/ph</span></p>
          <p className="flex space-between"><span>Cân nặng</span><span>{stats?.[0]?.CAN_NANG || ""}</span><span>kg</span></p>
        </div>
      </div>
      <div className="col-100 flex" style={{ marginTop: 20 }}>
        <div className="col-50"></div>
        <div className="col-50 text-center">Ngày ....... tháng ........ năm .........</div>
      </div>
      <div className="col-100 flex">
        <div className="col-50 text-center">Giám đốc bệnh viện</div>
        <div className="col-50 text-center">Bác sỹ khám bệnh</div>
      </div>
      <div className="page-2" style={{ height: "100%", width: "100%" }}>
        <p className="col-100 fs-18 uppercase bold">TỔNG KẾT BỆNH ÁN:</p>
        <div style={{ height: "100%", width: "100%" }}>
          <p><span className="bold">1. Quá trình bệnh lý và diễn biến lâm sàng:</span> <span>{getQuaTrinhBenhLy()}</span> </p>
          <p> <span className="bold">2. Tóm tắt kết quả xét nghiệm cận lâm sàng có giá trị chẩn đoán:</span> <span>{getKetLuanXn()}</span>  </p>
          <p ><span className="bold">3. Chẩn đoán ra viện:</span> </p>
          <p>- Bệnh chính: <span>{getICDBenhChinh()}</span> </p>
          <p>- Bệnh kèm theo (nếu có): <span>{getICDBenhPhu()}</span>  </p>
          <p ><span className="bold">4. Phương pháp điều trị:</span></p>
          <p className="flex"><span style={{ opacity: 0 }}>l</span>{lineDot2()}</p>
          <p className="flex"><span style={{ opacity: 0 }}>l</span>{lineDot2()}</p>
          <p className="flex"><span style={{ opacity: 0 }}>l</span>{lineDot2()}</p>
          <p className="flex"><span style={{ opacity: 0 }}>l</span>{lineDot2()}</p>
          <p ><span className="bold">5. Tình trạng người bệnh ra viện:</span>  </p>
          <p className="flex"><span style={{ opacity: 0 }}>l</span>{lineDot2()}</p>
          <p className="flex"><span style={{ opacity: 0 }}>l</span>{lineDot2()}</p>
          <p className="flex"><span style={{ opacity: 0 }}>l</span>{lineDot2()}</p>
          <p className="flex"><span style={{ opacity: 0 }}>l</span>{lineDot2()}</p>
          <p ><span className="bold">6. Hướng điều trị và các chế độ tiếp theo:</span>  </p>
          <p className="flex"><span style={{ opacity: 0 }}>l</span>{lineDot2()}</p>
          <p className="flex"><span style={{ opacity: 0 }}>l</span>{lineDot2()}</p>
          <p className="flex"><span style={{ opacity: 0 }}>l</span>{lineDot2()}</p>
          <p className="flex"><span style={{ opacity: 0 }}>l</span>{lineDot2()}</p>

          <table style={{ width: "100%" }}>
            <tr >
              <th colspan="2" style={{ width: "50%" }}  >Hồ sơ, phim, ảnh</th>
              <th rowspan="4" style={{ width: "25%" }} >
                <div style={{ height: "100px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "space-between" }}>
                  <p>Người giao hồ sơ:</p>
                  <p>Họ tên:................</p>
                </div>
              </th>
              <th rowspan="8" style={{ width: "25%" }} >
                <div style={{ height: "200px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "space-between" }}>
                  <p>Ngày…	tháng……năm…… <br />
                    Bác sỹ điều trị
                  </p>
                  <p>Họ tên:................</p>
                </div>
              </th>
            </tr>
            <tr>
              <th style={{ width: "25%" }} >Loại</th>
              <th style={{ width: "25%" }} >Số tờ</th>
            </tr>
            <tr>
              <th style={{ textAlign: "left" }} >- X - quang</th>
              <th ></th>
            </tr>
            <tr>
              <th style={{ textAlign: "left" }} >- CT Scanner</th>
              <th ></th>
            </tr>
            <tr>
              <th style={{ textAlign: "left" }} >- Siêu âm</th>
              <th ></th>
              <th rowspan="4">
                <div style={{ height: "100px", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "space-between" }}>
                  <p>Người nhận hồ sơ:</p>
                  <p>Họ tên:................</p>
                </div>
              </th>
            </tr>
            <tr>
              <th style={{ textAlign: "left" }} >- Xét nghiệm</th>
              <th ></th>
            </tr>
            <tr>
              <th style={{ textAlign: "left" }} >- Khác……………</th>
              <th ></th>
            </tr>
            <tr>
              <th style={{ textAlign: "left" }} >- Toàn bộ hồ sơ</th>
              <th ></th>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
};

export default InBenhAnNgoaiTru;
