import React from 'react'
import style from './style.module.less';
import i18n,{ languageKeys } from '../../i18n';
import { useState } from 'react';
import { Select, Pagination as AntdPagination } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { PAGE_SIZE_OPTIONS } from 'constants/keys';
import cn from 'classnames';

const Pagination = ({total, currentPage=1, onChangePage={}, onChangePageSize = () => {}, pageSize = PAGE_SIZE_OPTIONS[0], hideOnSinglePage=true, showSizeChanger=true, className}) => {
    const renderPageItem = (_, type, originalNode) => {
        if(type === 'prev') return (
            <span>
                <LeftOutlined style={{marginRight: '6px'}}/><span className={style['text--fade']}>{i18n.t(languageKeys.truoc)}</span>
            </span>
        )
        if(type === 'next') return (
            <span>
                <span className={style['text--fade']}>{i18n.t(languageKeys.tiep)}</span><RightOutlined style={{marginLeft: '6px'}}/>
            </span>
        )
        return originalNode
    }

  return (
    !!total ? (
        <div className={cn(style['pagination'], className)}>
            <div className={style['size-changer__container']}>
                {
                    (!!showSizeChanger) ? (
                        <>
                            <span className={style['text--fade']}>{i18n.t(languageKeys.hien_thi)}</span>
                            <Select
                                className={style['size-select']}
                                options={[
                                    {
                                        label: PAGE_SIZE_OPTIONS[0],
                                        value: PAGE_SIZE_OPTIONS[0]
                                    },
                                    {
                                        label: PAGE_SIZE_OPTIONS[1],
                                        value: PAGE_SIZE_OPTIONS[1]
                                    },
                                    {
                                        label: PAGE_SIZE_OPTIONS[2],
                                        value: PAGE_SIZE_OPTIONS[2]
                                    },
                                    {
                                        label: PAGE_SIZE_OPTIONS[3],
                                        value: PAGE_SIZE_OPTIONS[3]
                                    }
                                ]}
                                onChange={onChangePageSize} 
                                value={pageSize}
                            />
                            <span className={style['text--fade']}>{(i18n.t(languageKeys.on_one_page).toLowerCase())}</span>
                        </>
                    ) : <></>
                }
            </div>
    
            <div className={style['pagination-action']}>
                <AntdPagination
                    showSizeChanger={false}
                    defaultCurrent={1}
                    total={total}
                    current={currentPage}
                    hideOnSinglePage={hideOnSinglePage}
                    itemRender={renderPageItem}
                    onChange={onChangePage}
                    pageSize={pageSize}
                />
            </div>
        </div>
    ) : <></>
)
}

export default Pagination