import JsBarcode from "jsbarcode";
import moment from "moment";
import React, { useEffect } from "react";
import i18n, { languageKeys, languages } from "../../../../i18n";
import { useSelector } from "react-redux";
import { formatNumberToPrice, convertDateToValue, getSexFromString } from "helpers";
import Images from "components/Images/Images";

export const styleBangKeCongNoGreenLab = `
#bang-ke-cong-no-greenLab * { font-size: 14pt; font-family: "Times New Roman", Times, serif; }
#bang-ke-cong-no-greenLab .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#bang-ke-cong-no-greenLab .header { display: flex; gap: 10px; position:relative; font-size: 14pt;}
#bang-ke-cong-no-greenLab .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 16pt; }
#bang-ke-cong-no-greenLab .title { text-transform: uppercase; font-size: 16pt; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto;}
#bang-ke-cong-no-greenLab .subtitle { margin-block: 0.25rem; font-size: 14pt; font-weight: bold; }
#bang-ke-cong-no-greenLab .table * { line-height: 1.2em; }
#bang-ke-cong-no-greenLab .table { page-break-inside:auto; }
#bang-ke-cong-no-greenLab tr    { page-break-inside:avoid; page-break-after:auto; }
#bang-ke-cong-no-greenLab thead { display:table-header-group }
#bang-ke-cong-no-greenLab tfoot { display:table-footer-group }
#bang-ke-cong-no-greenLab .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#bang-ke-cong-no-greenLab .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#bang-ke-cong-no-greenLab .table th, .table td { padding: 5px; }
#bang-ke-cong-no-greenLab .table th { text-align: start; }
#bang-ke-cong-no-greenLab .noBorder2 { border-right: none; }
#bang-ke-cong-no-greenLab .noBorder { border-left: none; }
#bang-ke-cong-no-greenLab .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#bang-ke-cong-no-greenLab .avatar { height: 80px; width: 80px; padding-right: 15px; }
#bang-ke-cong-no-greenLab .anotherFoot { display: flex; padding-top: 10px; }
#bang-ke-cong-no-greenLab .anotherFoot > div { width: 50%; text-align: center; }
#bang-ke-cong-no-greenLab .des { font-style: italic; }
#bang-ke-cong-no-greenLab .sub-barcode-text { font-size:14pt;position:absolute;top:50px; left:50%; line-height: 1.2em; transform: translateX(-50%);}
#bang-ke-cong-no-greenLab .phone-website { display: flex; gap: 24px; align-items: center }
@media print {
   #bang-ke-cong-no-greenLab, #bang-ke-cong-no-greenLab .foot-break { break-inside: avoid; }
   #bang-ke-cong-no-greenLab{page-break-after: always;}
}
`;

const BangKeCongNoGreenLab = ({ data = { benh_nhan: {}, nhom_dv: [], hospital: {}, nhom: "" }, rid }) => {

  const userProfile = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (!!rid && !!data.benh_nhan.ma_benh_nhan) {
      JsBarcode(`#barcode-${rid}`)
        .options({ font: "OCR-B" })
        .CODE128(data.benh_nhan.ma_benh_nhan, {
          text: ` `,
          fontSize: 14,
          width: 1.2,
          height: 40,
          textMargin: 0,
          margin: 0,
          padding: 0,
        })
        .render();
    }
  }, [data.benh_nhan.ma_benh_nhan, rid]);

  return (
    <div id="bang-ke-cong-no-greenLab">
      <div className="header">
        {data.hospital.avatar && <Images className="logo" imgId={data.hospital.avatar}/>}

        <div style={{ display: "flex", gap: '80px', justifyContent: "space-between", width: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <div className="hospital-name">{data.hospital.name}</div>
            <div>
              <span className="txt-700">{i18n.t(languageKeys.field_Dia_chi)}</span>: {data.hospital.address}
            </div>
            <div className="phone-website">
              <div>
                <span className="txt-700">{i18n.t(languageKeys.dien_thoai)}</span>: {data.hospital.phone}
              </div>
            </div>
            <div>
              <span className="txt-700">Website</span>: {userProfile?.benh_vien?.WEBSITE || ''}
            </div>
            <div>
              <span className="txt-700">Email</span>: {userProfile?.benh_vien?.EMAIL || ''}
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', textAlign: 'center', position: 'relative', minWidth: '150px' }}>
            <svg id={`barcode-${rid}`}></svg>
            <div className="sub-barcode-text">
              <div style={{ width: 300, textAlign: 'center' }}>
                {i18n.t(languageKeys.ma_kh)}:<b> {data.benh_nhan.ma_benh_nhan}</b>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="title">{i18n.t(languageKeys.bang_ke_cong_no)}</div>

      <div className="subtitle">{i18n.t(languageKeys.thong_tin_khach_hang)}</div>

      <div>

        <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
          <div>
            {" "}
            {i18n.t(languageKeys.ho_va_ten)}: <b> {data.benh_nhan.ten_benh_nhan}</b>
          </div>
          <div>
            {" "}
            {i18n.t(languageKeys.field_Ngay_sinh)}: {data.benh_nhan?.ngay_sinh.split("/").pop()}
          </div>
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
          <div>
            {" "}
            {i18n.t(languageKeys.field_So_dien_thoai)}: {data.benh_nhan.sdt}
          </div>
          <div>
            {" "}
            {i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(data.benh_nhan.gioi_tinh)}
          </div>
        </div>

        <div>
          {" "}
          {i18n.t(languageKeys.dia_chi)}: {data.benh_nhan.dia_chi?.slice(-1) === ","
            ? data.benh_nhan.dia_chi.slice(0, -1).replaceAll(",", ", ")
            : data.benh_nhan.dia_chi.replaceAll(",", ", ")
          }
        </div>

        <div style={{ display: "grid", gridTemplateColumns: "40% 60%" }}>
          <div>
            {" "}
            {i18n.t(languageKeys.field_Ngay_kham)}: {convertDateToValue(data.thong_tin_kham.ngay_kham)}
          </div>
          <div>
            {" "}
            {i18n.t(languageKeys.tong_thanh_toan)}: {formatNumberToPrice(data.tong_can_thanh_toan, 'đ')}
          </div>
        </div>

        <div>
          {" "}
          {i18n.t(languageKeys.field_Ly_do_mien_giam)} : {data.thong_tin_kham.ly_do_mien_giam}
        </div>

        <table style={{ width: "100%" }} className="table">
          <thead>
            <tr>
              <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.stt)}</th>
              <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_thoi_gian)}</th>
              <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.loai_phieu)}</th>
              <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.tong_thanh_toan)}</th>
              <th align="center" style={{ textAlign: 'center' }}>{i18n.t(languageKeys.no_can_tra)}</th>
            </tr>
          </thead>

          <tbody>
            {data.danh_sach_thu_no?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td style={{ textAlign: 'center' }}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</td>
                    <td>{item.GIO} {convertDateToValue(item.NGAY)}</td>
                    <td>{i18n.t(languageKeys.phieu_thu)}</td>
                    <td style={{ textAlign: "end" }}>{formatNumberToPrice(item.DA_THANH_TOAN)}</td>
                    <td style={{ textAlign: "end" }}>{formatNumberToPrice(item.DU_NO)}</td>
                  </tr>
                </React.Fragment>
              );
            })}
          </tbody>
        </table>

        <div className="foot-break">
          <div style={{ display: "flex", justifyContent: "space-between", margin: '15px 0 12px' }}>
            <div>
              <b>{i18n.t(languageKeys.tong_can_thanh_toan)}: </b>
              <b>{formatNumberToPrice(data.tong_can_thanh_toan, 'đ')}</b>
            </div>
            <div>
              <b>{i18n.t(languageKeys.vienphi_da_tt)}: </b>
              <b>{formatNumberToPrice(data.tong_da_thanh_toan, 'đ')}</b>
            </div>
            <div>
              <b>{i18n.t(languageKeys.so_du_cong_no)}: </b>
              <b>{formatNumberToPrice(data.du_no_con_lai, 'đ')}</b>
            </div>
          </div>
        </div>

      </div>

      <div className="foot-break" style={{ marginTop: '20px' }}>

        <div className="anotherFoot">
          <div style={{ marginTop: 40 }}>
            <b style={{ fontSize: "14pt" }}>{i18n.t(languageKeys.khach_hang)}</b>
            <div className="des">({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</div>
            <div style={{ marginTop: 80, fontWeight: "bold" }}>{data.benh_nhan.ten_benh_nhan}</div>
          </div>

          <div>
            <div style={{ display: "flex", justifyContent: "center", }}>
              {data?.ngay_tao ?
                <i>
                  {data.gio ? data.gio + ", " : ""}{data.ngay_tao}
                </i>
                :
                <i
                  style={{
                    textAlign: "center",
                    marginTop: 15,
                  }}
                >
                  {i18n.language === languages.tieng_viet ? (
                    <i>
                      {moment().format("HH:mm") + ", "}
                      Ngày {moment().date()}, tháng {moment().month() + 1}, năm {moment().year()}
                    </i>
                  ) : (
                    <i> {moment().format("HH:mm , MMMM D, YYYY")}</i>
                  )}
                </i>}
            </div>
            <b style={{ fontSize: "14pt" }}>{i18n.t(languageKeys.field_nguoi_ban_thuoc)}</b>
            <div className="des">({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</div>
            <div style={{ marginTop: 80, fontWeight: "bold" }}>{data?.thu_ngan || ""}</div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default BangKeCongNoGreenLab;
