import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    open: false,
    data : {},
    loading: false,
    err:undefined,
    history: []
};

const chatAiSlice = createSlice({
    name: 'chatAiSlice',
    initialState,
    reducers : {
        doAsk : () => {},
        setOpen: (state,{ payload })  => {
            state.open = payload;
        },
        setData: (state,{ payload }) => {
            state.data = payload;
        },
        setLoading: (state,{ payload }) => {
            state.loading = payload;
        },
        setError: (state,{ payload }) => {
            state.err = payload;
        },
        updateHistory: (state,{ payload }) => {
            state.history = [...state.history, payload];
        },
        setHistory:(state,{ payload }) => {state.history = payload},
        resetSlice: () => {
            return initialState;
        }
    }
});

export const chatAiActions = chatAiSlice.actions;
export const chatAiState = (state) => state.ChatAiStore;
export default chatAiSlice.reducer;
