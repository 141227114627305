import {combineReducers} from '@reduxjs/toolkit'
import PacketServiceListSlice from './DSGoiDichVu/DSGoiDichVuSlice'
import SignUpForAServicePack from "./DKGoiDichVu/DKGoiDichVuSlice"
import {serivcePackageUsageSlice, servicePackageUsageInfoSlice, detailPackageUsageSlice, PacketUsageHistorySlice, ExamHistorySlice, PrescriptionByExaminationSlice, PackageUsagePaymentHistorySlice} from "./ThongTinSDGoiDv"
import ServicePackType from "./LoaiGoiDvSlice";
import GoiVatTuSlice from './GoiVatTu.slice';

const PacketServiceReducer = combineReducers({
    PacketServiceList: PacketServiceListSlice,
    SignUpForAServicePack: SignUpForAServicePack,
    ServicePackType:ServicePackType,
    ServicePacketUsage: serivcePackageUsageSlice,
    servicePackageUsageInfo: servicePackageUsageInfoSlice,
    detailPackageUsage: detailPackageUsageSlice,
    PacketUsageHistorySlice: PacketUsageHistorySlice,
    ExamHistory: ExamHistorySlice,
    PrescriptionByExamination: PrescriptionByExaminationSlice,
    PackageUsagePaymentHistory:PackageUsagePaymentHistorySlice,
    GoiVatTu: GoiVatTuSlice
})

export default PacketServiceReducer;