import { put, putResolve, select } from 'redux-saga/effects';
import { setSelectLoading, setTableLoading } from 'ducks/slices/loadingSlice';
import { takeLatest } from '@redux-saga/core/effects';
import {
    doFetchBook, doFetchCustomerTarget, doFetchPaymentMethod, doFetchPersonnel,
    fetchPaymentMethodSuccess, fetchPersonnelSuccess, fetchTargetSuccess, doFetchServiceTypes,
    fetchBookSuccess, doFetchListSample, fetchListSampleSuccess, doFetchSourceClient,
    fetchSourceClientSuccess, doFetchUnit, doFetchRouteOfUse, doFetchUsage, fetchUnitSuccess,
    fetchRouteOfUseSuccess, fetchUsageSuccess, doFetchSupplyStorage, fetchSupplyStorageSuccess,
    doFetchPrescriptionForm,
    fetchPrescriptionFormSuccess,
    doFetchManual,
    fetchManualSuccess,
    doFetchTransferRate,
    fetchTransferRateSuccess
} from 'ducks/slices/ListData/SharedData.slice';
import { common_post } from 'helpers';
import { apis } from "../../../constants";
import { updateLoaiDichVu } from 'ducks/slices/dataSlice';
import { getUser } from 'ducks/slices/authSlice';

export function* watchDoSharedData() {
    yield takeLatest(doFetchPersonnel, handleFetchPersonnel)
    yield takeLatest(doFetchPaymentMethod, handleFetchPaymentMethod)
    yield takeLatest(doFetchBook, handleFetchBook)
    yield takeLatest(doFetchCustomerTarget, handleFetchCustomerTarget)
    yield takeLatest(doFetchServiceTypes, handleFetchServiceTypes)
    yield takeLatest(doFetchListSample, handleFetchListSample)
    yield takeLatest(doFetchSourceClient, handleFetchSourceClient)
    yield takeLatest(doFetchUnit, handleFetchUnit)
    yield takeLatest(doFetchRouteOfUse, handleFetchRouteOfUse)
    yield takeLatest(doFetchUsage, handleFetchUsage)
    yield takeLatest(doFetchSupplyStorage, handleFetchSupplyStorage)
    yield takeLatest(doFetchManual, handleFetchManual)
    yield takeLatest(doFetchPrescriptionForm, handleFetchPrescriptionForm)
    yield takeLatest(doFetchTransferRate, handleFetchTransferRate)
}

function* handleFetchPersonnel(action) {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.dm_lay_ds_user, {
            limit: 6969,
            search_string: action.payload?.search_string || "",
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchPersonnelSuccess(res));
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchPaymentMethod() {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.dm_lay_hinh_thuc_thanh_toan, {
            search_string: '',
            is_get_all: 0,
            page: 1,
            limit: 999999
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchPaymentMethodSuccess(res));
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchBook(action) {
    const userProfile = yield select(getUser)
    const { LOAI_SO = null, ARR_LOAISO = [] } = action.payload;
    let payload = {
        LOAI_SO,
        search_string: "",
        limit: 1000,
        page: 1,
        NHANSU_ID: userProfile.NHANSU_ID,
        ARR_LOAISO
    }
    if (ARR_LOAISO?.length === 0 && LOAI_SO) {
        payload.ARR_LOAISO = [LOAI_SO]
    }
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.lay_ds_so_hoa_don_co_phan_quyen, payload)
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchBookSuccess({ data: res.result, type: LOAI_SO }));
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchCustomerTarget() {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.dm_lay_ds_doi_tuong_hieu_luc, {
            page: 1,
            limit: 999999
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchTargetSuccess(res));
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchServiceTypes() {
    try {
        const userProfile = yield select((state) => state.auth.user);
        let body = {
            NHANSU_ID: userProfile.NHANSU_ID,
            partner_code: userProfile.partner_code,
            page: 1,
            search_string: "",
            limit: 60,
        };
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.dm_loai_dvkt_lay_ds_co_hieu_luc, body);
        if (res && res.status === "OK") {
            // gửi dịch vụ sang slice dữ liệu
            yield putResolve(updateLoaiDichVu(res.result));
        }
    } catch (error) {
        console.log(error.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchListSample() {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.lay_ds_mau_mo_ta_cdha, {
            page: 1,
            limit: 999999
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchListSampleSuccess(res));
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchSourceClient() {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.dm_lay_ds_nguon_khach_hieu_luc, {
            page: 1,
            limit: 9999
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchSourceClientSuccess(res));
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchUnit() {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.dm_don_vi_tinh_lay_ds, {
            page: 1,
            limit: 99999,
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchUnitSuccess(res));
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchRouteOfUse() {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.dm_lay_ds_duong_dung, {
            page: 1,
            limit: 99999,
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchRouteOfUseSuccess(res));
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchUsage() {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.dm_lay_ds_cach_dung_hieu_luc, {
            page: 1,
            limit: 99999,
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchUsageSuccess(res));
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchSupplyStorage() {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.dm_lay_ds_kho_vat_tu, {
            page: 1,
            limit: 99999,
            KHOA_KHO: true,
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchSupplyStorageSuccess(res));
            }
        }
    } catch (err) {
        if (err instanceof Error) console.log(err.message)
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchManual() {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.layHDSD, {
            page: 1,
            limit: 99999,
            is_get_all: 0,
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchManualSuccess(res));
            }
        }
    } catch (err) {
        if (err instanceof Error) console.log(err.message)
    } finally {
        yield put(setSelectLoading(false));
    }
}

function* handleFetchPrescriptionForm() {
    try {
        yield put(setTableLoading(true));
        const res = yield common_post(apis.layDsMauDon)
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchPrescriptionFormSuccess(res.result?.find(i => i.ACTIVE)?.ID || 1));
            }
        }
    } catch (err) {
        if (err instanceof Error) console.log(err.message)
    } finally {
        yield put(setTableLoading(false));
    }
}

function* handleFetchTransferRate() {
    try {
        yield put(setSelectLoading(true));
        const res = yield common_post(apis.layDmTocDoTruyen, {
            page: 1,
            limit: 99999,
            is_get_all: 0,
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(fetchTransferRateSuccess(res));
            }
        }
    } catch (err) {
        if (err instanceof Error) console.log(err.message)
    } finally {
        yield put(setSelectLoading(false));
    }
}