import { memo } from "react";
import { useSelector } from "react-redux";
import { convertGender } from "../../../helpers";
import i18n, { languageKeys, languages } from "../../../i18n";
import moment from "moment";
import JsBarcode from "jsbarcode";
import { useEffect } from "react";
import QRCode from "react-qr-code";
import { OnCareQR } from "assets/img";
import Images from "components/Images/Images";
import { enDateFormat } from "constants/keys";

export const stylePhieuTraKqPdf = `
#phieu-container .maumota-content, 
#phieu-container .maumota-content * {
  font-size: 12px;
}
#phieu-container .maumota-content sub,
#phieu-container .maumota-content sup {
  line-height: inherit;
  font-family: inherit;
}
#phieu-container .maumota-content span,
#phieu-container .maumota-content strong,
#phieu-container .maumota-content i,
#phieu-container .maumota-content u {
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
}
#phieu-container .maumota-content h2 {
  font-size: 20px;
  line-height: unset;
}
#phieu-container .maumota-content h3 {
  font-size: 18px;
  line-height: unset;
}
#phieu-container .maumota-content h4 {
  font-size: 16px;
  line-height: unset;
}
#phieu-container * {
  box-sizing: border-box;
}
#phieu-container p {
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}
#phieu-container figure > img {
  width: 100%;
  height: auto !important;
}

#phieu-container figure.image-style-side {
  float: right;
}
#phieu-container img {
  height: auto !important;
  max-width: 100% !important;
}
#phieu-container .image-inline {
  align-items: flex-start;
  display: inline-flex;
}
#phieu-container figure > * {
  width: 100%;
  height: auto;
}
#phieu-container figure {
  width: 100%;
  margin: auto;
}
#phieu-container .table {
  display: table;
}
#phieu-container figure.table table {
  width: 100%;
}

#phieu-container figure.table table td {
  min-width: 20%;
}

#phieu-container figure.table table td .image {
  margin: 0;
}
#phieu-container figure.table table td > p {
  font-size: 0px;
}
#phieu-container figure.table table td:not(:has(img)) > p {
  font-size: 12px;
}

#phieu-container table, 
#phieu-container .table {
  height: auto !important;
  max-width: 100% !important;
}
#phieu-container table, #phieu-container th, #phieu-container td {
  border-collapse: collapse;
}
#phieu-container .maumota-content tr {
  height: auto !important;
}
#phieu-container .maumota-content td {
  padding: 5px;
}

#phieu-container .maumota-content h1,
#phieu-container .maumota-content h2,
#phieu-container .maumota-content h3,
#phieu-container .maumota-content h4,
#phieu-container .maumota-content h5,
#phieu-container .maumota-content ul {
  margin: 0;
}
#phieu-container .maumota-content ul {
  margin-bottom: 5px;
}

#phieu-container-top *, .footer *, .footer ,#title_kl,#phieu-container-bottom *, .title-mo-ta {
  font-size: 12px;
  line-height: 1rem;
  font-family: "Times New Roman", Times, serif;
  page-break-inside: avoid !important;
  page-break-before: avoid !important;
}

#phieu-container .maumota-content, 
#phieu-container .maumota-content *,
#phieu-container .ket-luan-content *,
.maukythuat-content ,.maukythuat-content * {
  font-family: "Times New Roman", Times, serif;
  // page-break-inside: avoid !important;
  // page-break-before: avoid !important;
}

* {
  page-break-inside: avoid !important;
  page-break-before: avoid !important;
}

.fs12 { font-size: 12px; }

#phieu-container .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}

#phieu-container .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  gap: 40px;
  font-size: 10px;
}

#phieu-container .header--left {
  display: flex;
  gap: 10px;
  align-item: center;
  gap: 20px;
}

#phieu-container .header--right {
  display: flex;
  justify-content: end;
  position: relative;
  flex-direction: column ;
  align-items: center ;
  justify-content: center ;
  text-align:  center ;
  position:  relative ;
  minWidth:  150px ;
}

#phieu-container .sub-barcode-text{
  font-size:12px;
  position:absolute;
  top:50px;
  left:50%;
  line-height: 1.2em;
  transform: translateX(-50%);
}

#phieu-container .row-infomation {
  display: flex;
  flex: 1;
  gap: 20px;
  width : 100%;
}

#phieu-container .hospital-name {
    text-transform: uppercase;
    font-weight: bold;
}

#phieu-container .thong-tin-phieu {
    display: flex;
}

#phieu-container .title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 90%;
  margin: 2.5rem auto 1rem auto;
}
#phieu-container .title-info {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
}

#phieu-container .footer{
    margin-top:16px;
    text-align : center;
}

#phieu-container .qr-container{
  width: 140px;
  height: 140px;
  background-color: #fff;
}

#phieu-container .title-footer {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
    margin-top : 6px;
}
#phieu-container .date-footer {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

#phieu-container .row-anh{
    position: relative;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-between;
    width:100%;
    height:100%;
}


#phieu-container .ket-luan-content{
    margin: 6px 0;
    font-size:12px;
    font-weight: bold !important;
}

#phieu-container .horizontal_dotted_line {
    border-bottom: 1px dotted black;
    width: 100%;
    margin:24px 0 14px 0;
}

#phieu-container .title-content{
    font-weight:700;
    font-size:12px;
}

#phieu-container .maumota-content{
    h1,h2,h3,h4,h5{
        margin:0 !important;
    }
    table{
        page-break-before:none !important;
    }

}

#phieu-container .maumota-content > ul {
  margin:0px !important;
}

#phieu-container .maumota-content > p {
  margin: 0px !important;
  margin-bottom:0px !important;
  margin-top:0px !important;
}
#phieu-container .maumota-content > li {
  margin: 0px !important;
  margin-bottom:0px !important;
}

#phieu-container .image-kl{
    display:block;
    height:100%;
    align-items:center;
    align-self:flex-end;
}

#phieu-container .img-ket-qua{
    max-height:25vw;
    max-width:31vw;
    object-fit:contain;
}

#phieu-container .between-row{
    justify-content: space-between;
}

#phieu-container .evenly-row{
    justify-content: space-evenly;
}

#phieu-container table{
    border : none;
}
#phieu-container .bold-txt {
  font-weight: bold;
}

#phieu-container .info-group {
  display: flex;
  flex-direction: column;
  gap:2px;
  font-size: 12px;
}

#phieu-container .row-group_patient-info {
  display:flex;
}

.row-group-2 {
  width:50%;
}

.row-group-3 {
  width:25%;
}

#phieu-container .aivi_note { display: none; }

.title-height {
 height: 80px;
}

@media print {
    #phieu-container .foot-break { break-inside: avoid; }
    #phieu-container { page-break-before: always !important;}
}
`;


export const stylePhieuTraKqPdf_namAu_haThanh_248 = `
#phieu-container .maumota-content, 
#phieu-container .maumota-content * {
  font-size: 12px;
}
#phieu-container .maumota-content sub,
#phieu-container .maumota-content sup {
  line-height: inherit;
  font-family: inherit;
}
#phieu-container .maumota-content span,
#phieu-container .maumota-content strong,
#phieu-container .maumota-content i,
#phieu-container .maumota-content u {
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
}
#phieu-container .maumota-content h2 {
  font-size: 20px;
  line-height: unset;
}
#phieu-container .maumota-content h3 {
  font-size: 18px;
  line-height: unset;
}
#phieu-container .maumota-content h4 {
  font-size: 16px;
  line-height: unset;
}
#phieu-container * {
  box-sizing: border-box;
}
#phieu-container p {
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}
#phieu-container figure > img {
  width: 100%;
  height: auto !important;
}

#phieu-container figure.image-style-side {
  float: right;
}
#phieu-container img {
  height: auto !important;
  max-width: 100% !important;
}
#phieu-container .image-inline {
  align-items: flex-start;
  display: inline-flex;
}
#phieu-container figure > * {
  width: 100%;
  height: auto;
}
#phieu-container figure {
  width: 100%;
  margin: auto;
}
#phieu-container .table {
  display: table;
}
#phieu-container figure.table table {
  width: 100%;
}

#phieu-container figure.table table td {
  min-width: 20%;
}

#phieu-container figure.table table td .image {
  margin: 0;
}
#phieu-container figure.table table td > p {
  font-size: 0px;
}
#phieu-container figure.table table td:not(:has(img)) > p {
  font-size: 12px;
}

#phieu-container table, 
#phieu-container .table {
  height: auto !important;
  max-width: 100% !important;
}
#phieu-container table, #phieu-container th, #phieu-container td {
  border-collapse: collapse;
}
#phieu-container .maumota-content tr {
  height: auto !important;
}
#phieu-container .maumota-content td {
  padding: 5px;
}

#phieu-container .maumota-content h1,
#phieu-container .maumota-content h2,
#phieu-container .maumota-content h3,
#phieu-container .maumota-content h4,
#phieu-container .maumota-content h5,
#phieu-container .maumota-content ul {
  margin: 0;
}
#phieu-container .maumota-content ul {
  margin-bottom: 5px;
}

#phieu-container-top *, .footer *, .footer ,#title_kl,#phieu-container-bottom *, .title-mo-ta {
  font-size: 12px;
  line-height: 1rem;
  font-family: "Times New Roman", Times, serif;
  page-break-inside: avoid !important;
  page-break-before: avoid !important;
}

#phieu-container .maumota-content, 
#phieu-container .maumota-content *,
#phieu-container .ket-luan-content *,
.maukythuat-content ,.maukythuat-content * {
  font-family: "Times New Roman", Times, serif;
  // page-break-inside: avoid !important;
  // page-break-before: avoid !important;
}

* {
  page-break-inside: avoid !important;
  page-break-before: avoid !important;
}

.fs12 { font-size: 12px; }

#phieu-container .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}

#phieu-container .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
  gap: 40px;
  font-size: 10px;
}

#phieu-container .header--left {
  display: flex;
  gap: 10px;
  align-item: center;
  gap: 20px;
}

#phieu-container .header--right {
  display: flex;
  justify-content: end;
  position: relative;
  flex-direction: column ;
  align-items: center ;
  justify-content: center ;
  text-align:  center ;
  position:  relative ;
  minWidth:  150px ;
}

#phieu-container .sub-barcode-text{
  font-size:12px;
  position:absolute;
  top:50px;
  left:50%;
  line-height: 1.2em;
  transform: translateX(-50%);
}

#phieu-container .row-infomation {
  display: flex;
  flex: 1;
  gap: 20px;
  width : 100%;
}

#phieu-container .hospital-name {
    text-transform: uppercase;
    font-weight: bold;
}

#phieu-container .thong-tin-phieu {
    display: flex;
}

#phieu-container .title {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  width: 90%;
  margin: 2.5rem auto 1rem auto;
}
#phieu-container .title-info {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 13px;
}

#phieu-container .footer{
    margin-top:16px;
    text-align : center;
}

#phieu-container .qr-container{
  width: 140px;
  height: 140px;
  background-color: #fff;
}

#phieu-container .title-footer {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
    margin-top : 6px;
}
#phieu-container .date-footer {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

#phieu-container .row-anh{
    position: relative;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-between;
    width:100%;
    height:100%;
}


#phieu-container .ket-luan-content{
    margin: 6px 0;
    font-size:12px;
    font-weight: bold !important;
}

#phieu-container .horizontal_dotted_line {
   display: none;
}

#phieu-container .title-content{
    font-weight:700;
    font-size:12px;
}

#phieu-container .maumota-content{
    h1,h2,h3,h4,h5{
        margin:0 !important;
    }
    table{
        page-break-before:none !important;
    }

}

#phieu-container .maumota-content > ul {
  margin:0px !important;
}

#phieu-container .maumota-content > p {
  margin: 0px !important;
  margin-bottom:0px !important;
  margin-top:0px !important;
}
#phieu-container .maumota-content > li {
  margin: 0px !important;
  margin-bottom:0px !important;
}

#phieu-container .image-kl{
    display:block;
    height:100%;
    align-items:center;
    align-self:flex-end;
}

#phieu-container .img-ket-qua{
    max-height:25vw;
    max-width:31vw;
    object-fit:contain;
}

#phieu-container .between-row{
    justify-content: space-between;
}

#phieu-container .evenly-row{
    justify-content: space-evenly;
}

#phieu-container table{
    border : none;
}
#phieu-container .bold-txt {
  font-weight: bold;
}

#phieu-container .info-group {
  display: flex;
  flex-direction: column;
  gap:2px;
  font-size: 14px !important;
}


#phieu-container .row-group_patient-info {
  display:flex;
}

.row-group-2 {
  width:50%;
}

.row-group-3 {
  width:25%;
}

#phieu-container .aivi_note { display: none; }

.title-height {
 height: 80px;
}


#phieu-container .info-group *, .thong-tin-pk *, .footer *, .title-mo-ta, .title_kl , .title-mo-ta * , .title *, .tt-hc, .tt-hc * {
  line-height: 1.2;
  font-size: 14pt !important;
}


 .title_kl *, .title {
  line-height: 1.2;
  font-size: 16pt !important;
}


@media print {
    #phieu-container .foot-break { break-inside: avoid; }
    #phieu-container { page-break-before: always !important;}
}
`;

export const stylePhieuTraKqPdfGL = `
#phieu-container .maumota-content, 
#phieu-container .maumota-content * {
  font-size: 14pt;
}
#phieu-container .maumota-content sub,
#phieu-container .maumota-content sup {
  line-height: inherit;
  font-family: inherit;
}
#phieu-container .maumota-content span,
#phieu-container .maumota-content strong,
#phieu-container .maumota-content i,
#phieu-container .maumota-content u {
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
}
#phieu-container .maumota-content h2 {
  font-size: 20px;
  line-height: unset;
}
#phieu-container .maumota-content h3 {
  font-size: 18px;
  line-height: unset;
}
#phieu-container .maumota-content h4 {
  font-size: 16px;
  line-height: unset;
}
#phieu-container * {
  box-sizing: border-box;
}
#phieu-container p {
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}
#phieu-container figure > img {
  width: 100%;
  height: auto !important;
}

#phieu-container figure.image-style-side {
  float: right;
}
#phieu-container img {
  height: auto !important;
  max-width: 100% !important;
}
#phieu-container .image-inline {
  align-items: flex-start;
  display: inline-flex;
}
#phieu-container figure > * {
  width: 100%;
  height: auto;
}
#phieu-container figure {
  width: 100%;
  margin: auto;
}
#phieu-container .table {
  display: table;
}
#phieu-container figure.table table {
  width: 100%;
}

#phieu-container figure.table table td {
  min-width: 20%;
}

#phieu-container figure.table table td .image {
  margin: 0;
}
#phieu-container figure.table table td > p {
  font-size: 0px;
}
#phieu-container figure.table table td:not(:has(img)) > p {
  font-size: 14pt;
}

#phieu-container table, 
#phieu-container .table {
  height: auto !important;
  max-width: 100% !important;
}
#phieu-container table, #phieu-container th, #phieu-container td {
  border-collapse: collapse;
}
#phieu-container .maumota-content tr {
  height: auto !important;
}
#phieu-container .maumota-content td {
  padding: 5px;
}

#phieu-container .maumota-content h1,
#phieu-container .maumota-content h2,
#phieu-container .maumota-content h3,
#phieu-container .maumota-content h4,
#phieu-container .maumota-content h5,
#phieu-container .maumota-content ul {
  margin: 0;
}
#phieu-container .maumota-content ul {
  margin-bottom: 5px;
}

#phieu-container-top * ,.footer *, .footer ,#title_kl ,#phieu-container-bottom *, , .title-mo-ta {
  font-size: 14pt;
  // line-height: 1.1rem;
  font-family: "Times New Roman", Times, serif;
  page-break-inside: avoid !important;
  page-break-before: avoid !important;
}

#phieu-container .maumota-content, 
#phieu-container .maumota-content *,
#phieu-container .ket-luan-content *  {
  font-family: "Times New Roman", Times, serif;
  // page-break-inside: auto !important;
  // page-break-before: auto !important;
}
* {
  page-break-inside: avoid !important;
  page-break-before: avoid !important;
}
#phieu-container .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}

.web , .email, .horizontal_dotted_line, .bac_si_chi_dinh {
  display: none !important;
}

#phieu-container .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 20px;
  font-size: 14pt;
}

#phieu-container .header--left {
  display: flex;
  gap: 10px;
  align-item: center;
  gap: 20px;
}

#phieu-container .header--right {
  display: flex;
  justify-content: end;
  position: relative;
  flex-direction: column ;
  align-items: center ;
  justify-content: center ;
  text-align:  center ;
  position:  relative ;
  minWidth:  150px ;
}

#phieu-container .sub-barcode-text{
  font-size:14pt;
  position:absolute;
  top:50px;
  left:50%;
  line-height: 1.2em;
  transform: translateX(-50%);
}

#phieu-container .row-infomation {
  display: flex;
  flex: 1;
  gap: 20px;
  width : 100%;
}

#phieu-container .hospital-name {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14pt;
    text-wrap: nowrap;
}

#phieu-container .thong-tin-phieu {
    display: flex;
}

#phieu-container .title {
  text-transform: uppercase;
  font-size: 16pt;
  font-weight: bold;
  text-align: center;
  width: 90%;
  // margin: 1rem auto 1rem auto;
}
#phieu-container .title-info {
    font-style: normal;
    font-weight: 700;
    font-size: 14pt;
    line-height: 15pt;
}

#phieu-container .footer{
    margin-top:16px;
    text-align : center;
}

#phieu-container .qr-container{
  width: 140px;
  height: 140px;
  background-color: #fff;
}

#phieu-container .title-footer {
    font-style: normal;
    font-weight: 700;
    font-size: 14pt;
    line-height: 19pt;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
    margin-top : 6px;
}
#phieu-container .date-footer {
    font-style: normal;
    font-weight: 400;
    font-size: 14pt;
    line-height: 16pt;
}

#phieu-container .row-anh{
    position: relative;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-between;
    width:100%;
    height:100%;
}


#phieu-container .ket-luan-content{
    margin: 6px 0;
    font-size:12px;
    font-weight: bold !important;
}

#phieu-container .horizontal_dotted_line {
    border-bottom: 1px dotted black;
    width: 100%;
    margin:24px 0 14px 0;
}

#phieu-container .title-content{
    font-weight:700;
    font-size:14pt;
}

#phieu-container .maumota-content{
    h1,h2,h3,h4,h5{
        margin:0 !important;
    }
    table{
        page-break-before:none !important;
    }

}

#phieu-container .maumota-content > ul {
  margin:0px !important;
}

#phieu-container .maumota-content > p {
  margin: 0px !important;
  margin-bottom:0px !important;
  margin-top:0px !important;
}
#phieu-container .maumota-content > li {
  margin: 0px !important;
  margin-bottom:0px !important;
}

#phieu-container .image-kl{
    display:block;
    height:100%;
    align-items:center;
    align-self:flex-end;
}
.fs12 { font-size: 14pt; }
#phieu-container .img-ket-qua{
    max-height:25vw;
    max-width:31vw;
    object-fit:contain;
}

#phieu-container .between-row{
    justify-content: space-between;
}

#phieu-container .evenly-row{
    justify-content: space-evenly;
}

#phieu-container table{
    border : none;
}
#phieu-container .bold-txt {
  font-weight: bold;
}

#phieu-container .info-group {
  display: flex;
  flex-direction: column;
  gap:2px;
  font-size: 14pt;
}

#phieu-container .row-group_patient-info {
  display:flex;
}

.row-group-2 {
  width:50%;
}

.row-group-3 {
  width:25%;
}

#phieu-container .aivi_note { display: none; }

.title-height {
 height: 80px;
}

#title_kl,.title-mo-ta, .tt-hc {
  font-size: 14pt !important;
}

@media print {
    #phieu-container .foot-break { break-inside: avoid; }
    #phieu-container { page-break-before: always !important;}
}
`;

export const stylePhieuTraKqPdfIshi = `
#phieu-container .maumota-content, 
#phieu-container .maumota-content * {
  font-size: 24px;
}
#phieu-container .maumota-content sub,
#phieu-container .maumota-content sup {
  line-height: inherit;
  font-family: inherit;
}
#phieu-container .maumota-content span,
#phieu-container .maumota-content strong,
#phieu-container .maumota-content i,
#phieu-container .maumota-content u {
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
}
#phieu-container .maumota-content h2 {
  font-size: 20px;
  line-height: unset;
}
#phieu-container .maumota-content h3 {
  font-size: 18px;
  line-height: unset;
}
#phieu-container .maumota-content h4 {
  font-size: 16px;
  line-height: unset;
}
#phieu-container * {
  box-sizing: border-box;
}
#phieu-container p {
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}
#phieu-container figure > img {
  width: 100%;
  height: auto !important;
}

#phieu-container figure.image-style-side {
  float: right;
}
#phieu-container img {
  height: auto !important;
  max-width: 100% !important;
}
#phieu-container .image-inline {
  align-items: flex-start;
  display: inline-flex;
}
#phieu-container figure > * {
  width: 100%;
  height: auto;
}
#phieu-container figure {
  width: 100%;
  margin: auto;
}
#phieu-container .table {
  display: table;
}
#phieu-container figure.table table {
  width: 100%;
}

#phieu-container figure.table table td {
  min-width: 20%;
}

#phieu-container figure.table table td .image {
  margin: 0;
}
#phieu-container figure.table table td > p {
  font-size: 0px;
}
#phieu-container figure.table table td:not(:has(img)) > p {
  font-size: 24px;
}

#phieu-container table, 
#phieu-container .table {
  height: auto !important;
  max-width: 100% !important;
}
#phieu-container table, #phieu-container th, #phieu-container td {
  border-collapse: collapse;
}
#phieu-container .maumota-content tr {
  height: auto !important;
}
#phieu-container .maumota-content td {
  padding: 5px;
}

#phieu-container .maumota-content h1,
#phieu-container .maumota-content h2,
#phieu-container .maumota-content h3,
#phieu-container .maumota-content h4,
#phieu-container .maumota-content h5,
#phieu-container .maumota-content ul {
  margin: 0;
}
#phieu-container .maumota-content ul {
  margin-bottom: 5px;
}

#phieu-container-top * ,.footer *, .footer ,#title_kl,#phieu-container-bottom *, , .title-mo-ta {
  font-size: 24px;
  font-family: "Times New Roman", Times, serif;
  page-break-inside: avoid !important;
  page-break-before: avoid !important;
}

#phieu-container .maumota-content, 
#phieu-container .maumota-content *,
#phieu-container .ket-luan-content *  {
  font-family: "Times New Roman", Times, serif;
  // page-break-inside: auto !important;
  // page-break-before: auto !important;
}


* {
  page-break-inside: avoid !important;
  page-break-before: avoid !important;
}

#phieu-container .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}

.web , .email, .horizontal_dotted_line, .bac_si_chi_dinh {
  display: none !important;
}

#phieu-container .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  gap: 20px;
  font-size: 24px;
}

#phieu-container .header--left {
  display: flex;
  gap: 10px;
  align-item: center;
  gap: 20px;
}

#phieu-container .header--right {
  display: flex;
  justify-content: end;
  position: relative;
  flex-direction: column ;
  align-items: center ;
  justify-content: center ;
  text-align:  center ;
  position:  relative ;
  minWidth:  150px ;
}

#phieu-container .sub-barcode-text{
  font-size: 24px;
  position:absolute;
  top:50px;
  left:50%;
  transform: translateX(-50%);
}

#phieu-container .row-infomation {
  display: flex;
  flex: 1;
  gap: 20px;
  width : 100%;
}

#phieu-container .hospital-name {
    text-transform: uppercase;
    font-weight: bold;
    // font-size:16pt;
}

#phieu-container .thong-tin-phieu {
    display: flex;
}

#phieu-container .title {
  text-transform: uppercase;
  font-size: 30px !important;
  font-weight: bold;
  text-align: center;
  width: 90%;
  // margin: 1rem auto 1rem auto;
}
#phieu-container .title-info {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
}

#phieu-container .footer{
    margin-top: 24px;
    text-align: center;
}

#phieu-container .qr-container{
  width: 140px;
  height: 140px;
  background-color: #fff;
}

#phieu-container .title-footer {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
    margin-top : 6px;
}
#phieu-container .date-footer {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
}

#phieu-container .row-anh{
    position: relative;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-between;
    width:100%;
    height:100%;
}


#phieu-container .ket-luan-content{
    margin: 6px 0;
    font-size: 24px;
    font-weight: bold !important;
}

#phieu-container .horizontal_dotted_line {
    border-bottom: 1px dotted black;
    width: 100%;
    margin:24px 0 14px 0;
}

#phieu-container .title-content{
    font-weight: 700;
    font-size: 24px;
}

#phieu-container .maumota-content{
    h1,h2,h3,h4,h5{
        margin:0 !important;
    }
    table{
        page-break-before:none !important;
    }

}

#phieu-container .maumota-content > ul {
  margin:0px !important;
}

#phieu-container .maumota-content > p {
  margin: 0px !important;
  margin-bottom:0px !important;
  margin-top:0px !important;
}
#phieu-container .maumota-content > li {
  margin: 0px !important;
  margin-bottom:0px !important;
}

#phieu-container .image-kl{
    display:block;
    height:100%;
    align-items:center;
    align-self:flex-end;
}
.fs12 { font-size: 24px; }
#phieu-container .img-ket-qua{
    max-height:25vw;
    max-width:31vw;
    object-fit:contain;
}

#phieu-container .between-row{
    justify-content: space-between;
}

#phieu-container .evenly-row{
    justify-content: space-evenly;
}

#phieu-container table{
    border : none;
}
#phieu-container .bold-txt {
  font-weight: bold;
}

#phieu-container .info-group {
  display: flex;
  flex-direction: column;
  gap:2px;
  font-size: 24px;
}

#phieu-container .row-group_patient-info {
  display:flex;
}

.row-group-2 {
  width:50%;
}

.row-group-3 {
  width:25%;
}

.row-group-4 {
  width:40%;
}

.row-group-30 {
  width:30%;
}

.row-group-70 {
  width:70%;
}
#phieu-container .aivi_note { display: none; }

.title-height {
 height: 80px;
}

@media print {
    #phieu-container .foot-break { break-inside: avoid; }
    #phieu-container { page-break-before: always !important;}
}
`;

export const stylePhieuTraKqPdfAivi = `


html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, 
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	font-size: 100%;
}
  // ul, li,
// /* HTML5 display-role reset for older browsers */
// article, aside, details, figcaption, figure, 
// footer, header, hgroup, menu, nav, section {
// 	display: block;
// }
body {
	line-height: 1;
}
// ol, ul {
// 	list-style: none;
// }
blockquote, q {
	quotes: none;
}
// blockquote:before, blockquote:after,
// q:before, q:after {
// 	content: '';
// 	content: none;
// }

#phieu-container .maumota-content, 
#phieu-container .maumota-content * {
  font-size: 16px;
}
#phieu-container .maumota-content sub,
#phieu-container .maumota-content sup {
  line-height: inherit;
  font-family: inherit;
}
#phieu-container .maumota-content span,
#phieu-container .maumota-content strong,
#phieu-container .maumota-content i,
#phieu-container .maumota-content u {
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
}
#phieu-container .maumota-content h2 {
  font-size: 20px;
  line-height: unset;
}
#phieu-container .maumota-content h3 {
  font-size: 18px;
  line-height: unset;
}
#phieu-container .maumota-content h4 {
  font-size: 16px;
  line-height: unset;
}
#phieu-container * {
  box-sizing: border-box;
}
#phieu-container p {
  word-wrap: break-word;
  margin-bottom: 0;
  margin-top: 0;
}
#phieu-container figure > img {
  width: 100%;
  height: auto !important;
}

#phieu-container figure.image-style-side {
  float: right;
}
#phieu-container img {
  height: auto !important;
  max-width: 100% !important;
}
#phieu-container .image-inline {
  align-items: flex-start;
  display: inline-flex;
}
#phieu-container figure > * {
  width: 100%;
  height: auto;
}
#phieu-container figure {
  width: 100%;
  margin: auto;
}
#phieu-container .table {
  display: table;
}
#phieu-container figure.table table {
  width: 100%;
}

#phieu-container figure.table table td {
  min-width: 20%;
}

#phieu-container figure.table table td .image {
  margin: 0;
}
#phieu-container figure.table table td > p {
  font-size: 0px;
}
#phieu-container figure.table table td:not(:has(img)) > p {
  font-size: 16px;
}

#phieu-container table, 
#phieu-container .table {
  height: auto !important;
  max-width: 100% !important;
}
#phieu-container table, #phieu-container th, #phieu-container td {
  border-collapse: collapse;
}
#phieu-container .maumota-content tr {
  height: auto !important;
}
#phieu-container .maumota-content td {
  padding: 5px;
}

#phieu-container .maumota-content h1,
#phieu-container .maumota-content h2,
#phieu-container .maumota-content h3,
#phieu-container .maumota-content h4,
#phieu-container .maumota-content h5,
#phieu-container .maumota-content ul {
  margin: 0;
}
#phieu-container .maumota-content ul {
  margin-bottom: 5px;
}

#phieu-container-top * ,.footer *, .footer ,#title_kl,#phieu-container-bottom *, .title-mo-ta {
  font-size: 16px;
  font-family: "Times New Roman", Times, serif;
  page-break-inside: avoid !important;
  page-break-before: avoid !important;
}

#phieu-container .maumota-content, 
#phieu-container .maumota-content *,
#phieu-container .ket-luan-content * {
  font-family: "Times New Roman", Times, serif;
  // page-break-inside: auto !important;
  // page-break-before: auto !important;
}

* {
  page-break-inside: avoid !important;
  page-break-before: avoid !important;
}
.fs12 { font-size: 16px; }

#phieu-container .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}

#phieu-container .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  gap: 40px;
  font-size: 15px;
}

#phieu-container .header--left {
  display: flex;
  gap: 10px;
  align-item: center;
  gap: 20px;
}

#phieu-container .header--right {
  display: flex;
  justify-content: end;
  position: relative;
  flex-direction: column ;
  align-items: center ;
  justify-content: center ;
  text-align:  center ;
  position:  relative ;
  minWidth:  150px ;
}

#phieu-container .sub-barcode-text{
  font-size: 18px;
  position:absolute;
  top:50px;
  left:50%;
  transform: translateX(-50%);
}

#phieu-container .row-infomation {
  display: flex;
  flex: 1;
  gap: 20px;
  width : 100%;
}

#phieu-container .hospital-name {
    text-transform: uppercase;
    font-weight: bold;
   
}

#phieu-container .thong-tin-phieu {
    display: flex;
}

#phieu-container .title {
  text-transform: uppercase;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  width: 90%;
  margin-top: 10px;
}
#phieu-container .title-info {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
}

#phieu-container .footer{
    margin-top: 16px;
    text-align: center;
}

#phieu-container .qr-container{
  width: 140px;
  height: 140px;
  background-color: #fff;
}

#phieu-container .title-footer {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    text-transform: uppercase;
    color: #333333;
    margin-top : 6px;
}
#phieu-container .date-footer {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

#phieu-container .row-anh{
    position: relative;
    display:flex;
    flex-direction:row;
    flex-wrap:wrap;
    justify-content:space-between;
    width:100%;
    height:100%;
}


#phieu-container .ket-luan-content{
    margin: 6px 0;
    font-size:18px;
    font-weight: bold !important;
}

#phieu-container .horizontal_dotted_line {
    border-bottom: 1px dotted black;
    width: 100%;
    margin:24px 0 14px 0;
}

#phieu-container .title-content{
    font-weight:700;
    font-size: 18px;
}

#phieu-container .maumota-content{
    h1,h2,h3,h4,h5{
        margin:0 !important;
    }
    table{
        page-break-before:none !important;
    }

}

#phieu-container .maumota-content > ul {
  margin:0px !important;
}

#phieu-container .maumota-content > p {
  margin: 0px !important;
  margin-bottom:0px !important;
  margin-top:0px !important;
}
#phieu-container .maumota-content > li {
  margin: 0px !important;
  margin-bottom:0px !important;
}

#phieu-container .image-kl{
    display:block;
    height:100%;
    align-items:center;
    align-self:flex-end;
}

#phieu-container .img-ket-qua{
    max-height:25vw;
    max-width:31vw;
    object-fit:contain;
}

#phieu-container .between-row{
    justify-content: space-between;
}

#phieu-container .evenly-row{
    justify-content: space-evenly;
}

#phieu-container table{
    border : none;
}
#phieu-container .bold-txt {
  font-weight: bold;
}

#phieu-container .info-group {
  display: flex;
  flex-direction: column;
  gap:2px;
  font-size: 16px;
}

#phieu-container .row-group_patient-info {
  display:flex;
}

.row-group-2 {
  width:50%;
}

.row-group-3 {
  width:25%;
}

.web , .email, .horizontal_dotted_line, .bac_si_chi_dinh {
  display: none !important;
}

.dia-chi ,.ky_thuat_chi_dinh, #title_kl, .footer *, .title-mo-ta  {
  font-size: 16px !important;
}
  
.title-height {
 height: 50px;
}

.aivi_note, .aivi_note * {
 font-size: 15px !important;
}

@media print {
    #phieu-container .foot-break { break-inside: avoid; }
    #phieu-container { page-break-before: always !important;}
}
`;

const PhieuTraKetQua = ({ data, rid = rid() }) => {
  const userProfile = useSelector((state) => state.auth.user);
  const renderKetLuan = () => {
    let arr = [];
    if (data.ket_luan) {
      arr = data.ket_luan.split("\n")

    }
    return (
      <ul style={{ listStyle: "none", margin: 0 }}>
        {arr.map((item, index) => <li style={{ margin: 0 }}> - {item}</li>)}
      </ul>
    )
  }

  useEffect(() => {
    if (!!rid && !!data?.MA_PHIEU) {
      JsBarcode(`#barcode-${rid}`)
        .options({ font: "OCR-B" })
        .CODE128(data?.MA_PHIEU, {
          text: ` `,
          fontSize: 10,
          width: 1.2,
          height: 40,
          textMargin: 0,
          margin: 0,
          padding: 0,
        })
        .render();
    }
  }, [data?.MA_PHIEU, rid]);

  const genDate = () => {

    let date = data.NGAY_TRA_KET_QUA
    let gio = data.GIO_TRA_KET_QUA


    if (i18n.language === languages.tieng_viet) {
      return <>{moment().format('HH:mm')} ngày {date?.slice(date.length - 2)} tháng {date?.slice(4, 6)} năm {date?.slice(0, 4)}</>
    } else {
      return moment(`${gio},${date}`, 'HH:mm,YYYYMMDD').format(enDateFormat);
    }
  }

  const checkFooterMark = () => {
    try {
      let { config } = userProfile;
      if (!!config.BAC_SI_PHIEU_TRA) {
        if (data.MA_LOAI_DICH_VU === 'TDCN_NOISOITIEUHOA') return i18n.t(languageKeys.bac_si_chuyen_khoa)
        else return i18n.t(languageKeys.field_Bac_si)
      } else {
        return i18n.t(languageKeys.field_Bac_si)
      }
    } catch (error) {
      return i18n.t(languageKeys.field_Bac_si)
    }
  }

  const checkFiveStar = (() => {
    try {
      if (userProfile.partner_code === 'pkdk5s12' && userProfile.BENH_VIEN_ID === 'A0sL0PeEBL') return true;
    } catch (error) {
      return false;
    }
  })();

  const renderInfoGroup = () => {

    if (userProfile.partner_code === 'isgisg123') {
      return <>
        <div className="info-group">
          <div className="row-group_patient-info">
            <div className="row-group-70" >{i18n.t(languageKeys.ten_khach_hang)} : <span>{data.TEN || ""}</span></div>
            <div className="row-group-30" >
              <span >{i18n.t(languageKeys.field_Ngay_sinh)}: </span>
              <span>{data?.NGAY_SINH.length === 8 ? moment(data?.NGAY_SINH, 'YYYYMMDD').format('DD/MM/YYYY') : data?.NGAY_SINH}</span>
            </div>
          </div>
          <div>
            <span>{i18n.t(languageKeys.field_Chan_doan_lam_sang)} : </span> {data.CHAN_DOAN_LAM_SANG || ""}
          </div>
          <div>
            <span>{i18n.t(languageKeys.field_Dich_vu_chi_dinh)} : </span> {data.KY_THUAT_CHI_DINH || ""}
          </div>
        </div>
      </>
    }


    if (userProfile.partner_code === 'aivi052428') {
      return <><div className="info-group">
        <div className="row-group_patient-info">
          <div className="row-group-2" >{i18n.t(languageKeys.ten_khach_hang)} : <span>{data.TEN || ""}</span></div>
          <div className="row-group-3" >
            <span >{i18n.t(languageKeys.field_Ngay_sinh)}: </span>
            <span>{data?.NGAY_SINH.length === 8 ? moment(data?.NGAY_SINH, 'YYYYMMDD').format('DD/MM/YYYY') : data?.NGAY_SINH}</span>
          </div>
          <div className="row-group-3" ><span >{i18n.t(languageKeys.field_Gioi_tinh)}: </span><span> {convertGender(data.GIOI_TINH)}</span></div>
        </div>
        <div className="row-group_patient-info">
          <div className="row-group-2"><span >{i18n.t(languageKeys.field_So_dien_thoai)}: </span> <span>{data.SO_DIEN_THOAI}</span></div>
          <div className="row-group-2"><span >{i18n.t(languageKeys.field_Doi_tuong)}: </span> <span>{data?.TEN_DOI_TUONG || ''}</span></div>
        </div>
        <div className="dia-chi">
          <span>{i18n.t(languageKeys.field_Dia_chi)} : </span> {data.DIA_CHI_CHI_TIET || ""}
        </div>
        <div>
          <span>{i18n.t(languageKeys.field_Dich_vu_chi_dinh)} : </span> {data.KY_THUAT_CHI_DINH || ""}
        </div>
      </div>
      </>
    }

    if( userProfile.partner_code == "0018htdk" ||  userProfile.partner_code == "0018nadk"||  userProfile.partner_code == "0018dk248") {
      return <><div className="info-group">
      <div className="row-group_patient-info">
        <div className="row-group-2" >{i18n.t(languageKeys.ten_khach_hang)} : <span>{data.TEN || ""}</span></div>
        <div className="row-group-3" >
          <span >{i18n.t(languageKeys.field_Ngay_sinh)}: </span>
          <span>{data?.NGAY_SINH.length === 8 ? moment(data?.NGAY_SINH, 'YYYYMMDD').format('DD/MM/YYYY') : data?.NGAY_SINH}</span>
        </div>
        <div className="row-group-3" ><span >{i18n.t(languageKeys.field_Gioi_tinh)}: </span><span> {convertGender(data.GIOI_TINH)}</span></div>
      </div>
      <div className="row-group_patient-info">
        <div className="row-group-2"><span >{i18n.t(languageKeys.field_So_dien_thoai)}: </span> <span>{data.SO_DIEN_THOAI}</span></div>
        <div className="row-group-2"><span >{i18n.t(languageKeys.field_Doi_tuong)}: </span> <span>{data?.TEN_DOI_TUONG || ''}</span></div>
      </div>
      <div>
        <span>{i18n.t(languageKeys.dia_chi)} : </span> {data.DIA_CHI_CHI_TIET || ""}
      </div>
      <div>
        <span>{i18n.t(languageKeys.field_Chan_doan_lam_sang)} : </span> {data.CHAN_DOAN_LAM_SANG || ""}
      </div>
      <div>
        <span>{i18n.t(languageKeys.field_Dich_vu_chi_dinh)} : </span> {data.KY_THUAT_CHI_DINH || ""}
      </div>
    </div>
    </>
    }

    return <><div className="info-group">
      <div className="row-group_patient-info">
        <div className="row-group-2" >{i18n.t(languageKeys.ten_khach_hang)} : <span>{data.TEN || ""}</span></div>
        <div className="row-group-3" >
          <span >{i18n.t(languageKeys.field_Ngay_sinh)}: </span>
          <span>{data?.NGAY_SINH.length === 8 ? moment(data?.NGAY_SINH, 'YYYYMMDD').format('DD/MM/YYYY') : data?.NGAY_SINH}</span>
        </div>
        <div className="row-group-3" ><span >{i18n.t(languageKeys.field_Gioi_tinh)}: </span><span> {convertGender(data.GIOI_TINH)}</span></div>
      </div>
      <div className="row-group_patient-info">
        <div className="row-group-2"><span >{i18n.t(languageKeys.field_So_dien_thoai)}: </span> <span>{data.SO_DIEN_THOAI}</span></div>
        <div className="row-group-2"><span >{i18n.t(languageKeys.field_Doi_tuong)}: </span> <span>{data?.TEN_DOI_TUONG || ''}</span></div>
      </div>
      <div>
        <span>{i18n.t(languageKeys.field_Chan_doan_lam_sang)} : </span> {data.CHAN_DOAN_LAM_SANG || ""}
      </div>
      <div>
        <span>{i18n.t(languageKeys.field_Dich_vu_chi_dinh)} : </span> {data.KY_THUAT_CHI_DINH || ""}
      </div>
    </div>
    </>
  }

  const renderBody = () => {
    if (userProfile.partner_code === 'isgisg123') {
      return <>
        <div>
          <h2 style={{ lineHeight: '14px', margin: '10px 0px 0px 0px' }}>II.{(i18n.t(languageKeys.ky_thuat)).toUpperCase()}</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data?.KY_THUAT || " " }} className="maukythuat-content" />
        <div>
          <h2 style={{ lineHeight: '14px', margin: '10px 0px 5px 0px' }} className="title-mo-ta">III.{(i18n.t(languageKeys.mo_ta)).toUpperCase()}</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data.maumota?.replaceAll("&lt;image&gt;","") }} className="maumota-content" />
        <h2 id="title_kl" style={{ lineHeight: '14px', margin: '10px 0px 0px 0px' }}>IV.{(i18n.t(languageKeys.ket_luan)).toUpperCase()}</h2>
        <div dangerouslySetInnerHTML={{ __html: data.ket_luan }} className="ket-luan-content" />
      </>
    }
    if (userProfile.partner_code === 'aivi052428') {
      return <>
        <div>
          <h2 style={{ lineHeight: '14px', margin: '10px 0px 5px 0px' }} className="title-mo-ta">I.{(i18n.t(languageKeys.mo_ta)).toUpperCase()}</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data.maumota?.replaceAll("&lt;image&gt;","") }} className="maumota-content" />
        <h2 id="title_kl" style={{ lineHeight: '14px', margin: '10px 0px 0px 0px' }}>II.{(i18n.t(languageKeys.ket_luan)).toUpperCase()}</h2>
        <div dangerouslySetInnerHTML={{ __html: data.ket_luan }} className="ket-luan-content" />
      </>
    }
    return <>

      <div>
        <h2 style={{ lineHeight: '14px', margin: '10px 0px 5px 0px' }} className="title-mo-ta">II.{(i18n.t(languageKeys.mo_ta)).toUpperCase()}</h2>
      </div>
      <div dangerouslySetInnerHTML={{ __html: data.maumota?.replaceAll("&lt;image&gt;","") }} className="maumota-content" />
      <h2 id="title_kl"  className="title_kl" style={{ lineHeight: '14px', margin: '10px 0px 0px 0px' }}>III.{(i18n.t(languageKeys.ket_luan)).toUpperCase()}</h2>
      <div dangerouslySetInnerHTML={{ __html: data.ket_luan }} className="ket-luan-content" />
    </>
  }

  const renderNote = () => {
    console.log("data", data?.MA_LOAI_DV)
    let MA_LOAI_DV = data?.MA_LOAI_DV
    switch (MA_LOAI_DV) {
      case "CDHA_SATHAI_DV":
      case "CDHA_SATHAI_VP":

        return <><i style={{ fontWeight: 700 }}>{i18n.t(languageKeys.ghi_chu)}:</i><br />
          <i>- {i18n.t(languageKeys.aivi_note_1)}.</i><br />
          <i>- {i18n.t(languageKeys.aivi_note_2)}.</i><br />
          <i>- {i18n.t(languageKeys.aivi_note_3)}.</i></>
      default:
        return <></>
    }
  }

  const renderNoteCustomPartnerCode = () => {

    if( userProfile.partner_code == "0018htdk" ||  userProfile.partner_code == "0018nadk"||  userProfile.partner_code == "0018dk248") {
      let MA_LOAI_DV = data?.MA_LOAI_DV
      switch (MA_LOAI_DV) {
        case "CDHA_SIEUAM":
  
          return <><i style={{ fontWeight: 700 }}>{i18n.t(languageKeys.ghi_chu)}:</i><br />
            <i>- {i18n.t(languageKeys.namAu_haThanh_248_note_1)}.</i><br />
            <i>- {i18n.t(languageKeys.namAu_haThanh_248_note_2)}.</i><br />
            </>
        default:
          return <></>
      }

    }
    
  }

  return (
    <div id="phieu-container">
      <div id="phieu-container-top">
        <div className="header" style={{ display: checkFiveStar ? 'none' : undefined }}>
          {userProfile.benh_vien.ANH_DAI_DIEN && <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>}
          <div style={{ display: "flex", gap: '80px', justifyContent: "space-between", width: "100%" }}>
            <div style={{ flexGrow: 1 }} className="thong-tin-pk">
              <div className="hospital-name">{userProfile.benh_vien.TEN_CSKCB}</div>
              <div>
                <span className="txt-700">{i18n.t(languageKeys.field_Dia_chi)}</span>: {layDiaChi(userProfile.benh_vien)}
              </div>
              <div className="phone-website">
                <div>
                  <span className="txt-700">{i18n.t(languageKeys.dien_thoai)}</span>: {userProfile.benh_vien.SO_DIEN_THOAI}
                </div>
              </div>
              <div className="web">
                <span className="txt-700">Website</span>: {userProfile?.benh_vien?.WEBSITE || ''}
              </div>
              <div className="email">
                <span className="txt-700">Email</span>: {userProfile?.benh_vien?.EMAIL || ''}
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', textAlign: 'center', position: 'relative', minWidth: '210px' }}>
              <svg id={`barcode-${rid}`}></svg>
              <div className="sub-barcode-text">
                <div style={{ width: '300px', textAlign: 'center' }}>{i18n.t(languageKeys.ma_phieu)}: <b>{data?.MA_PHIEU || ''}</b></div>
                <div style={{ width: '300px', textAlign: 'center' }}>{i18n.t(languageKeys.ma_kh)}: <b>{data?.MA_KH || ''}</b></div>
              </div>
            </div>

          </div>
        </div>

        <div className="title">{data.TEN_LOAI_PHIEU || ""}</div>

        {userProfile.partner_code === 'aivi052428' ? "" : <h2  className="tt-hc" style={{ margin: '10px 0px 0px 0px' }}>I.{(i18n.t(languageKeys.field_Thong_tin_hanh_chinh)).toUpperCase()}</h2>}
        {renderInfoGroup()}
      </div>
      {renderBody()}


      <div id="phieu-container-bottom">

        <div className="horizontal_dotted_line"></div>

        <div className="aivi_note">
          {renderNote()}
        </div>

        <div className="note-custom">
          {renderNoteCustomPartnerCode()}
        </div>

        {/* {data?.arr_anh_ID?.length < 3 ? (
  <div className="row-anh foot-break evenly-row">
    {data?.arr_anh_ID?.map((item, index) => (
      <div className="image-kl">
        <img className="img-ket-qua" src={getImageFromSever(item)} alt={item} />
      </div>
    ))}
  </div>
) : (
  <div className="row-anh foot-break between-row">
    {data?.arr_anh_ID?.map((item, index) => (
      <div className="image-kl">
        <img className="img-ket-qua" src={getImageFromSever(item)} alt={item} />
      </div>
    ))}
  </div>
)} */}

        <div style={{ display: "flex", justifyContent: "space-between" }} className="foot-break">
          <div className="footer">
            {data?.LINK_VIEW ? (
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px', flexDirection: 'column' }}>
                <div className="qr-container">
                  <QRCode
                    size={256}
                    style={{ height: '100%', width: "100%" }}
                    value={data?.LINK_VIEW}
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <span>{i18n.t(languageKeys.text_quet_qr)}</span>
              </div>
            ) : <></>}
            {/* 
  sửa theo yêu cầu BA cần Tăng Lương
  <div className="date-footer"></div>
    <div className="title-footer">{i18n.t(languageKeys.nguoi_tra_kq)}</div>
    <div style={{ width: "100%", height: "80px" }}></div>
    <b style={{ fontSize: 14 }}>
      {i18n.t(languageKeys.field_Bac_si)}: {data.NGUOI_TRA_KQ || ""}
    </b> */}
          </div>
          <div className="footer">
            <div className="date-footer">{genDate()}</div>
            <div className="title-footer">{checkFooterMark()}</div>
            <div className={data?.HIEN_THI_CHU_KY ? "" : "title-height"} style={{ width: "100%"}}></div>
            <b className="fs12">
            <div style={{ width: "100%", maxHeight: "90px", textAlign: "center" }}>
              {
                data?.HIEN_THI_CHU_KY ? (
                  <Images imgId={data.CHU_KY_DIEN_TU} fromService={'FILE'} style={{ maxHeight: 70, maxWidth: 150, objectFit: "cover" }} />
                ) : (<></>)
              }                  
            </div>
              {data.CHU_KY || ""}
              {/* {data.NGUOI_TRA_KQ || ""} */}
            </b>
          </div>
        </div>

        {!!userProfile.config?.QR_DOWNLOAD_APP && (
          <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginTop: 30 }}>
            <img src={OnCareQR} alt="QR" style={{ height: 85, width: 85 }} />
            <i style={{ fontWeight: 700 }}>
              {i18n.t(languageKeys.phieukhambenh_emp_qr_oncare)}
            </i>
          </div>
        )}

      </div>

    </div>
  );
};
export default memo(PhieuTraKetQua);

export const layDiaChi = (benh_vien) => {
  let string = "";
  if (benh_vien.DIA_CHI_CHI_TIET) {
    string = benh_vien.DIA_CHI_CHI_TIET;
  }
  if (benh_vien.TEN_PHUONG_XA) {
    string = string + `, ${benh_vien.TEN_PHUONG_XA}`;
  }
  if (benh_vien.TEN_QUAN_HUYEN) {
    string = string + `, ${benh_vien.TEN_QUAN_HUYEN}`;
  }
  if (benh_vien.TEN_TINH_THANH) {
    string = string + `, ${benh_vien.TEN_TINH_THANH}`;
  }
  return string;
};
