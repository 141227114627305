import { Tooltip } from "antd";
import cn from "classnames";
import tooltip from "./tooltip.module.less";

const TooltipButton = ({ widthTip = 400, children, title = "" }) => {
  return (
    <Tooltip title={title}>
      <span className={cn(tooltip["text-elipsis"])} style={{ width: widthTip }}>
        {children}
      </span>
    </Tooltip>
  );
};

export { TooltipButton };
