import JsBarcode from "jsbarcode";
import moment from "moment";
import React, { useEffect } from "react";
import { getSexFromString } from "../../../helpers";
import i18n, { languageKeys, languages } from "../../../i18n";
import _ from "lodash";
import { useSelector } from "react-redux";
import { enDateFormat } from "constants/keys";
import Images from "components/Images/Images";

export const styleKeHoachSuDung = `
#ke-hoach-su-dung * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }

#ke-hoach-su-dung .column {
  float: left;
  width: 33.33%;
}

#ke-hoach-su-dung .row:after {
  content: "";
  display: table;
  clear: both;
}

#ke-hoach-su-dung .container-row {
  margin-top: 8px;
}

#ke-hoach-su-dung .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#ke-hoach-su-dung .header { display: flex; gap: 10px; position:relative; font-size: 10px;}
#ke-hoach-su-dung .flex {
    display: flex;
  }
#ke-hoach-su-dung .flex.justify {
    justify-content: space-around;
  }

#ke-hoach-su-dung .text-bold {
    font-size: 12px;
    font-weight: bold !important;
  }

  #ke-hoach-su-dung .table table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
  }

  #ke-hoach-su-dung td, #ke-hoach-su-dung th {
    border: 1px solid #000;
    // text-align: center;
    padding: 6px;
    // color: #2c3782;
  }

  #ke-hoach-su-dung .table th:first-child {
    border: 1px solid #000;
    text-align: left;
    padding: 6px;
  }

#ke-hoach-su-dung .tel {
    margin-right: 50px;
  }
#ke-hoach-su-dung .txt-700 {
    font-weight: 700;
  }
#ke-hoach-su-dung .container-title {
  text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2rem auto 1rem auto;
  }
#ke-hoach-su-dung .container__title--main {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
  }
#ke-hoach-su-dung .container__ttbn--wrapper {
  margin-top: 6px;
}
#ke-hoach-su-dung .container__ttbn--wrapper>div {
    margin: 4px 0 0 0;
  }
#ke-hoach-su-dung .container-wrapper-flex {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  #ke-hoach-su-dung .container-wrapper-flex .col-3{
    width: 30%;
  }

  #ke-hoach-su-dung .container-wrapper-flex .col-4{
    width: 40%;
  }

  #ke-hoach-su-dung .container-wrapper-flex .col-5{
    width: 50%;
  }

  #ke-hoach-su-dung .thongtin-khambenh {
    width: 100%;
    display: flex;
    margin-top: 12px;
  }

  #ke-hoach-su-dung .thongtin-khambenh .left {
    // width: 65%;
    display: flex;
    flex-direction: column;
  }

  #ke-hoach-su-dung .thongtin-khambenh .right {
    width: 35%;
  }

  #ke-hoach-su-dung .table-border {
    padding: 6px 0;
    border: 1px solid #000;
  }

  #ke-hoach-su-dung .table-item{
    padding-left:12px;
  }

  #ke-hoach-su-dung hr.solid {
    border-top: 1px solid #000;
  }

  #ke-hoach-su-dung .footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }

  #ke-hoach-su-dung .footer .footer-confirm {
    margin-right: 44px;
    text-align: center;
  }

  #ke-hoach-su-dung .name-space {
    height: 80px;
  }

  #ke-hoach-su-dung .txt-size-m{
    font-size: 12px;
  }

  #ke-hoach-su-dung .upperTxt{
    text-transform: uppercase;
  }

  #ke-hoach-su-dung .phone-website { 
    display: flex; gap: 24px; align-items: center 
  }

@media print {
#ke-hoach-su-dung .foot-break { break-inside: avoid; }
}

#ke-hoach-su-dung .table { margin-block: 10px; }
#ke-hoach-su-dung .table * { font-size: 12px; }
#ke-hoach-su-dung .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#ke-hoach-su-dung .table th, .table td { padding: 5px 10px; }
#ke-hoach-su-dung .table th { text-align: start; }
`;

const PhieuKeHoachSuDung = ({ data, rid }) => {

  const userProfile = useSelector((state) => state.auth.user);
  let { benh_nhan, ke_hoach_su_dung } = data;

  useEffect(() => {
    if (!!rid && !!benh_nhan.ma_benh_nhan) {
      JsBarcode(`#barcode-${rid}`)
        .options({ font: "OCR-B" })
        .CODE128(benh_nhan.ma_benh_nhan, {
          text: ` `,
          fontSize: 10,
          width: 1.2,
          height: 40,
          textMargin: 0,
          margin: 0,
          padding: 0,
        })
        .render();
    }
  }, [benh_nhan.ma_benh_nhan, rid]);

  return (
    <div id="ke-hoach-su-dung">
      <div className="header" style={{ paddingTop: "12px" }}>
        {data.hospital.avatar && <Images className="logo" imgId={data.hospital.avatar} />}

        <div style={{ display: "flex", gap: '80px', justifyContent: "space-between", width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span className="txt-700">{data.hospital.name}</span>
            <span>
              <span className="txt-700">{i18n.t(languageKeys.field_Dia_chi)}</span>: {data.hospital.address}
            </span>
            <span>
              <span className="txt-700">{i18n.t(languageKeys.dien_thoai)}</span>: {data.hospital.phone}
            </span>
            <div>
              <span className="txt-700">Website:</span> {convertString(userProfile.benh_vien.WEBSITE)}
            </div>
            <div>
              <span className="txt-700">Email:</span> {convertString(userProfile.benh_vien.EMAIL)}
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', position: 'relative', minWidth: '150px' }}>
            <svg id={`barcode-${rid}`}></svg>
            <span className="sub-barcode-text" style={{ display: 'flex', flexDirection: 'column' }}>
              {i18n.t(languageKeys.ma_kh)}: {data.benh_nhan.ma_benh_nhan}
            </span>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="container-title">
          {i18n.t(languageKeys.phieu_ke_hoach_dieu_tri)}
        </div>
        <div className="container__chapter--1">
          <div className="container__title--main">I. {i18n.t(languageKeys.thong_tin_khach_hang)?.toUpperCase()}</div>
          <div className="container__ttbn--wrapper">
            <div className="container-wrapper-flex">
              <div className="col-3">
                <div>
                  {i18n.t(languageKeys.field_Ma_benh_nhan)}: <span className="txt-700 txt-size-m">{convertString(benh_nhan.ma_benh_nhan)}</span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Gioi_tinh)}: {getSexFromString(benh_nhan.gioi_tinh)}
                </div>
              </div>
              <div className="col-4">
                <div>
                  {i18n.t(languageKeys.ten_benh_nhan)}:{" "}
                  <span className="txt-700 txt-size-m"> {convertString(benh_nhan.ten_benh_nhan)?.toUpperCase()} </span>
                </div>
                <div>
                  {i18n.t(languageKeys.field_Doi_tuong)}: {convertString(benh_nhan.doi_tuong)}
                </div>
              </div>
              <div className="col-3">
                <div>
                  {benh_nhan.ngay_sinh.trim().length > 4 ? i18n.t(languageKeys.field_Ngay_sinh) : i18n.t(languageKeys.field_Nam_sinh)}:{" "}
                  {benh_nhan.ngay_sinh}
                </div>
                <div>
                  {i18n.t(languageKeys.field_So_dien_thoai)}: <span className="txt-700">{convertString(benh_nhan.sdt)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="dia-chi">
            {i18n.t(languageKeys.dia_chi)}: {convertString(benh_nhan.dia_chi)?.slice(-1) === ","
              ? convertString(benh_nhan.dia_chi).slice(0, -1).replaceAll(",", ", ")
              : convertString(benh_nhan.dia_chi).replaceAll(",", ", ")
            }
          </div>
          <div className="container-wrapper-flex">
            <div className="col-5">
              {i18n.t(languageKeys.ten_goi_dv)}: {data.ten_goi_dv}
            </div>
            <div className="col-5">
              {i18n.t(languageKeys.so_buoi_sd)}: {data.so_buoi_su_dung}
            </div>
          </div>
        </div>

        <div className="container__title--main" style={{ margin: "10px 0" }}>
          II. {i18n.t(languageKeys.ke_hoach_dieu_tri).toUpperCase()}
        </div>

        {_.orderBy(ke_hoach_su_dung, ["KE_HOACH_NGAY"])?.map((plan, index) => {
          return (
            <>
              {/* <span className="txt-700 txt-size-m">Liệu trình đề xuất</span> */}
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <b>{i18n.t(languageKeys.data_Ngay)} {plan.KE_HOACH_NGAY.replace('NGAY_', '')}</b>
                <div>{i18n.t(languageKeys.ngay_dat_hen)}: {moment(plan.NGAY, "YYYYMMDD").format("DD/MM/YYYY")}</div>
                <div>{i18n.t(languageKeys.gio_dat_hen)}: {plan.DANH_SACH_DICH_VU?.[0]?.GIO_BAT_DAU} - {plan.DANH_SACH_DICH_VU?.[0]?.GIO_KET_THUC}</div>
                <div />
              </div>

              <table style={{ width: "100%" }} className="table">
                <thead>
                  <tr>
                    <th style={{ width: 28, textAlign: 'center' }}>{i18n.t(languageKeys.field_Stt)}</th>
                    <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Ten_dich_vu)}</th>
                    <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.txt_So_luong)}</th>
                  </tr>
                </thead>
                <tbody>
                  {plan.DANH_SACH_DICH_VU.map((item, index) => {
                    return (
                      <tr key={index}>
                        <td style={{ textAlign: 'center' }}>{index + 1 >= 10 ? index + 1 : `0${index + 1}`}</td>
                        <td>{item.TEN_DICHVU}</td>
                        <td style={{ textAlign: 'center' }}>{item.SO_LUONG}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>

              <div style={{ marginBottom: 7 }}>
                {i18n.t(languageKeys.ghi_chu)}: {plan.GHI_CHU}
              </div>

              {plan.DANH_SACH_VAT_TU?.[0] && (
                <>
                  <span className="txt-size-m upperTxt">{i18n.t(languageKeys.thuoc_vat_tu_su_dung)}</span>
                  <table style={{ width: "100%" }} className="table">
                    <thead>
                      <tr>
                        <th style={{ width: 28, textAlign: 'center' }}>{i18n.t(languageKeys.field_Stt)}</th>
                        <th style={{ textAlign: 'center' }}>{i18n.t(languageKeys.field_Ten_thuoc)}</th>
                        <th style={{ width: 65, textAlign: 'center' }}>{i18n.t(languageKeys.field_ham_luong)}, {i18n.t(languageKeys.field_nong_do)}</th>
                        <th style={{ width: 28, textAlign: 'center' }}>{i18n.t(languageKeys.sl)}</th>
                        <th style={{ width: 100, textAlign: 'center' }}>{i18n.t(languageKeys.duong_dung)}</th>
                        <th style={{ width: 100, textAlign: 'center' }}>{i18n.t(languageKeys.toc_do_truyen)}</th>
                        <th style={{ width: 110, textAlign: 'center' }}>{i18n.t(languageKeys.huong_dan_su_dung)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {plan.DANH_SACH_VAT_TU.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ textAlign: 'center' }}>{index + 1 >= 10 ? index + 1 : `0${index + 1}`}</td>
                            <td>{item.TEN_VAT_TU}</td>
                            <td style={{ textAlign: 'center' }}>{item.HAM_LUONG || item.NONG_DO}</td>
                            <td style={{ textAlign: 'center' }}>{item.SO_LUONG}</td>
                            <td style={{ textAlign: 'center' }}>{item.DUONG_DUNG}</td>
                            <td style={{ textAlign: 'center' }}>{item.TOC_DO_TRUYEN}</td>
                            <td>{item.HUONG_DAN_SU_DUNG}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </>
          );
        })}
      </div>

      <div className="footer foot-break">

        <div style={{ marginTop: 16, textAlign: 'center', marginLeft: 44 }}>
          <div className="txt-700">{i18n.t(languageKeys.khach_hang)}</div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          <div className="txt-700">{data.benh_nhan.ten_benh_nhan}</div>
        </div>

        <div className="footer-confirm">
          {i18n.language === languages.tieng_viet ? (
            <i>
              {moment().format("HH:mm") + ", "}
              Ngày {moment().date()},tháng {moment().month() + 1}, năm {moment().year()}
            </i>
          ) : (
            <i> {moment().format(enDateFormat)}</i>
          )}
          <div className="txt-700">
            {userProfile.partner_code === 'cellab' ? i18n.t(languageKeys.dieu_duong_truong) : i18n.t(languageKeys.field_Nguoi_tu_van)}
          </div>
          <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
          <div className="name-space" />
          <div className="txt-700">{data.tu_van_vien}</div>
        </div>

      </div>
    </div>
  )
};

let convertString = (field) => {
  let text = "";
  if (!!field && typeof field === "string") {
    text = field;
  } else if (!!field && typeof field === "number") {
    text = `${field}`;
  }
  return text;
};

export default PhieuKeHoachSuDung;