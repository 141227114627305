import JsBarcode from "jsbarcode";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import i18n, { languageKeys, languages } from "../../../../i18n";
import { formatNumberToPrice, layDiaChi } from "helpers";
import Images from "components/Images/Images";

export const stylePhieuHoanUng = `
#phieu-hoan-ung * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }
#phieu-hoan-ung .logo { width: 54px; height: 54px; object-fit: contain; }
#phieu-hoan-ung .header { display: flex; gap: 10px; position:relative; font-size: 10px;}
#phieu-hoan-ung .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 10px; }
#phieu-hoan-ung .title { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto;}
#phieu-hoan-ung .subtitle { margin-block: 0.25rem; font-size: 12px; font-weight: bold; }
#phieu-hoan-ung .table * { line-height: 1.2em; }
#phieu-hoan-ung .table { page-break-inside:auto; }
#phieu-hoan-ung tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-hoan-ung thead { display:table-header-group }
#phieu-hoan-ung tfoot { display:table-footer-group }
#phieu-hoan-ung .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#phieu-hoan-ung .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-hoan-ung .table th, .table td { padding: 5px; }
#phieu-hoan-ung .table th { text-align: start; }
#phieu-hoan-ung .noBorder2 { border-right: none; }
#phieu-hoan-ung .noBorder { border-left: none; }
#phieu-hoan-ung .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#phieu-hoan-ung .avatar { height: 80px; width: 80px; padding-right: 15px; }
#phieu-hoan-ung .anotherFoot { display: flex; padding-top: 10px; }
#phieu-hoan-ung .anotherFoot > div { width: 50%; text-align: center; }
#phieu-hoan-ung .des { font-style: italic; }
#phieu-hoan-ung .sub-barcode-text { font-size:12px;position:absolute;top:50px; left:50%; line-height: 1.2em; transform: translateX(-50%);}
#phieu-hoan-ung .fs12 { font-style: 12px; }

@media print {
   #phieu-hoan-ung, #phieu-hoan-ung .foot-break { break-inside: avoid; }
   #phieu-hoan-ung { page-break-after: always;}
}
`;

export const stylePhieuHoanUngGL = `
#phieu-hoan-ung * { font-size: 14pt; font-family: "Times New Roman", Times, serif; }
#phieu-hoan-ung .logo { width: 54px; height: 54px; object-fit: contain; }
#phieu-hoan-ung .header { display: flex; gap: 10px; position:relative; font-size: 14pt;}
#phieu-hoan-ung .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 16pt; }
#phieu-hoan-ung .title { text-transform: uppercase; font-size: 16pt; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto;}
#phieu-hoan-ung .subtitle { margin-block: 0.25rem; font-size: 12pt; font-weight: bold; }
#phieu-hoan-ung .table * { line-height: 1.2em; }
#phieu-hoan-ung .table { page-break-inside:auto; }
#phieu-hoan-ung tr    { page-break-inside:avoid; page-break-after:auto; }
#phieu-hoan-ung thead { display:table-header-group }
#phieu-hoan-ung tfoot { display:table-footer-group }
#phieu-hoan-ung .table { margin-top: 0.5rem; margin-bottom: 0.75rem; }
#phieu-hoan-ung .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#phieu-hoan-ung .table th, .table td { padding: 5px; }
#phieu-hoan-ung .table th { text-align: start; }
#phieu-hoan-ung .noBorder2 { border-right: none; }
#phieu-hoan-ung .noBorder { border-left: none; }
#phieu-hoan-ung .foot { display: flex; justify-content: space-between; padding-top: 20px; }
#phieu-hoan-ung .avatar { height: 80px; width: 80px; padding-right: 15px; }
#phieu-hoan-ung .anotherFoot { display: flex; padding-top: 10px; }
#phieu-hoan-ung .anotherFoot > div { width: 50%; text-align: center; }
#phieu-hoan-ung .des { font-style: italic; }
#phieu-hoan-ung .fs12 { font-style: 12pt; }
#phieu-hoan-ung .sub-barcode-text { font-size:12pt;position:absolute;top:50px; left:50%; line-height: 1.2em; transform: translateX(-50%);}

@media print {
   #phieu-hoan-ung, #phieu-hoan-ung .foot-break { break-inside: avoid; }
   #phieu-hoan-ung { page-break-after: always;}
}
`;

const PhieuHoanUng = ({ data, rid }) => {
    const userProfile = useSelector((state) => state.auth.user);

    useEffect(() => {
        if (!!rid && !!data.MA_BENH_NHAN) {
            JsBarcode(`#barcode-${rid}`)
                .options({ font: "OCR-B" })
                .CODE128(data.MA_BENH_NHAN, {
                    text: ` `,
                    fontSize: 10,
                    width: 1.2,
                    height: 40,
                    textMargin: 0,
                    margin: 0,
                    padding: 0,
                })
                .render();
        }
    }, [data.MA_BENH_NHAN, rid]);

    return Array(2)
        .fill(null)
        .map((_, index) => (
            <div id="phieu-hoan-ung" style={index === 0 ? { marginTop: -7 } : {}} key={index}>
                {/* <div className="header">
                    {userProfile.benh_vien.ANH_DAI_DIEN && <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>}

                    <div style={{ display: "flex", gap: '80px', justifyContent: "space-between", width: "100%" }}>
                        <div style={{ flexGrow: 1 }}>
                            <div className="hospital-name">{userProfile.benh_vien.TEN_CSKCB}</div>
                            <div>
                                {i18n.t(languageKeys.field_Dia_chi)}: {layDiaChi(userProfile.benh_vien)}
                            </div>
                            <div>
                                {i18n.t(languageKeys.dien_thoai)}: {userProfile.benh_vien.SO_DIEN_THOAI}
                            </div>
                        </div>

                        <div style={{display: 'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center', textAlign: 'center', position: 'relative', minWidth: '150px'}}>
                            <svg id={`barcode-${rid}`}></svg>
                            <div className="sub-barcode-text">
                                <div style={{minWidth: '300px', textAlign: 'center'}}>
                                    {i18n.t(languageKeys.ma_kh)}: <b>{data.MA_BENH_NHAN}</b>
                                </div>
                                <div style={{minWidth: '300px', textAlign: 'center'}}>
                                    {i18n.t(languageKeys.ma_phieu)}: <b>{data.MA_PHIEU}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="header">
          {userProfile.benh_vien.ANH_DAI_DIEN && <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>}

          <div style={{ display: "flex", gap: '80px', justifyContent: "space-between", width: "100%" }}>
            <div style={{ flexGrow: 1 }}>
              <div className="hospital-name">{userProfile.benh_vien.TEN_CSKCB}</div>
              <div>
                <span className="txt-700">{i18n.t(languageKeys.field_Dia_chi)}</span>: {layDiaChi(userProfile.benh_vien)}
              </div>
              <div className="phone-website">
                <div>
                  <span className="txt-700">{i18n.t(languageKeys.dien_thoai)}</span>: {userProfile.benh_vien.SO_DIEN_THOAI}
                </div>
              </div>
              <div>
                  <span className="txt-700">Website</span>: {userProfile?.benh_vien?.WEBSITE || ''}
                </div>
              <div>
                <span className="txt-700">Email</span>: {userProfile?.benh_vien?.EMAIL || ''}
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'start', textAlign: 'center', position: 'relative', minWidth: '150px' }}>
              <svg id={`barcode-${rid}`}></svg>
              <div className="sub-barcode-text">
                <div style={{ width: 300, textAlign: 'center' }}>
                  {i18n.t(languageKeys.ma_kh)}:<b> {data.MA_BENH_NHAN}</b>
                </div>
                <div style={{ width: 300, textAlign: 'center' }}>
                  {i18n.t(languageKeys.field_So_phieu)}: <b>{data.MA_PHIEU}</b>
                </div>
              </div>
            </div>

          </div>
        </div>

                <div className="title">
                    {i18n.t(languageKeys.phieu_chi_hoan_ung)}
                </div>

                <div className="subtitle" style={{ textAlign: "center", lineHeight: "1em" }}>
                    ({i18n.t(languageKeys.hoa_don_lien)} {index + 1}: {index + 1 === 1 ? i18n.t(languageKeys.thu_ngan_giu) : i18n.t(languageKeys.khach_hang_giu)})
                </div>

                <div style={{ height: 30 }} />

                <div>
                    <div style={{ display: "grid", gridTemplateColumns: "50% 50%" }}>
                        <div style={{ display: "flex", gap: "20%" }}>
                            <div>
                                {" "}
                                {i18n.t(languageKeys.ho_va_ten_khach_hang)}: <b>{data.TEN}</b>
                            </div>
                        </div>
                        <div style={{ display: "flex", gap: "20%" }}>
                            <div>
                                {" "}
                                {i18n.t(languageKeys.field_Ngay_sinh)}:
                                {" "}
                                {data.NGAY_SINH?.length === 4
                                    ? data.NGAY_SINH
                                    : moment(data.NGAY_SINH).isValid()
                                        ? moment(data.NGAY_SINH).format("DD/MM/YYYY")
                                        : moment(data.NGAY_SINH, "DDMMYYYY").format("DD/MM/YYYY")
                                }
                            </div>
                        </div>
                    </div>

                    <div>
                        {" "}
                        {i18n.t(languageKeys.dia_chi)}: {data.DIA_CHI_CHI_TIET}
                    </div>

                    <div>
                        {" "}
                        {i18n.t(languageKeys.so_tien_da_tam_ung)}: <b>{formatNumberToPrice(data.SO_TIEN_THUC, "", true)}</b>
                    </div>

                    <div>
                        {" "}
                        {i18n.t(languageKeys.so_tien_hoan_ung)}: <b>{formatNumberToPrice(data.SO_TIEN, "", true)}</b>
                    </div>

                    <div>
                        {" "}
                        {i18n.t(languageKeys.hinh_thuc_chi_hoan_ung)}: {data.TEN_HINH_THUC_THANH_TOAN}
                    </div>

                </div>

                <div className="foot-break" style={{ minHeight: "20vh", marginTop: '20px' }}>

                    <div className="anotherFoot">
                        <div style={{ marginTop: 30 }}>
                            <b  className="fs12">{i18n.t(languageKeys.khach_hang)}</b>
                            <div className="des">({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</div>
                        </div>

                        <div>
                            <div style={{ display: "flex", justifyContent: "center", }}>
                                {data?.ngay_tao ?
                                    <i>
                                        {data.gio ? data.gio + ", " : ""}{data.ngay_tao}
                                    </i>
                                    :
                                    <i
                                        style={{
                                            width: "100%",
                                            textAlign: "center",
                                            marginTop: 15,
                                        }}
                                    >
                                        {moment().format("HH:mm") +
                                            `,  ${i18n.t(languageKeys.common_ngay)} ` +
                                            moment().format("DD") +
                                            `  ${i18n.t(languageKeys.common_thang)} ` +
                                            moment().format("MM") +
                                            `  ${i18n.t(languageKeys.common_nam)} ` +
                                            +moment().format("YYYY")}
                                    </i>}
                            </div>
                            <b className="fs12">{i18n.t(languageKeys.data_Thu_ngan)}</b>
                            <div className="des">({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</div>
                            <div style={{ marginTop: 50, fontWeight: 'bold' }}>{data?.CHU_KY || userProfile.CHU_KY}</div>
                        </div>
                    </div>
                </div>
            </div>
        ));
};

export default PhieuHoanUng;