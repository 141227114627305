import { memo } from "react";
import { useSelector } from "react-redux";
import { rid, formatNumberToPrice, getFullName, layDiaChi } from "../../../helpers";
import moment, { lang } from "moment";
import i18n, { languageKeys, languages } from "../../../i18n";
import { enDateFormat } from "constants/keys";
import Images from "components/Images/Images";

export const styleChiTietSoTamUng = `
#so-tam-ung * { font-size: 12px; line-height: 1rem; font-family: "Times New Roman", Times, serif; }
#so-tam-ung .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#so-tam-ung .header { display: flex; gap: 10px; font-size: 10px;}
#so-tam-ung .hospital-name { text-transform: uppercase; font-weight: bold; }
#so-tam-ung .thong-tin-phieu { display:flex ; }
#so-tam-ung .title { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto; }
#so-tam-ung .table { margin-block: 0.75rem; }
#so-tam-ung .table * { font-size: 12px; }
#so-tam-ung .table { page-break-inside:auto; }
#so-tam-ung tr    { page-break-inside:avoid; page-break-after:auto; }
#so-tam-ung .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#so-tam-ung .table th, .table td { padding: 5px 10px; }
#so-tam-ung .table th { text-align: start; }
@media print {
   #so-tam-ung, #so-tam-ung .foot-break { break-inside: avoid; }
   #so-tam-ung{page-break-after: always;}
}
#so-tam-ung .loai { text-transform: uppercase; font-size: 14px; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 1rem auto;}
#so-tam-ung .time {  font-size: 12px; margin-bottom : 10px;width : 100%;  text-align: center; display:flex,flex-direction: row; justify-content : center}
#so-tam-ung .time-bold {  font-size: 12px; margin-bottom : 10px, font-weight: bold;}
#so-tam-ung .text--bold{    font-weight: bold !important;   }
#so-tam-ung .align-center{ text-align: center!important; }
#so-tam-ung .align-right{ text-align: right!important; }
#so-tam-ung .phone-website { display: flex; gap: 24px; align-items: center }
`;

export const styleChiTietSoTamUngGL = `
#so-tam-ung * { font-size: 14pt; font-family: "Times New Roman", Times, serif; }
#so-tam-ung .logo {
  width: 60px;
  height: 60px;
  object-fit: contain;
  object-position: top center;
  margin-top: 4px;
}
#so-tam-ung .header { display: flex; gap: 10px; font-size: 14pt;}
#so-tam-ung .hospital-name { text-transform: uppercase; font-weight: bold; font-size: 16pt; }
#so-tam-ung .thong-tin-phieu { display:flex ; }
#so-tam-ung .title { text-transform: uppercase; font-size: 14pt; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 0.25rem auto; }
#so-tam-ung .table { margin-block: 0.75rem; }
#so-tam-ung .table * { font-size: 14pt; }
#so-tam-ung .table { page-break-inside:auto; }
#so-tam-ung tr    { page-break-inside:avoid; page-break-after:auto; }
#so-tam-ung .table, .table th, .table td { border: 1px solid black; border-collapse: collapse; }
#so-tam-ung .table th, .table td { padding: 5px 10px; }
#so-tam-ung .table th { text-align: start; }
@media print {
   #so-tam-ung, #so-tam-ung .foot-break { break-inside: avoid; }
   #so-tam-ung{page-break-after: always;}
}
#so-tam-ung .loai { text-transform: uppercase; font-size: 14pt; font-weight: bold; text-align: center; width: 90%; margin: 2.5rem auto 1rem auto;}
#so-tam-ung .time {  font-size: 14pt; margin-bottom : 10px;width : 100%;  text-align: center; display:flex,flex-direction: row; justify-content : center}
#so-tam-ung .time-bold {  font-size: 14pt; margin-bottom : 10px, font-weight: bold;}
#so-tam-ung .text--bold{    font-weight: bold !important;   }
#so-tam-ung .align-center{ text-align: center!important; }
#so-tam-ung .align-right{ text-align: right!important; }
#so-tam-ung .phone-website { display: flex; gap: 24px; align-items: center }
`;


export const renderReason = (reason = '') => {
  switch (reason) {
    case 'TIEN THUA':
      return reason = i18n.t(languageKeys.vien_phi_tien_thua)
    case 'THANH_TOAN_TAM_UNG':
      return reason = i18n.t(languageKeys.thanh_toan_tam_ung)
    default:
      return reason
  }
}


const ChiTietSoTamUng = ( dataExport ) => {
  const userProfile = useSelector((state) => state.auth.user);

  const { data, from, to, ten_so, loai_so } = dataExport.data;
  const totalMoney = (data && data?.length) ? 
    data?.reduce((total, item) => 
        (!!item.SO_TIEN ? (total += Number(item.SO_TIEN)) : total)
    , 0) : 0

  const getLoaiSo = (loai) => {
    if(loai === 'THANH_TOAN') return i18n.t(languageKeys.phieu_thu);
    if(loai === 'TAM_UNG') return i18n.t(languageKeys.phieu_tam_ung);
    if(loai === 'HOAN_UNG') return i18n.t(languageKeys.phieu_hoan_ung);
    return i18n.t(languageKeys.phieu_huy)
  }
  

  return (
    <div id="so-tam-ung">
      <div className="header">
        { userProfile.benh_vien.ANH_DAI_DIEN &&
            <Images className="logo" imgId={userProfile.benh_vien.ANH_DAI_DIEN}/>
          }
        <div>
          <div className="hospital-name">{userProfile.benh_vien.TEN_CSKCB}</div>
          <div>
            {i18n.t(languageKeys.dia_chi)}: {`${layDiaChi(userProfile.benh_vien)}`}
          </div>
         <div className="phone-website">
            <div>
                {i18n.t(languageKeys.dien_thoai)}: {userProfile.benh_vien.SO_DIEN_THOAI || ''}
            </div>
            
        </div>
        <div>
                Website: {userProfile?.benh_vien?.WEBSITE || ''}
            </div>
        <div>
            Email: {userProfile?.benh_vien?.EMAIL || ''}
        </div>
        </div>
      </div>

      {/* <div className="title">{'Sổ chẩn đoán hình ảnh'.toUpperCase()}</div> */}
      <div className="loai">{`${ten_so}`.toUpperCase()}</div>
      <div className="time">{i18n.t(languageKeys.time)} <span className="time-bold">
        {`${i18n.t(languageKeys.field_Tu)} ${from} ${i18n.t(languageKeys.field_Den)} ${to}`}
        </span></div>

      <table style={{ width: "100%" }} className="table">
        <thead>
          <tr>
            <th className="align-center" style={{ width: "28px" }}>{i18n.t(languageKeys.field_Stt)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_Ma_phieu)}</th>
            <th className="align-center">{i18n.t(languageKeys.ten_bn)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_Ngay_tao)}</th>
            <th className="align-center">{i18n.t(languageKeys.nguoi_tao)}</th>
            <th className="align-center">{i18n.t(languageKeys.tong_tien)}</th>
            <th className="align-center">{i18n.t(languageKeys.loai_phieu)}</th>
            <th className="align-center">{i18n.t(languageKeys.field_hinh_thuc_thanh_toan)}</th>
            <th className="align-center">{i18n.t(languageKeys.ly_do_tam_hoan_ung)}</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, idx) => {
            return (
                <tr key={rid()}>
                    <td className="align-center">{idx + 1 >= 10 ? idx + 1 : `0${idx + 1}`}</td>
                    <td>{item?.SO_PHIEU}</td>
                    <td>{item?.TEN}</td>
                    <td>{moment(item?.NGAY,"YYYYMMDD").format("DD/MM/YYYY")}</td>
                    <td>{item?.NGUOI_TAO}</td>
                    <td className="align-right">{formatNumberToPrice(item?.SO_TIEN)}</td>
                    <td>{getLoaiSo(item?.LOAI_PHIEU)}</td>
                    <td>{item?.TEN_HINH_THUC_THANH_TOAN}</td>
                    <td>{renderReason(item?.LY_DO)}</td>
                </tr>
            );
          })}
          <tr>
            <td className="align-right text--bold" colSpan={5}>{i18n.t(languageKeys.common_Tong)}</td>
            <td className="align-right text--bold">{formatNumberToPrice(totalMoney)}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </table>

      <div className="foot-break" style={{marginTop: '20px'}}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          {i18n.language === languages.tieng_viet ? (
            <i>
              {moment().format("HH:mm") + ", "}
              {i18n.t(languageKeys.common_ngay)} {moment().date()},{i18n.t(languageKeys.common_thang)} {moment().month() + 1}, {i18n.t(languageKeys.common_nam)} {moment().year()}
            </i>
          ) : (
            <i style={{ marginRight: "40px" }}> {moment().format(enDateFormat)}</i>
          )}
        </div>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ textAlign: "center", marginLeft: 40 }}>
            <div>
              <b>{i18n.t(languageKeys.nguoi_lap_bang)}</b>
            </div>
            <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
            <div style={{ marginTop: 80, fontWeight: "bold" }}></div>
            <div style={{ marginTop: 80, fontWeight: "bold" }}>{userProfile.CHU_KY}</div>
            
          </div>

          <div style={{ textAlign: "center", marginLeft: 40 }}>
            {/* <div>
              <b>{i18n.t(languageKeys.truong_khoa_chan_doan_hinh_anh)}</b>
            </div>
            <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
            <div style={{ marginTop: 80, fontWeight: "bold" }}></div> */}
          </div>

          <div style={{ textAlign: "center", marginRight: 40 }}>
            <div>
              <b>{i18n.t(languageKeys.txt_ke_toan)}</b>
            </div>
            <i>({i18n.t(languageKeys.ky_va_ghi_ro_ho_ten)})</i>
            {/* <div style={{ marginTop: 80, fontWeight: "bold" }}>{userProfile.CHU_KY}</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ChiTietSoTamUng);
