import { createSlice } from "@reduxjs/toolkit";

const initState = {
    tableLoading: false,
    formLoading: false,
    selectLoading: false,
    siderLoading: false,
    buttonLoading: false,
    modalLoading: false,
    QRLoading: false,
    indexedDbLoading: false,
};

const loadingSlice = createSlice({
    name: "loading",
    initialState: initState,
    reducers: {
        setTableLoading: (state, action) => {
            state.tableLoading = action.payload
        },
        setFormLoading: (state, action) => {
            state.formLoading = action.payload
        },
        setSelectLoading: (state, action) => {
            state.selectLoading = action.payload
        },
        setSiderLoading: (state, action) => {
            state.siderLoading = action.payload
        },
        setButtonLoading: (state, action) => {
            state.buttonLoading = action.payload
        },
        setModalLoading: (state, action) => {
            state.modalLoading = action.payload
        },
        setQRLoading: (state, action) => {
            state.QRLoading = action.payload
        },
        setIndexedDbLoading: (state, action) => {
            state.indexedDbLoading = action.payload
        },
    },
});

export const { setTableLoading, setFormLoading, setSelectLoading, setSiderLoading, setButtonLoading, setModalLoading, setQRLoading, setIndexedDbLoading } = loadingSlice.actions
export const tableLoadingState = (state) => state.loading.tableLoading
export const formLoadingState = (state) => state.loading.formLoading
export const selectLoadingState = (state) => state.loading.selectLoading
export const siderLoadingState = (state) => state.loading.siderLoading
export const buttonLoadingState = (state) => state.loading.buttonLoading
export const modalLoadingState = (state) => state.loading.modalLoading
export const QRLoadingState = (state) => state.loading.QRLoading
export const IndexedDbLoadingState = (state) => state.loading.indexedDbLoading
export default loadingSlice.reducer;