import { Input, Form, notification } from "antd";
import { apis } from "../../constants";
import { HLog, common_post, locdau } from "helpers";
import { useEventListener } from "hooks";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDistrictSuccess, getWardSuccess } from "ducks/slices/ListData/CityDistrictWard.slice";
import moment from "moment";
import i18n, { languageKeys } from "../../i18n";
import { QRLoadingState, setQRLoading } from "ducks/slices/loadingSlice";
import { fieldTiepDon } from "pages/QuanLyTiepDon/fieldsTiepDon";
import _ from "lodash";
import { snapshotActions } from "ducks/slices/snapshotSlice";

const QRInput = ({
    isCreateNew, open,
    form,
    dateRef,
    handleFillForm,
    setDistrictList, setWardList, setSelectedThx,
    handleClearPatient = () => {},
    isRedux
}) => {

    const dispatch = useDispatch()
    const data = useSelector((state) => state.data)
    const loading = useSelector(QRLoadingState)
    const { modeQR } = useSelector((state) => state.snapShot)

    const QRCodeRef = useRef()

    useEffect(() => {
        !open && loading && dispatch(setQRLoading(false))
        form.setFieldValue("QR", undefined)
        return () => {
            form.setFieldValue("QR", undefined)
            dispatch(setQRLoading(false))
        }
    }, [open])

    const keydownEvent = (event) => {
        // Focus QR Input
        if (event?.key?.includes("Alt") && isCreateNew) {
            event.preventDefault()
            QRCodeRef.current?.focus()
            setTimeout(() => {
                if (form.getFieldValue("QR")) dispatch(setQRLoading(true))
            }, 100)
        }
        const input = document.getElementById('QR');
        // Handle specific keydown
        if (event?.code?.includes("Bracket") && isCreateNew) {
            if (document.activeElement.id === 'QR') {
                if (event.code === "BracketLeft") {
                    input.value += event.shiftKey ? "Ơ" : "ơ";
                }
                if (event.code === "BracketRight") {
                    input.value += event.shiftKey ? "Ư" : "ư";
                }
                event.preventDefault();
                return;
            }
        }
        if (event?.altKey === true && event?.key !== "Alt" && event?.code?.includes("Digit") && isCreateNew) {
            if (document.activeElement.id === 'QR') {
                input.value += event.key;
                event.preventDefault();
                return;
            }
        }
        if (event?.altKey === false && event?.key !== "Alt" && event?.shiftKey === false && isCreateNew) {
            if (document.activeElement.id === 'QR') {
                // switch (event.code) {
                //     case "Digit0":
                //         input.value += "đ";
                //         break;
                //     case "Digit1":
                //         input.value += "ă";
                //         break;
                //     case "Digit2":
                //         input.value += "â";
                //         break;
                //     case "Digit3":
                //         input.value += "ê";
                //         break;
                //     case "Digit4":
                //         input.value += "ô";
                //         break;
                //     default:
                //         input.value += event.key;
                //         break;
                // }
                if (event.code === "Digit0" || (event.code === "" && event.key === "0")) {
                    input.value += "đ";
                } else if (event.code === "Digit1" || (event.code === "" && event.key === "1")) {
                    input.value += "ă";
                } else if (event.code === "Digit2" || (event.code === "" && event.key === "2")) {
                    input.value += "â";
                } else if (event.code === "Digit3" || (event.code === "" && event.key === "3")) {
                    input.value += "ê";
                } else if (event.code === "Digit4" || (event.code === "" && event.key === "4")) {
                    input.value += "ô";
                } else {
                    input.value += event.key;
                }
                event.preventDefault();
                return;
            }
        }
    };
    useEventListener("keydown", keydownEvent, window.document, open && !modeQR);

    const handleQR = async (e) => {
        const info = e.target.value.split("|")
        // QR string to array
        if (info?.length === 7 && (info[6] === "" || modeQR)) {
            //
            if (modeQR) {
                dispatch(setQRLoading(true))
                dispatch(snapshotActions.setModeQR(false))
            }
            //
            try {
                // Check old customer
                const resKH = await common_post(apis.tiep_don_ds_benh_nhan, {
                    search_string: formatQRNumber(info[0]),
                    limit: 1,
                    from_partner: false,
                })
                if (resKH.status === "OK") {
                    if (resKH.result.length === 1) {
                        // Fill old customer info
                        handleFillForm(resKH.result[0])
                    } else {
                        form.resetFields()
                        // Handle fill new customer info
                        const address = (modeQR ? info[5] : formatQRString(info[5])).split(",").map(i => i.trim())
                        //
                        const cityQR = findLocation(
                            data.tinh, address[address.length - 1], 'TEN_TINH_THANH'
                        )[0]
                        const districtList = findLocation(
                            data.huyen[cityQR.MA_TINH_THANH], address[address.length - 2], 'TEN_QUAN_HUYEN'
                        )
                        let districtQR
                        let wardQR
                        districtList.forEach(item => {
                            const wardItem = findLocation(
                                data.xa[item.MA_QUAN_HUYEN], address[address.length - 3], 'TEN_PHUONG_XA'
                            )[0]
                            if (wardItem) {
                                districtQR = item
                                wardQR = wardItem
                            }
                        })
                        const detailAddressQR = address.slice(0, address.length - 3)
                            .join(", ").split(" ")
                            .map(i => formatQRNumber(i)).join(" ")
                        //
                        // Check ver
                        isRedux
                            ? handleReduxVer(info, cityQR, districtQR, wardQR, detailAddressQR)
                            : handleUseStateVer(info, cityQR, districtQR, wardQR, detailAddressQR)
                    }
                } else {
                    notification.error({ message: i18n.t(languageKeys.thao_tac_that_bai), placement: "bottomLeft" })
                }
            } catch (err) {
                HLog(err)
                notification.error({ message: i18n.t(languageKeys.thao_tac_that_bai), placement: "bottomLeft" })
            } finally {
                dispatch(setQRLoading(false))
                form.setFieldValue("QR", undefined)
            }
        }
    }

    // Use Redux
    const handleReduxVer = (info, cityQR, districtQR, wardQR, detailAddressQR) => {
        // Fill form
        dateRef.current?.setDate(moment(formatQRNumber(info[3]), "DDMMYYYY").format("DD/MM/YYYY").split("/"))
        form.setFields([
            { name: fieldTiepDon.cccd, value: formatQRNumber(info[0]) },
            { name: fieldTiepDon.ten_benh_nhan, value: (modeQR ? info[2] : formatQRString(info[2])).toUpperCase() },
            { name: fieldTiepDon.ngay_sinh, value: formatQRNumber(info[3]) },
            { name: fieldTiepDon.gioi_tinh, value: info[4] === "Nam" ? "nam" : "nu" },
            { name: fieldTiepDon.ma_tinh_thanh, value: cityQR?.MA_TINH_THANH },
            { name: fieldTiepDon.tinh_thanh, value: cityQR?.TEN_TINH_THANH },
            { name: fieldTiepDon.ma_quan_huyen, value: districtQR?.MA_QUAN_HUYEN },
            { name: fieldTiepDon.quan_huyen, value: districtQR?.TEN_QUAN_HUYEN },
            { name: fieldTiepDon.ma_xa_phuong, value: wardQR?.MA_PHUONG_XA },
            { name: fieldTiepDon.xa_phuong, value: wardQR?.TEN_PHUONG_XA },
            { name: fieldTiepDon.t_h_x, value: wardQR.TEN_VIET_TAT },
            { name: fieldTiepDon.dia_chi_chi_tiet, value: detailAddressQR },
        ])
        dispatch(getDistrictSuccess({
            result: data.huyen[cityQR.MA_TINH_THANH].map((item) => ({
                MA_QUAN_HUYEN: item.MA_QUAN_HUYEN,
                TEN_QUAN_HUYEN: item.TEN_QUAN_HUYEN,
            }))
        }))
        dispatch(getWardSuccess({
            result: data.xa[districtQR.MA_QUAN_HUYEN].map((item) => ({
                MA_PHUONG_XA: item.MA_PHUONG_XA,
                TEN_PHUONG_XA: item.TEN_PHUONG_XA,
                TEN_VIET_TAT: item.TEN_VIET_TAT,
            }))
        }))
    }

    // Use useState
    const handleUseStateVer = (info, cityQR, districtQR, wardQR, detailAddressQR) => {
        handleClearPatient()
        // Fill form
        const birthday = formatQRNumber(info[3])
        dateRef?.current?.setDate(moment(birthday, "DDMMYYYY").format("DD/MM/YYYY").split("/"))
        form.setFields([
            { name: fieldTiepDon.cccd, value: formatQRNumber(info[0]) },
            { name: fieldTiepDon.ten_benh_nhan, value: (modeQR ? info[2] : formatQRString(info[2])).toUpperCase() },
            {
                name: fieldTiepDon.ngay_sinh, value: dateRef
                    ? birthday
                    : (birthday.length > 4 ? moment(birthday, "DDMMYYYY").format("DD/MM/YYYY") : birthday)
            },
            { name: fieldTiepDon.gioi_tinh, value: info[4] === i18n.t(languageKeys.gioi_tinh_Nam) ? "nam" : "nu" },
            { name: fieldTiepDon.tinh_thanh, value: JSON.stringify(cityQR) },
            { name: fieldTiepDon.quan_huyen, value: JSON.stringify(_.pick(districtQR, ["MA_QUAN_HUYEN", "TEN_QUAN_HUYEN"])) },
            { name: fieldTiepDon.xa_phuong, value: JSON.stringify(_.pick(wardQR, ["MA_PHUONG_XA", "TEN_PHUONG_XA", "TEN_VIET_TAT"])) },
            { name: fieldTiepDon.t_h_x, value: wardQR.TEN_VIET_TAT },
            { name: fieldTiepDon.dia_chi_chi_tiet, value: detailAddressQR },
        ])
        setDistrictList(
            data.huyen[cityQR.MA_TINH_THANH].map((item) => ({
                MA_QUAN_HUYEN: item.MA_QUAN_HUYEN,
                TEN_QUAN_HUYEN: item.TEN_QUAN_HUYEN,
            }))
        );
        setWardList(
            data.xa[districtQR.MA_QUAN_HUYEN].map((item) => ({
                MA_PHUONG_XA: item.MA_PHUONG_XA,
                TEN_PHUONG_XA: item.TEN_PHUONG_XA,
                TEN_VIET_TAT: item.TEN_VIET_TAT,
            }))
        );
        setSelectedThx(wardQR);
    }

    return (
        <Form.Item name={"QR"} style={{ width: 0, height: 0, margin: 0 }}>
            <Input
                id="QR"
                ref={QRCodeRef}
                onChange={handleQR}
                style={{ width: 0, height: 0, padding: 0, border: 'none', boxShadow: 'none' }}
                onBlur={() => {
                    modeQR && dispatch(snapshotActions.setModeQR(false))
                    form.setFieldValue("QR", undefined)
                }}
            />
        </Form.Item>
    )
}

export default QRInput;

// Format for ENG US keyboard
const formatQRString = (txt) => {
    //
    txt = txt.replace(/ơ|Ơ/g, "[");
    txt = txt.replace(/ư|Ư/g, "]");
    // a
    txt = txt.replace(/a5|A5/g, "à");
    txt = txt.replace(/a6|A6/g, "ả");
    txt = txt.replace(/a7|A7/g, "ã");
    txt = txt.replace(/a8|A8/g, "á");
    txt = txt.replace(/a9|A9/g, "ạ");
    // ă
    txt = txt.replace(/ă5|!5/g, "ằ");
    txt = txt.replace(/ă6|!6/g, "ẳ");
    txt = txt.replace(/ă7|!7/g, "ẵ");
    txt = txt.replace(/ă8|!8/g, "ắ");
    txt = txt.replace(/ă9|!9/g, "ặ");
    txt = txt.replace(/!/g, "ă");
    // â
    txt = txt.replace(/â5|@5/g, "ầ");
    txt = txt.replace(/â6|@6/g, "ẩ");
    txt = txt.replace(/â7|@7/g, "ẫ");
    txt = txt.replace(/â8|@8/g, "ấ");
    txt = txt.replace(/â9|@9/g, "ậ");
    txt = txt.replace(/@/g, "â");
    // đ
    txt = txt.replace(/\)/g, "đ");
    // e
    txt = txt.replace(/e5|E5/g, "è");
    txt = txt.replace(/e6|E6/g, "ẻ");
    txt = txt.replace(/e7|E7/g, "ẽ");
    txt = txt.replace(/e8|E8/g, "é");
    txt = txt.replace(/e9|E9/g, "ẹ");
    // ê
    txt = txt.replace(/ê5|#5/g, "ề");
    txt = txt.replace(/ê6|#6/g, "ể");
    txt = txt.replace(/ê7|#7/g, "ễ");
    txt = txt.replace(/ê8|#8/g, "ế");
    txt = txt.replace(/ê9|#9/g, "ệ");
    txt = txt.replace(/#/g, "ê");
    // i
    txt = txt.replace(/i5|I5/g, "ì");
    txt = txt.replace(/i6|I6/g, "ỉ");
    txt = txt.replace(/i7|I7/g, "ĩ");
    txt = txt.replace(/i8|I8/g, "í");
    txt = txt.replace(/i9|I9/g, "ị");
    // o
    txt = txt.replace(/o5|O5/g, "ò");
    txt = txt.replace(/o6|O6/g, "ỏ");
    txt = txt.replace(/o7|O7/g, "õ");
    txt = txt.replace(/o8|O8/g, "ó");
    txt = txt.replace(/o9|O9/g, "ọ");
    // ô
    txt = txt.replace(/ô5|\$5/g, "ồ");
    txt = txt.replace(/ô6|\$6/g, "ổ");
    txt = txt.replace(/ô7|\$7/g, "ỗ");
    txt = txt.replace(/ô8|\$8/g, "ố");
    txt = txt.replace(/ô9|\$9/g, "ộ");
    txt = txt.replace(/\$/g, "ô");
    // ơ
    txt = txt.replace(/]5|}5/g, "ờ");
    txt = txt.replace(/]6|}6/g, "ở");
    txt = txt.replace(/]7|}7/g, "ỡ");
    txt = txt.replace(/]8|}8/g, "ớ");
    txt = txt.replace(/]9|}9/g, "ợ");
    txt = txt.replace(/]/g, "ơ");
    // u
    txt = txt.replace(/u5|U5/g, "ù");
    txt = txt.replace(/u6|U6/g, "ủ");
    txt = txt.replace(/u7|U7/g, "ũ");
    txt = txt.replace(/u8|U8/g, "ú");
    txt = txt.replace(/u9|U9/g, "ụ");
    // ư
    txt = txt.replace(/\[5|{5/g, "ừ");
    txt = txt.replace(/\[6|{6/g, "ử");
    txt = txt.replace(/\[7|{7/g, "ữ");
    txt = txt.replace(/\[8|{8/g, "ứ");
    txt = txt.replace(/\[9|{9/g, "ự");
    txt = txt.replace(/\[/g, "ư");
    // y
    txt = txt.replace(/y5|Y5/g, "ỳ");
    txt = txt.replace(/y6|Y6/g, "ỷ");
    txt = txt.replace(/y7|Y7/g, "ỹ");
    txt = txt.replace(/y8|Y8/g, "ý");
    txt = txt.replace(/y9|Y9/g, "ỵ");

    return txt;
}

//
const formatQRNumber = (num) => {
    if (num.includes("AltGraph")) {
        //
        num = num.replace(/Control/g, "");
        num = num.replace(/AltGraph/g, "");
        //
        num = num.replace(/đ/g, "0");
        num = num.replace(/ă/g, "1");
        num = num.replace(/â/g, "2");
        num = num.replace(/ê/g, "3");
        num = num.replace(/ô/g, "4");

        return num.replace(/^./, num[0].toUpperCase());
    } else {
        return num.replace(/^./, num[0].toUpperCase());
    }
}

//
const findLocation = (data, address, key) => {
    //
    const arrDel = ['Thành phố ', 'Tỉnh ', 'Huyện ', 'Thị xã ', 'Quận ', 'Xã ', 'Thị trấn ', 'Phường '];
    const regex = new RegExp(arrDel.join('|'), 'g')
    //
    return data
        .filter(i => {
            if (Number(i[key].replace(regex, ''))) {
                return Number(address.replace(regex, '')) === Number(i[key].replace(regex, ''))
            } else {
                return (
                    address.includes(i[key].replace(regex, '')) ||
                    locdau(address).includes(locdau(i[key].replace(regex, '')))
                )
            }
        })
}