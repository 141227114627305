import { put, select } from 'redux-saga/effects';
import { takeLatest } from '@redux-saga/core/effects';
import { common_post } from 'helpers';
import { apis } from "../../../constants";
import { QlyTamUngData, addPhTamUng, cancelPhTamUng, createPhHoanTien, editPhTamUng, getDsChTTSuccess, getDsChuaThanhToan, getDsPhHoanUng, getDsPhHoanUngSuccess, getDsPhTamUng, getDsPhTamUngSuccess } from 'ducks/slices/QlyVienPhi/QlyTamUng.slice';
import { setFormLoading, setTableLoading } from 'ducks/slices/loadingSlice';
import { formatPatientData } from 'pages/QuanLyTiepDon/hooksTiepDon';
import { notification } from 'antd';
import i18n, { languageKeys } from 'i18n';
import _ from 'lodash';

export function* watchDoQlyTamUng() {
    yield takeLatest(getDsPhTamUng, handleGetDsPhTamUng)
    yield takeLatest(addPhTamUng, handleAddPhTamUng)
    yield takeLatest(editPhTamUng, handleEditPhTamUng)
    yield takeLatest(cancelPhTamUng, handleCancelPhTamUng)
    yield takeLatest(createPhHoanTien, handleCreatePhHoanTien)
    yield takeLatest(getDsPhHoanUng, handleGetDsPhHoanUng)
    yield takeLatest(getDsChuaThanhToan, handleGetDsChTT)
}

function* handleGetDsPhTamUng(action) {
    const { limit, filters: newFilters, page } = action.payload;
    const { filters: currentFilters } = yield select(QlyTamUngData);
    const filters = newFilters ? newFilters : currentFilters
    try {
        yield put(setTableLoading(true));
        const res = yield common_post(apis.lay_ds_phieu_tam_ung, {
            ...filters,
            page,
            limit
        })
        if (res) {
            if (res.status === 'OK') {
                yield put(getDsPhTamUngSuccess({ ...res, limit, filters }));
            } else {
                notification.error({
                    message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
                    placement: 'bottomLeft'
                });
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setTableLoading(false));
    }
}

function* handleAddPhTamUng(action) {
    const { info, doPrint, duplicateInfoRef, form, callback } = action.payload
    try {
        yield put(setFormLoading(true));
        // Check patient info
        const patientData = yield formatPatientData(info, false);
        if (!info.BENH_NHAN_ID) {
            const checkResult = yield common_post(apis.checkTrungTTKH, {
                HO_TEN: info?.TEN,
                NGAY_SINH: info?.NGAY_SINH?.slice(-4),
                SO_DIEN_THOAI: info?.SO_DIEN_THOAI
            })
            if(checkResult?.status === 'OK'){
                if(checkResult?.data?.length){
                    const data = checkResult.data[0];
                    if(!info.BENH_NHAN_ID){
                    yield put(setFormLoading(true));
                    duplicateInfoRef?.current?.open({...data, BENH_NHAN_ID: data?.ID})
                    return false;
                    }
                }
            }

            const res = yield common_post(apis.tiep_don_tao_benh_nhan, {
                ...patientData,
                TIEP_TAN_ID: info.NHANSU_ID,
            });
            if (!!res) {
                info.BENH_NHAN_ID = res.ID;
                info.MA_BENH_NHAN = res.MA_BENH_NHAN;
            } else {
                return notification.error({
                    placement: "bottomLeft",
                    message: i18n.t(languageKeys.noti_tao_moi_benh_nhan_that_bai),
                });
            }
        } else {
            // const checkResult = yield common_post(apis.checkTrungTTKH, {
            //     HO_TEN: info?.TEN,
            //     NGAY_SINH: info?.NGAY_SINH?.slice(-4),
            //     SO_DIEN_THOAI: info?.SO_DIEN_THOAI
            // })
            // if(checkResult?.status === 'OK'){
            //     if(checkResult?.data?.length){
            //         const data = checkResult.data[0];
            //         if(info.BENH_NHAN_ID && data?.ID !== info.BENH_NHAN_ID){
            //         yield put(setFormLoading(true));
            //         form.setFields([
            //             {
            //             name: 'TEN',
            //             errors: [i18n.t(languageKeys.trung_thong_tin)]
            //             }, 
            //             {
            //             name: 'SO_DIEN_THOAI',
            //             errors: [i18n.t(languageKeys.trung_thong_tin)]
            //             }, 
            //             {
            //             name: 'NGAY_SINH',
            //             errors: [i18n.t(languageKeys.trung_thong_tin)]
            //             }, 
            //         ])
            //         notification.error({
            //             message: i18n.t(languageKeys.trung_thong_tin),
            //             placement: 'bottomLeft'
            //         })
            //         return false;
            //         }
            //     }
            // }
            const res = yield common_post(apis.tiep_don_kiem_tra_thong_tin, patientData);
            if (res.status !== "OK") {
                return notification.error({
                    placement: "bottomLeft",
                    message: i18n.t(languageKeys.thao_tac_that_bai),
                });
            }
        }
        // Add new
        const res = yield common_post(apis.them_phieu_tam_ung, _.pick(info, [
            "HINH_THUC_THANH_TOAN",
            "LY_DO",
            "NGUOI_NOP_TIEN",
            "SO_TIEN",
            "SO_HOADON_ID",
            "NHANSU_ID",
            "BENH_NHAN_ID",
            "PHAN_LOAI"
        ]))
        if (res) {
            if (res.status === 'OK') {
                console.log(`common_post`, );   
                callback()
                yield put(getDsPhTamUng({ page: 1, limit: 15 }))
                doPrint({...info, MA_PHIEU: res.MA_PHIEU, MA_BENH_NHAN: info.MA_BENH_NHAN})
                return notification.success({
                    placement: "bottomLeft",
                    message: i18n.t(languageKeys.them_moi_thanh_cong),
                });
            } else {
                return notification.error({
                    placement: "bottomLeft",
                    message: i18n.t(languageKeys.noti_Tao_phieu_that_bai),
                });
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setFormLoading(false));
    }
}

function* handleEditPhTamUng(action) {
    const { current_page, limit } = yield select(QlyTamUngData)
    try {
        yield put(setFormLoading(true));
        const res = yield common_post(apis.sua_phieu_tam_ung, _.pick(action.payload, [
            "ID",
            "MA_PHIEU",
            "HINH_THUC_THANH_TOAN",
            "LY_DO",
            "NGUOI_NOP_TIEN",
            "SO_TIEN",
            "SO_HOADON_ID",
            "NHANSU_ID",
            "BENH_NHAN_ID"
        ]))
        if (res) {
            if (res.status === 'OK') {
                yield put(getDsPhTamUng({ page: current_page, limit: limit }))
                notification.success({
                    message: i18n.t(languageKeys.cap_nhat_thanh_cong),
                    placement: 'bottomLeft'
                });
            } else {
                notification.error({
                    message: i18n.t(languageKeys.cap_nhat_that_bai),
                    placement: 'bottomLeft'
                });
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setFormLoading(false));
    }
}

function* handleCancelPhTamUng(action) {
    const { current_page, limit } = yield select(QlyTamUngData)
    try {
        yield put(setTableLoading(true));
        const res = yield common_post(apis.huy_phieu_tam_ung, action.payload)
        if (res) {
            if (res.status === 'OK') {
                yield put(getDsPhTamUng({ page: current_page, limit: limit }))
                notification.success({
                    message: i18n.t(languageKeys.status_Da_huy),
                    placement: 'bottomLeft'
                });
            } else {
                notification.error({
                    message: i18n.t(languageKeys.thao_tac_that_bai),
                    placement: 'bottomLeft'
                });
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setTableLoading(false));
    }
}

function* handleCreatePhHoanTien(action) {
    const { Payload, doPrint } = action.payload
    try {
        yield put(setFormLoading(true));
        const res = yield common_post(apis.tao_phieu_hoan_ung, Payload)
        if (res) {
            if (res.status === 'OK') {
                yield put(getDsPhTamUng({ page: 1, limit: 15 }))
                notification.success({
                    message: i18n.t(languageKeys.thao_tac_thanh_cong),
                    placement: 'bottomLeft'
                });
                doPrint(res.MA_PHIEU)
            } else {
                notification.error({
                    message: i18n.t(languageKeys.thao_tac_that_bai),
                    placement: 'bottomLeft'
                });
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setFormLoading(false));
    }
}

function* handleGetDsPhHoanUng(action) {
    try {
        yield put(setFormLoading(true));
        const res = yield common_post(apis.lay_lich_su_hoan_ung, action.payload)
        if (res) {
            if (res.status === 'OK') {
                yield put(getDsPhHoanUngSuccess(res));
            } else {
                notification.error({
                    message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
                    placement: 'bottomLeft'
                });
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setFormLoading(false));
    }
}

function* handleGetDsChTT(action) {
    try {
        yield put(setFormLoading(true));
        // vienphi_lay_ds_benh_nhan_chua_thanh_toan
        // tam_ung_ds_can_tt
        const res = yield common_post(apis.tam_ung_ds_can_tt, action.payload)
        if (res) {
            if (res.status === 'OK') {
                yield put(getDsChTTSuccess(res));
            } else {
                notification.error({
                    message: i18n.t(languageKeys.noti_Tai_du_lieu_that_bai),
                    placement: 'bottomLeft'
                });
            }
        }
    } catch (err) {
        console.log(err.message);
    } finally {
        yield put(setFormLoading(false));
    }
}
