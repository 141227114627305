import { chatAiActions, chatAiState } from "ducks/slices/chatAiSlice";
import { useDispatch, useSelector } from "react-redux";
import { SSE } from "sse.js";
import { notification } from "antd";
import { rid } from "helpers";
import { apis, keys } from "../../../constants";
import React from "react";
import { handleGetToken } from "../apiInstances";
EventSource = SSE;
var _source = null;

class AskAnswers {
  constructor(ask, answer) {
    this.ask = ask;
    this.answer = answer;
    this.id = rid(8) + Date.now().toString();
  }
}

const useAsking = () => {
  const dispatch = useDispatch();
  const { history } = useSelector(chatAiState);
  const {useAi} = useSelector(state => state.auth)
  React.useEffect(() => {
    if(useAi) {
    let token = localStorage.getItem(keys.ai_access_token);
    !token && handleGetToken();
    }
  }, [useAi]);


  /**
   *
   * @param {*} data
   * @returns { {access_token: string, expires_in:number} }
   */
  const convertJSON = (data) => {
    try {
      let rs = JSON.parse(data);
      return rs;
    } catch (error) {
      return data;
    }
  };
  /**
   * 
   * @param {string} message câu hỏi
   * @param {Array} previousMessages Các câu hỏi trước đó
   * @returns { { message:string, newToken:string } }
   */

  async function prepareAsking(message, previousMessages) {
    try {
      let tokenLocal = localStorage.getItem(keys.ai_access_token);
      let newToken;
      let parseToken = convertJSON(tokenLocal);
      console.log(parseToken)

      if (Date.now() > parseToken.expires_in) {
      // if (Date.now() <= parseToken.expires_in) {

        newToken = await handleGetToken();
      } else {
        newToken = parseToken.access_token;
      }

      return { message, newToken, previousMessages };
    } catch (error) {
      console.log(error);
    }
  }
  async function asking(message, token,previousMessages) {
    try {
      setLoadingMessage(true);
      const url = apis.askingChatAi;
      if (!message) return;
      _source = new SSE(url, {
        method: "POST",
        payload: JSON.stringify({ ask: message, previousMessages }),
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
        start: false,
      });

      let string = "";
      let error = null;
      if (_source && _source.onerror) {
        _source.onerror = (event) => {
          error = event;
        };
      }
      _source.onmessage = (event) => {
        let payload = JSON.parse(event.data);
        if (payload?.content) string += payload?.content;
      };

      _source.addEventListener("readystatechange", (e) => {
        if (+e?.readyState === 2) {
          if (string) {
            addData(new AskAnswers(message, JSON.stringify(string)));
            dispatch(chatAiActions.updateHistory(new AskAnswers(message, JSON.stringify(string))));
          }
          setLoadingMessage(false);
          handleCloseEvent();
        }
      });

      _source.stream();
    } catch (error) {
      setLoadingMessage(false);
      console.log(error);
      notification.error({ message: "Xin lỗi! Chat bot hiện đang ốm, bạn vui lòng quay lại vào dịp khác", placement: "bottomLeft" });
    }
  }

  const handleCloseEvent = () => {
    try {
      if (_source && typeof _source.close === "function") {
        _source.close();
      }
    } catch (error) {
      console.log(error);
    }
  };

  /**
   *
   * @param {String} message câu hỏi gốc
   * @param {Function | any} callback customize câu hỏi
   * @param {Array} previousMessages danh sách các câu hỏi trước đó
   * @returns
   */

  const makeAsk = (message, callback,previousMessages) => {
    try {
      if (!message) return;
      dispatch(chatAiActions.setOpen(true));
      // return
      let question = message;
      if (callback) question = callback(message);
      const checkHistoryMess = history.filter((i) => i?.ask?.toLowerCase()?.includes(question?.toLowerCase()));
      if (checkHistoryMess.length) {
        dispatch(chatAiActions.setData(checkHistoryMess[0]));
      } else {
        console.log(question);
        prepareAsking(question,previousMessages)
          .then(({ message, newToken, previousMessages }) => asking(message, newToken, previousMessages))
          .catch((err) => notification.error({ message: err?.message }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  //================================================================
  function addData(string) {
    dispatch(chatAiActions.setData(string));
  }

  function setLoadingMessage(bool) {
    dispatch(chatAiActions.setLoading(bool));
  }

  return {
    makeAsk,
  };
};

export default useAsking;
