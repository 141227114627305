import { Button, Col, Divider, Modal, Row } from "antd";
import { forwardRef, useImperativeHandle, useState } from "react";
// import { Logo } from "../../assets/svg";
import i18n, { languageKeys } from "../../i18n";
import modal from "./modal.module.less";
import cn from "classnames";
import { useEventListener } from "../../hooks";
// import { useSelector } from "react-redux";
// import { LogoApp } from "assets/img";

const ConfirmModal = forwardRef(
  (
    {
      children,
      width = 400, // Chiều rộng của modal
      title = i18n.t(languageKeys.title_Xac_nhan), // Text Tiêu đề của modal
      content = i18n.t(languageKeys.modal_hay_xac_nhan), // Text Nội dung của modal
      okText = i18n.t(languageKeys.title_Xac_nhan), // Text nút Ok
      cancelText = i18n.t(languageKeys.common_Thoat), // Text nút Cancel
      onOk = () => {}, // Hàm xử lý khi bấm nút Ok
      onCancel = () => {}, // Hàm xử lý khi bấm nút Cancel
      okDisabled = false, // Disabled nút Ok
      clickOutsideDisabled = false, // Disabled việc \click ngoài modal -> đóng modal
      danger = false,
      zIndex,
      onPressCancelSuccess = () => {},
      disableEnterEvent = false,
      hasEventText = true,
      isCloseWhenOk = true,
      showCancelBtn = true,
      ...props
    },
    ref
  ) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tempData, setTempData] = useState();

    // const userProfile = useSelector((state) => state.auth.user);

    useImperativeHandle(ref, () => ({
      open(data) {
        setVisible(true);
        if (!!data) setTempData(data);
      },
      close() {
        onDismiss();
      },
      visible,
      setVisible,
      loading(bool) {
        setLoading(bool);
      },
    }));

    const onClose = () => {
      onCancel(tempData);
      setTempData();
      if (loading) setLoading(false);
      setVisible(false);
    };

    const onDismiss = () => {

      setTempData();
      if (loading) setLoading(false);
      setVisible(false);
      onPressCancelSuccess(tempData)
    };

    const onClickOk = () => {
      if (isCloseWhenOk) setVisible(false);
      onOk(tempData);
    };

    // hàm xử lý phím tắt
    function keydownEvent(event) {
      if (!visible) {
        return;
      }

      if (event.key === "Enter" && !disableEnterEvent) {
        event.preventDefault();
        onClickOk();
      }
    }

    useEventListener("keydown", keydownEvent, window, visible, 500);

    return (
      <Modal
        visible={visible}
        onCancel={clickOutsideDisabled ? () => {} : onDismiss}
        onOk={() => onClickOk}
        width={width}
        okText={okText}
        cancelText={cancelText}
        closable={false}
        footer={null}
        zIndex={zIndex}
        style={{
          top: '20%',
        }}
        {...props}
      >
        {/* =========== Hiển thị mặc định: Logo + title + text content =========== */}
        <div style={{ textAlign: "center" }}>

          {/* {
            userProfile?.config?.CUSTOM_DOMAIN
              ? userProfile.config.CUSTOM_DOMAIN.LOGO_ICON
                ? <img
                  src={window.GATEWAY + `cis-public/public/file?ID=${userProfile.config.CUSTOM_DOMAIN.LOGO_ICON}`}
                  alt="logo"
                  style={{ height: 29 }}
                />
                : <Logo />
              : <Logo />
          } */}

          {/* <img
            src={
              userProfile?.config?.CUSTOM_DOMAIN
                ? userProfile.config.CUSTOM_DOMAIN.LOGO_ICON
                  ? window.GATEWAY + `cis-public/public/file?ID=${userProfile.config.CUSTOM_DOMAIN.LOGO_ICON}`
                  : LogoApp
                : LogoApp
            }
            alt="logo"
            style={{ height: 29 }}
          /> */}

          {/* <Divider style={{ marginTop: 15, marginBottom: 0 }} /> */}

          <div className={cn(modal["title-content"], danger && modal["red-txt"])}>
            {typeof title === "string" ? <h1>{title}</h1> : title}

            {typeof content === "string" ? <p>{content}</p> : content}
          </div>
        </div>

        {/* =================== Component(s) con được truyền vào =================== */}
        {children}

        <Divider style={{ marginTop: 15 }} />

        {/* =================== Hàng nút Cancel + Ok =================== */}
        <Row gutter={10} justify="center" className={cn(danger && modal["red-btns"])}>

          {showCancelBtn ? (
            <Col>
              <Button type="primary" ghost onClick={onClose}>
                {cancelText} {hasEventText ? `(Esc)` : ''}
              </Button>
            </Col>
          ) : <></>}

          <Col>
            <Button type="primary" onClick={() => onOk(tempData)} loading={loading} className={modal["ok-btn"]} disabled={okDisabled}>
              {okText} {hasEventText ? `(Enter)` : ''}
            </Button>
          </Col>
        </Row>
      </Modal>
    );
  }
);

export default ConfirmModal;
