import * as Excel from "exceljs";
import * as FileSaver from "file-saver";
import {
  formatNumberToPrice,
  formatPriceToNumber,
  HLog,
} from "../../helpers";
import moment from "moment";
import i18n, {languages, languageKeys} from "../../i18n";

export async function exportExcel_BienBanKiemNhapThuoc(input) {
    try {
      let {
        data = [],
        thoi_gian = "",
        DIA_CHI = "",
        SO_DIEN_THOAI = "",
        TEN_BENH_VIEN = "",
        dataThuoc = {}
      } = input;
      let dayName = moment().format("DD/MM/YYYY");
      let time = moment().format("h:mm");
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

      const fileExtension = ".xlsx";
      var wb = new Excel.Workbook();
      var ws = wb.addWorksheet(i18n.t(languageKeys.bien_ban_kiem_nhap_thuoc));
      ws.pageSetup.printTitlesColumn = "A:K";
      ws.pageSetup.paperSize = "A4";
      ws.views = [{ showGridLines: false }];

      console.log("bước style");
      ws.getColumn("A").width = 5;
      ws.getColumn("B").width = 15;
      ws.getColumn("C").width = 15;
      ws.getColumn("D").width = 15;
      ws.getColumn("E").width = 15;
      ws.getColumn("F").width = 15;
      ws.getColumn("G").width = 15;
      ws.getColumn("H").width = 15;
      ws.getColumn("I").width = 15;
      ws.getColumn("J").width = 15;
      ws.getColumn("K").width = 15;
      ws.getColumn("I").width = 15;
      ws.getColumn("L").width = 15;


      // console.log("bước render value");
      // ws.mergeCells("C2:C4");
      // ws.getCell("C2").value = {
      //   result: 7,
      // };
      // ws.getCell("C2").alignment = { horizontal: "center", vertical: "middle" };
      // ws.getCell("B2").font = { size: 8, bold: true, name: "Times New Roman" };
      // ws.getCell("B2").value = "  " + TEN_BENH_VIEN;
      // //ws.getCell("B3").value = "  Địa chỉ: " + DIA_CHI;
      // ws.getCell("B3").value = "  Điện thoại: " + SO_DIEN_THOAI;

      // Hàng 1

      ws.mergeCells("F6:I7");
      ws.getCell("F6").font = { size: 28, bold: true, name: "Times New Roman" };
      ws.getCell("F6").alignment = { horizontal: "center" };
      ws.getCell("F6").value = i18n.t(languageKeys.bien_ban_kiem_nhap_thuoc);

      // Bảng
      ws.getCell("B9").value = i18n.t(languageKeys.stt)
      ws.getCell("C9").value = i18n.t(languageKeys.so_chung_tu)
      ws.getCell("D9").value = i18n.t(languageKeys.ten_thuoc_hoa_chat_vat_tu_y_te_tieu_hao)
      ws.getCell("E9").value = i18n.t(languageKeys.field_Don_vi)
      ws.getCell("F9").value = i18n.t(languageKeys.so_kiem_soat)
      ws.getCell("G9").value = i18n.t(languageKeys.field_nuoc_san_xuat)
      ws.getCell("H9").value = i18n.t(languageKeys.han_dung)
      ws.getCell("I9").value = i18n.t(languageKeys.field_don_gia)
      ws.getCell("J9").value = i18n.t(languageKeys.field_So_luong)
      ws.getCell("K9").value = i18n.t(languageKeys.tong_thanh_tien)
      ws.getCell("L9").value = i18n.t(languageKeys.field_Ghi_chu)

      // ws.getCell("B10").value = "1"
      // ws.getCell("C10").value = "2"
      // ws.getCell("D10").value = "3"
      // ws.getCell("E10").value = "4"
      // ws.getCell("F10").value = "5"
      // ws.getCell("G10").value = "6"
      // ws.getCell("H10").value = "7"
      // ws.getCell("I10").value = "8"
      // ws.getCell("J10").value = "9"
      // ws.getCell("K10").value = "10"
      // ws.getCell("L10").value = "11"

      ws.addTable({
        name: "index",
        ref: "B10",
        headerRow: false,
        style: {
          showFirstColumn: true,
        },
        columns: [
          { name: "tt" },
          { name: "so_chung_tu" },
          { name: "ten_thuoc_hoa_chat" },
          { name: "don_vi" },
          { name: "so_kiem_soat" },
          { name: "nuoc_san_xuat" },
          { name: "han_dung" },
          { name: "don_gia" },
          { name: "so_luong"},
          { name: "thanh_tien"},
          { name: "ghi_chu"},
        ],
        rows: data,
      });
      // data.map(item => [item.STT ,item.MA_BENH_NHAN ,item.TEN ,item.NGAY_SINH ,item.CMND ,item.DOI_TUONG ,item.SO_THE_BHYT, item.NGUOI_TIEP_DON ,item.TRANG_THAI ]),

      let lastTable = 10 + data.length;
      console.log(lastTable);

      for (let i = 9; i < lastTable; i++) {
        ws.getRow(i).alignment = { vertical: "middle", horizontal: 'center', wrapText: true };
        ws.getRow(i).font = { name: "Times New Roman", size : 13}
        ws.getCell("B" + i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        ws.getCell("C" + i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };

        ws.getCell("D" + i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        ws.getCell("E" + i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        ws.getCell("F" + i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        ws.getCell("G" + i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        ws.getCell("H" + i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        ws.getCell("I" + i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        ws.getCell("J" + i).border = {
  top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };

        ws.getCell("K" + i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };

        ws.getCell("L" + i).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
      ws.getRow(9).font = { name: "Times New Roman", size : 14, bold : true}

      ws.getCell("I" + (lastTable + 3)).alignment = { horizontal: "center" };
      ws.getCell("I" + (lastTable + 3)).font = { size: 16, bold: true , name: "Times New Roman" };
      ws.getCell("I" + (lastTable + 3)).value = i18n.t(languageKeys.tm_cong_ty);
      ws.getCell("I" + (lastTable + 4)).alignment = { horizontal: "center" };
      ws.getCell("I" + (lastTable + 4)).value =i18n.t(languageKeys.ky_ghi_ro_ho_ten);

      // ws.getCell("C" + (lastTable + 3)).alignment = { horizontal: "center" };
      // ws.getCell("C" + (lastTable + 3)).font = { size: 16, bold: true, name: "Times New Roman" };
      // ws.getCell("C" + (lastTable + 3)).value = i18n.t(languageKeys.nha_cung_cap);
      // ws.getCell("C" + (lastTable + 4)).alignment = { horizontal: "center" };
      // ws.getCell("C" + (lastTable + 4)).value = i18n.t(languageKeys.ky_ghi_ro_ho_ten);

      ws.getCell("F" + (lastTable + 3)).alignment = { horizontal: "center" };
      ws.getCell("F" + (lastTable + 3)).font = { size: 16, bold: true ,name: "Times New Roman"};
      ws.getCell("F" + (lastTable + 3)).value = i18n.t(languageKeys.nha_cung_cap);
      ws.getCell("F" + (lastTable + 4)).alignment = { horizontal: "center" };
      ws.getCell("F" + (lastTable + 4)).value = i18n.t(languageKeys.ky_ghi_ro_ho_ten);


      await wb.xlsx.writeBuffer("bufer.xlsx").then(async (buffer) => {
        const data = new Blob([buffer], { type: fileType });

        FileSaver.saveAs(
          data,
          i18n.t(languageKeys.bien_ban_kiem_nhap_thuoc) +" - "+ dayName + fileExtension
        );
      });
      return "success";
    } catch (error) {
      HLog(error);
      throw error;
    }
  }
