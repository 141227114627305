import { apis } from "../../../../constants";
import { common_post } from "helpers";

export const apiGetPacketServiceList = (dataRequest) => common_post(apis.get_packet_service_list, dataRequest);
export const apiGetChildrenService = (dataRequest) => common_post(apis.get_children_service, dataRequest);
export const apiAddPacketService = (dataRequest) => common_post(apis.add_packet_service, dataRequest);
export const apiGetDetailPacketService = (dataRequest) => common_post(apis.get_detail_packet_service, dataRequest);
export const apiEditPacketService = (dataRequest) => common_post(apis.edit_packet_service, dataRequest);
export const apiLockPacketService = (dataRequest) => common_post(apis.lock_packet_service_list, dataRequest);
export const apiDeletePacketService = (dataRequest) => common_post(apis.delete_packet_service_list, dataRequest);
export const apiGetCustomerSourceList = (dataRequest) => common_post(apis.dm_lay_ds_nguon_khach, dataRequest);
